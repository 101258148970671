import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Define a type for the slice state

interface UserDataState {
  userId: String,
  workshopId: String,
  userRoles: String,
  user: Object,
  workshop: Object,
  configData: Object,
  isStaffPage: Boolean
}

// Define the initial state using that type
const initialState: UserDataState = {
  userId: undefined,
  workshopId: undefined,
  userRoles: undefined,
  user: undefined,
  workshop: undefined,
  configData: undefined,
  isStaffPage: false,
};

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<String>) => {
      state.userId = action.payload;
    },
    setWorkShopId: (state, action: PayloadAction<String>) => {
      state.workshopId = action.payload;
    },
    setUser: (state, action: PayloadAction<Object>) => {
      state.user = action.payload;
    },
    setUserRoles: (state, action: PayloadAction<String>) => {
      state.userRoles = action.payload;
    },
    setWorkshop: (state, action: PayloadAction<Object>) => {
      state.workshop = action.payload;
    },
    setConfigData: (state, action: PayloadAction<Object>) => {
      state.configData = action.payload;
    },
    setStaffPage: (state, action: PayloadAction<Boolean>) => {
      state.isStaffPage = action.payload;
    },
  },
});

export const {
  setUserId,
  setUser,
  setWorkShopId,
  setUserRoles,
  setWorkshop,
  setConfigData,
  setStaffPage,
} = userDataSlice.actions;

export default userDataSlice.reducer;
