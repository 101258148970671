/* eslint-disable no-unused-vars */
export interface TaskBoardItem {
  id: string;
  description: string;
  status: string;
  vehicleNumber: string,
  date: Date,
  priority: string,
  jobId: number
  progress: number
}

// eslint-disable-next-line no-shadow
export enum TaskBoardItemStatus {
  REPAIR = 'repair',
  ACCIDENT = 'accident',
  BREAK_DOWN = 'breakdown',
  SERVICE = 'service',
}

export const PRIORITY = {
  REPAIR: 'repair',
  ACCIDENT: 'accident',
  SERVICE: 'service',
  BREAK_DOWN: 'breakdown',
};

// eslint-disable-next-line no-shadow
export enum TypeOfHistory {
  CASH = 'Cash',
  CREDIT = 'Credit',
  REJECTED = 'Rejected',
}
