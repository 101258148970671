/* eslint-disable import/prefer-default-export */
export const getAllJobs = `
query getAllJobs($workShopId: String!, $limit: Int!, $nextToken: String) {
  getAllJobs(workShopId: $workShopId, limit: $limit, nextToken: $nextToken) {
    jobCreation {
      activeStatus
      applicableVehicleMake
      applicableVehicleModel
      assistanceAllocation
      assistantTechIncentive
      category
      assistantTechGroup2
      checkList
      description
      imageLink
      frt
      incomeAccountName
      jobCategory
      instructionsNotes
      labourRate
      labourRateType
      lh
      mainTechGroup2
      mainTechGroup1
      mainTechIncentive
      pl
      price
      rh
      rer
      searching
      sortKey
      stdHrsMin
      tax1Vat
      tax2Vat
      tax3
      videoLink
      workShopId
      assistantAllowed
      assistantTechnicianCategory
      mainTechnicianCategory
      uploadChecklist
      incomeAccountCode
    }
    nextToken
  }
}
`;

export const searchJobQuary = `
  query searchJob($workShopId: String!, $searching: String!) {
    searchJob(workShopId: $workShopId, searching: $searching) {
      activeStatus
      applicableVehicleMake
      applicableVehicleModel
      assistanceAllocation
      assistantTechIncentive
      category
      assistantTechGroup2
      checkList
      description
      imageLink
      frt
      incomeAccountName
      jobCategory
      instructionsNotes
      labourRate
      labourRateType
      lh
      mainTechGroup2
      mainTechGroup1
      mainTechIncentive
      pl
      price
      rh
      rer
      searching
      sortKey
      stdHrsMin
      tax1Vat
      tax2Vat
      tax3
      videoLink
      workShopId
    }
  }
`;

export const addUpdateJobCreationMutation = `
mutation addUpdateJobCreation($jobCreation: JobCreationInput) {
  addUpdateJobCreation(newJobCreation: $jobCreation) {
    activeStatus
    applicableVehicleMake
    applicableVehicleModel
    assistanceAllocation
    assistantTechIncentive
    category
    assistantTechGroup2
    checkList
    description
    imageLink
    frt
    incomeAccountName
    jobCategory
    instructionsNotes
    labourRate
    labourRateType
    lh
    mainTechGroup2
    mainTechGroup1
    mainTechIncentive
    pl
    price
    rh
    rer
    searching
    sortKey
    stdHrsMin
    tax1Vat
    tax2Vat
    tax3
    videoLink
    workShopId
    assistantAllowed
    assistantTechnicianCategory
    mainTechnicianCategory
    uploadChecklist
    incomeAccountCode
  }
}
`;
