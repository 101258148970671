import { timeConvert } from '../../booking/viewJobs/viewJob/common';

export const dataCol1 = (selectedItem, t, currency) => ({
  item: [
    {
      title: `${t('ActiveStatus')}`,
      value: selectedItem?.activeStatus,
    },
    {
      title: `${t('ApplicableVehicleModel')}`,
      value: selectedItem?.applicableVehicleModel,
    },
    {
      title: `${t('CheckListAvailability')}`,
      value: selectedItem?.checkList,
    },
    {
      title: `${t('Category')}`,
      value: selectedItem?.category,
    },
    {
      title: `${t('Description')}`,
      value: selectedItem?.description,
    },
    {
      title: `${t('Price')}`,
      value: (selectedItem?.price) ? new Intl.NumberFormat(currency.languageCode, { style: 'currency', currency: currency.currency }).format(selectedItem?.price) : '-',
    },
    {
      title: `${t('LabourRateType')}`,
      value: selectedItem?.labourRateType,
    },
    {
      title: `${t('LabourRateRs')}`,
      value: (selectedItem?.labourRate) ? new Intl.NumberFormat(currency.languageCode, { style: 'currency', currency: currency.currency }).format(selectedItem?.labourRate) : '-',
    },
    {
      title: `${t('STDHrsmin')}`,
      value: (selectedItem?.stdHrsMin) ? timeConvert(selectedItem?.stdHrsMin) : '-',
    },
    {
      title: `${t('AssistanceAllowed')}`,
      value: selectedItem?.assistanceAllocation,
    },
    {
      title: `${t('MainTechIncentive')}`,
      value: selectedItem?.mainTechIncentive,
    },
  ],
});

export const dataCol2 = (selectedItem, t) => ({
  item: [
    {
      title: `${t('AssistantTechIncentive')}`,
      value: selectedItem?.assistantTechIncentive,
    },
    {
      title: `${t('MainTechGroup1')}`,
      value: selectedItem?.mainTechGroup1,
    },
    {
      title: `${t('MainTechGroup2')}`,
      value: selectedItem?.mainTechGroup2,
    },
    {
      title: `${t('AssistantTechGroup2')}`,
      value: selectedItem?.assistantTechGroup2,
    },
    {
      title: `${t('Tax1VAT8)')}`,
      value: selectedItem?.tax1Vat,
    },
    {
      title: `${t('Tax2NBT2)')}`,
      value: selectedItem?.tax2,
    },
    {
      title: `${t('Tax3Other)')}`,
      value: selectedItem?.tax3,
    },
    {
      title: `${t('IncomeAccountName')}`,
      value: selectedItem?.incomeAccountName,
    },
    {
      title: `${t('PL')}`,
      value: selectedItem?.pl,
    },
    {
      title: `${t('INSTRUCTIONSNotes')}`,
      value: selectedItem?.instructionsNotes,
    },
    {
      title: `${t('ImagesHyperLink')}`,
      value: selectedItem?.imageLink,
    },
    {
      title: `${t('VideosHyperLink)')}`,
      value: selectedItem?.videoLink,
    },
  ],
});
