/* eslint-disable no-unused-vars */
export interface TaskBoardItem {
  id: string;
  title: string;
  description: string;
  status: string;
  vehicleNumber: string,
  date: Date,
  priority: string,
  jobId: number
  progress: number
}

// eslint-disable-next-line no-shadow
export enum TaskBoardItemStatus {
  CHECKIN_TO_DO = 'To Do CheckIn',
  CHECKIN_IN_PROGRESS = 'Checkin In Progress',
  TO_DO = 'To Do',
}

export const PRIORITY = {
  REPAIR: 'repair',
  ACCIDENT: 'accident',
  SERVICE: 'service',
  BREAK_DOWN: 'breakdown',
  QUICK_JOBS: 'quickjobs',
};
