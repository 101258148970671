/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Card, Row, Col, Tag, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { PRIORITY } from './TaskboardTypes';
import BaseTooltip from '../shared/BaseTooltip';
import '../bookingHistory.scss';
import ViewTask from './ViewTask';
import { useAppSelector } from '../../../../redux/hooks';

function TaskBoardItemCard(props: any) {
  const {
    item, getAllBookingHistory,
  } = props;
  const [colour, setColour] = useState('orange');
  const userId = useAppSelector((state) => state.userData.userId);
  const { Title } = Typography;
  const { t } = useTranslation();

  const getPriority = () => {
    let tempCol = colour;
    if (PRIORITY.REPAIR === item.jobType) {
      tempCol = '#2F80ED';
    } else if (PRIORITY.BREAK_DOWN === item.jobType) {
      tempCol = '#27AE60';
    } else if (PRIORITY.SERVICE === item.jobType) {
      tempCol = '#E2B93B';
    } else {
      tempCol = '#EC0F0F';
    }
    setColour(tempCol);
  };

  useEffect(() => {
    getPriority();
  }, []);

  return (
    <Card
      className="styled-card-history"
      style={{
        height: 175,
      }}
      size="small"
      title={(
        <Title className="task-board-item-card-title" level={5} ellipsis={{ rows: 2 }}>{t('Plate Number')}</Title>
    )}
      extra={(
        <BaseTooltip overlay={item.title}>
          <Title style={{ marginRight: '10px' }} className="task-board-item-card-title" level={5} ellipsis={{ rows: 2 }}>
            {item.plateNumber}
          </Title>
        </BaseTooltip>
    )}
    >
      <BaseTooltip overlay={item.description}>
        <Row>
          <Col span={17} style={{ textAlign: 'left' }}>{item.ownerName}</Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            {moment(item.selectedDate).format('DD/MM/YYYY')}
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <Typography.Paragraph type="secondary" ellipsis={{ rows: 2 }}>
              {item.description}
            </Typography.Paragraph>
          </Col>
        </Row>
        <Row>
          <Col span={19}>
            <Tag className="jobStsTag" color={colour}>{t(item.jobType)}</Tag>
          </Col>
          <Col span={5}>
            {userId && (
              <ViewTask
                item={item}
                getAllBookingHistory={getAllBookingHistory}
              />
            )}
          </Col>
        </Row>
        <br />
      </BaseTooltip>
      {/* <Row style={{ float: 'right' }}>
        {userId && (
        <ViewJob
          item={item}
          getAllBookingHistory={getAllBookingHistory}
        />
        )}
      </Row> */}
    </Card>
  );
}

export default TaskBoardItemCard;
