import React, { useEffect, useState } from 'react';
import './JobCreation.scss';
import {
  Button, Col, Drawer, Input, message, Popconfirm, Popover, Row, Table, Typography, Upload,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DownloadOutlined, FileExcelOutlined, PlusOutlined, SearchOutlined, UploadOutlined,
} from '@ant-design/icons';
import Handlebars from 'handlebars';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  deleteJob, getAllJob, getSignedUrl, searchJob,
} from './actions';
import Loading from '../../../Loading';
import AddEditItem from './componets/AddEditItem';
import csvValidationHandle from '../../../utils/csvValidation';
import { ACTION_TYPE, CSV_TYPES } from '../../../enum/ConfigurationData';
import { dataCol1, dataCol2 } from './columns';
import { timeConvert } from '../../booking/viewJobs/viewJob/common';
import { hasRole } from '../../../utils/commonUtil';
import { USER_ROLES } from '../../../enum/User';

const source = '{{#item}}<h3 className="view-title">{{title}} </h3><p className="view-value">{{value}}</p><br>{{/item}}';

const template = Handlebars.compile(source);

type JobCreationType = {
  workShopId: string,
  configData: any
  userRoles: any[]
}

export const JobCreation = ({
  workShopId,
  configData,
  userRoles,
}: JobCreationType) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [signedUrl, setSignedUrl] = useState<string>();
  const [enableUploadButton, setEnableUploadButton] = useState<boolean>(false);
  const { Title } = Typography;
  const [data, setData] = useState<any>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [currency, setCurrency] = useState<any>();
  const [selectedType, setSelectedType] = useState<ACTION_TYPE>(ACTION_TYPE.VIEW);
  const [csvValid, setCsvValid] = useState<boolean>(false);
  const { t } = useTranslation();

  const triggerModal = (action: ACTION_TYPE, item: any = undefined) => {
    setSelectedItem(item);
    setSelectedType(action);
    setModalVisible(true);
  };

  useEffect(() => {
    if (configData) setCurrency(configData?.currency);
  }, [configData]);

  const getAllJobData = () => {
    let limit = 20;
    let nextToken = null;
    if (pageNumber > 1) {
      limit = 10;
      nextToken = data.nextToken;
    }
    getAllJob(workShopId, limit, nextToken).then((res: any) => {
      const newData = (res && res.data.getAllJobs) || [];
      const newTableData: any[] = newData.jobCreation || [];
      const nToken = newData.nextToken || null;
      setTableData((prevState: any) => [...prevState, ...newTableData]);
      setData(newData);
      setLoading(true);
      if (newTableData.length < 10 || !nToken) {
        setHasMore(false);
      }
      if (nToken) {
        setPageNumber(pageNumber + 1);
      }
    });
  };

  const fetchData = () => {
    setTableData([]);
    setPageNumber(1);
    setData(undefined);
    setRefresh(true);
  };

  const deleteSelectedJob = (job: any) => {
    deleteJob(job.workShopId, job.sortKey).then((res: any) => {
      if (res) {
        message.success('Job deleted successfully').then();
      } else {
        message.error('Something went wrong').then();
      }
      const newTableData = tableData.filter((row: any) => row.sortKey !== job.sortKey);
      setTableData(newTableData);
    }).catch(() => {
      message.error('Something went wrong').then();
    });
  };
  const columnsData = [
    {
      title: `${t('Vehicle Category')}`,
      dataIndex: 'category',
      width: '60px',
    },
    {
      title: `${t('Description')}`,
      dataIndex: 'description',
      width: '90px',
    },
    {
      title: `${t('PriceRS')} (${currency?.currency})`,
      dataIndex: 'price',
      width: '70px',
      render: (price: any) => {
        if (price) {
          const param = { style: 'currency', currency: currency.currency };
          return new Intl.NumberFormat(currency.languageCode, param).format(price);
        }
        return '-';
      },
      className: 'price-colomn',
      align: 'right' as const,
    },
    {
      title: `${t('Labour Rate Type')}`,
      dataIndex: 'labourRateType',
      width: '60px',
      align: 'left' as const,
    },
    {
      title: `${t('Labour Rate')} (${currency?.currency})`,
      dataIndex: 'labourRate',
      width: '65px',
      render: (labourRate: any) => {
        if (labourRate) {
          const param = { style: 'currency', currency: currency.currency };
          return new Intl.NumberFormat(currency.languageCode, param).format(labourRate);
        }
        return '-';
      },
      align: 'left' as const,
    },
    {
      title: `${t('STD Hrs (min)')}`,
      dataIndex: 'stdHrsMin',
      width: '70px',
      render: (value: string) => {
        if (value) return timeConvert(value);
        return '-';
      },
      align: 'left' as const,
    },
    {
      title: `${t('Action')}`,
      dataIndex: 'action',
      width: '60px',
      render: (action: any, item: any) => (
        <>
          <Popover placement="topLeft" content="View Item" trigger="hover">
            <Button
              type="text"
              icon={<img src="/assets/view.png" alt="View Item" />}
              onClick={() => triggerModal(ACTION_TYPE.VIEW, item)}
            />
          </Popover>
          {hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN], userRoles)
              && (
              <>
                <Popover placement="topLeft" content="Edit Item" trigger="hover">
                  <Button
                    type="text"
                    icon={<img src="/assets/edit.png" alt="Edit Item" />}
                    onClick={() => triggerModal(ACTION_TYPE.EDIT, item)}
                  />
                </Popover>
                <Popover placement="topLeft" content="Delete Item" trigger="hover">
                  <Popconfirm
                    title="Are you sure you want to delete this item?"
                    onConfirm={() => deleteSelectedJob(item)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="text"
                      icon={<img src="/assets/trash.png" alt="Delete Item" />}
                    />
                  </Popconfirm>
                </Popover>
              </>
              )}
        </>
      ),
    },
  ];

  const onSearch = (e: any) => {
    let keyword = e.target.value;
    keyword = keyword.toLowerCase();
    if (keyword === '') {
      setTableData(data.jobCreation);
    } else {
      searchJob(workShopId, keyword).then((res: any) => {
        setTableData(res.data.searchJob);
      });
    }
  };

  const tablePagination = (e) => {
    if (e + 1 >= pageNumber && hasMore) {
      getAllJobData();
    }
  };

  const handleChange = (info) => {
    setEnableUploadButton(false);
    let nFileList: any[] = [...info.fileList];
    const fileType: string = info.file.name.split('.')[1];
    if (fileType !== 'csv') {
      message.error(`${info.file.name} is not a csv file`).then();
    } else if (csvValid) {
      nFileList = nFileList.slice(-1);

      if (nFileList.length && nFileList[0].status === 'done') {
        const selectedFile: any = nFileList[0].originFileObj;
        const key: string = `${workShopId}.csv`;
        const contentType: string = selectedFile.type;
        getSignedUrl(key, contentType, workShopId).then((res: any) => {
          setSignedUrl(res.url);
          setEnableUploadButton(true);
          message.destroy();
        }).catch(() => {
          message.destroy();
          message.error('Error when getting signed url').then();
        });
      }
      nFileList = nFileList.map((file: any) => {
        const sFile = file;
        if (sFile.response) {
          sFile.url = file.response.url;
        }
        return sFile;
      });
      setFileList(nFileList);
    }
  };

  const uploadToS3 = async () => {
    if (signedUrl && fileList.length && fileList[0]) {
      setEnableUploadButton(false);
      const selectedFile: any = fileList[0].originFileObj;
      fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': selectedFile.type,
        },
        body: selectedFile,
      })
        .then((res: any) => res.text())
        .then(() => {
          setEnableUploadButton(true);
          setFileList([]);
          setTimeout(() => {
            fetchData();
          }, 1000);
          message.success('Data file uploaded successfully.');
        });
    }
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const csvBeforeUpload = async (e) => {
    message.loading('Loading CSV', 0);
    setEnableUploadButton(false);
    const flag: any = await csvValidationHandle(e, CSV_TYPES.JOB_CREATION);
    setCsvValid(flag);
  };

  useEffect(() => {
    if (isRefresh) {
      getAllJobData();
      setRefresh(false);
    }
  }, [isRefresh]);

  useEffect(() => {
    fetchData();
  }, [workShopId]);

  return (
    <>
      <Row>
        <Col>
          <Title level={3} style={{ color: '#8C8C8C' }}>{t('Jobs')}</Title>
        </Col>
      </Row>
      <Row style={{ marginBottom: '20px' }}>
        <Col lg={3} xl={3} className="addItem-button-col" />
        <Col xs={4} sm={4} md={4} lg={4} xl={3} style={{ marginRight: '-25px', marginLeft: '48px' }}>
          <Button
            block
            onClick={() => triggerModal(ACTION_TYPE.ADD)}
            className="job-creation-button add-item-button"
            style={{ width: '120px' }}
            icon={<PlusOutlined />}
            disabled={!hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN], userRoles)}
          >
            {t('Add Item')}
          </Button>
        </Col>
        <Col xs={6} sm={6} md={5} lg={6} xl={4}>
          <Link to="/templates/karstation-data-format.csv" target="_blank" download>
            <Button
              className="ant-btn-edit"
              id="downloadTemplate-button"
              icon={<DownloadOutlined />}
              disabled={!hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN], userRoles)}
            >
              {' '}
              {t('Export CSV')}
              {' '}
            </Button>
          </Link>
        </Col>
        <Col xs={3} sm={3} md={3} lg={4} xl={4}>
          <Upload
            beforeUpload={csvBeforeUpload}
            onChange={handleChange}
            multiple
            fileList={fileList}
            customRequest={dummyRequest}
            disabled={!hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN], userRoles)}
          >
            <Button
              className="ant-btn-edit"
              id="selectFile-button"
              disabled={!hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN], userRoles)}
              icon={<FileExcelOutlined />}
            >
              {t('Select File')}
            </Button>
          </Upload>
        </Col>
        <Col xs={3} sm={3} md={4} lg={3} xl={3}>
          <Button
            className="ant-btn-edit"
            id="upload-button"
            style={{ width: 137 }}
            icon={<UploadOutlined />}
            disabled={!enableUploadButton
                || !hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN], userRoles)}
            onClick={uploadToS3}
          >
            {t('Upload File')}
          </Button>
        </Col>
        <Col xs={3} sm={3} md={5} lg={6} xl={6}>
          <Input onChange={onSearch} addonBefore={<SearchOutlined />} placeholder={t('Search')} style={{ marginLeft: '0px' }} />
        </Col>
      </Row>
      {!loading ? (
        <Loading />
      ) : (
        <Row className="table-row">
          <Col>
            <Table
              className="jobtable"
              columns={columnsData}
              dataSource={tableData}
              scroll={{ y: 600 }}
              pagination={{ onChange: tablePagination }}
            />
          </Col>
        </Row>
      )}
      {
          selectedType === ACTION_TYPE.VIEW
            ? (
              <Drawer
                title="Item Details"
                placement="right"
                width={600}
                onClose={() => setModalVisible(false)}
                visible={isModalVisible}
              >
                <Row className="view-row" gutter={[16, 48]}>
                  <Col className="view-col" span={12}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: template(dataCol1(selectedItem, t, currency)),
                      }}
                    />
                  </Col>
                  <Col className="view-col" span={12}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: template(dataCol2(selectedItem, t)),
                      }}
                    />
                  </Col>
                </Row>
              </Drawer>
            ) : (
              <AddEditItem
                item={selectedItem}
                fetchData={fetchData}
                setModalVisible={setModalVisible}
                isModalVisible={isModalVisible}
              />
            )
      }
    </>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    configData: state.userData.configData,
    userRoles: state.userData.userRoles,
  };
}

export default connect(mapStateToProps, {})(JobCreation);
