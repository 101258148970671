import { DragDropContext } from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';
import {
  Col, DatePicker, Input, message, Row, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { connect } from 'react-redux';
import { TypeOfHistory, TaskBoardItem } from './TaskboardTypes';
import TaskBoardCol from './TaskBoardCol';
import { getHistoryBookings } from '../action';
import { useAppSelector } from '../../../../redux/hooks';
import Loading from '../../../../Loading';
import NoData from '../../../../components/NoData';
import { PRIORITY } from '../../../../enum/TaskboardTypes';

type workshop = {
  workShopId: any
}
const defaultItems = {
  [TypeOfHistory.CASH]: [],
  [TypeOfHistory.CREDIT]: [],
  [TypeOfHistory.REJECTED]: [],
};

type TaskBoardData = Record<TypeOfHistory, TaskBoardItem[]>;

function Taskboard({ workShopId }: workshop) {
  const [itemsByStatus, setItemsByStatus] = useState<TaskBoardData>(defaultItems);
  const [searchData, setSearchData] = useState<any[]>([]);
  const [done, setDone] = useState<boolean>(false);
  const userId = useAppSelector((state) => state.userData.userId);
  const generateFromDate = () => moment().add(-7, 'days').format('YYYY-MM-DD');
  const generateToDate = () => moment().add(+1, 'days').format('YYYY-MM-DD');
  const [fromDate, setFromDate] = useState<any>(generateFromDate);
  const [toDate, setToDate] = useState<any>(generateToDate);
  const { t } = useTranslation();
  const [isDataAvailable, setDataAvailable] = useState<boolean>(false);
  const { Option } = Select;

  const getToDate = (e: any) => {
    const date = e ? moment(e).add(+1, 'days').format('YYYY-MM-DD') : generateToDate();
    setToDate(date);
  };

  const getFromDate = (e: any) => {
    const date = e ? moment(e).format('YYYY-MM-DD') : generateFromDate();
    setFromDate(date);
  };

  const getAllHistoryBookings = () => {
    if (fromDate && toDate) {
      if (fromDate < toDate) {
        getHistoryBookings(workShopId, userId, fromDate, toDate).then((res: any) => {
          const data = {} as any;
          const HistoryData = [];
          res.data.getAllBookingHistory.map((item: any) => {
            const temp = { ...item, id: item.sortKey } as never;
            HistoryData.push(temp);
            return null;
          }, 1200);
          setSearchData(HistoryData);
          data[TypeOfHistory.CASH] = HistoryData
            .filter((item: any) => item.paymentMethod === TypeOfHistory.CASH);
          data[TypeOfHistory.CREDIT] = HistoryData
            .filter((item: any) => item.paymentMethod === TypeOfHistory.CREDIT);
          data[TypeOfHistory.REJECTED] = HistoryData
            .filter((item: any) => item.status === TypeOfHistory.REJECTED);
          setItemsByStatus(data);
          setDone(true);
          setDataAvailable(!!res?.data?.getAllBookingHistory?.length);
        });
      } else {
        message.error('Please select a valid date range');
      }
    }
  };

  const onSearch = (e) => {
    const keyword = e.target.value;
    let res = searchData;
    if (keyword !== '') {
      res = searchData
        .filter((item: any) => item.plateNumber.toUpperCase().includes(keyword.toUpperCase())
       || item.ownerName.toUpperCase().includes(keyword.toUpperCase()));
    }
    const data: any = {};
    data[TypeOfHistory.CASH] = res
      .filter((item: any) => item.paymentMethod === TypeOfHistory.CASH);
    data[TypeOfHistory.CREDIT] = res
      .filter((item: any) => item.paymentMethod === TypeOfHistory.CREDIT);
    data[TypeOfHistory.REJECTED] = res
      .filter((item: any) => item.status === TypeOfHistory.REJECTED);
    setItemsByStatus(data);
  };

  const handleChange = (value: string) => {
    let res = searchData;
    if (value !== 'all') {
      res = searchData.filter((item) => item?.jobType.toLowerCase() === value);
    }
    const data: any = {};
    data[TypeOfHistory.CASH] = res
      .filter((item: any) => item.paymentMethod === TypeOfHistory.CASH);
    data[TypeOfHistory.CREDIT] = res
      .filter((item: any) => item.paymentMethod === TypeOfHistory.CREDIT);
    data[TypeOfHistory.REJECTED] = res
      .filter((item: any) => item.status === TypeOfHistory.REJECTED);
    setItemsByStatus(data);
  };

  const taskBoardContent = () => {
    if (!done) {
      return (<Loading />);
    }
    if (!isDataAvailable) {
      return (<NoData />);
    }
    return Object.values(TypeOfHistory).map((historyType) => (
      <TaskBoardCol
        key={historyType}
        historyType={historyType}
        items={itemsByStatus[historyType]}
        getAllHistoryBookings={getAllHistoryBookings}
      />
    ));
  };

  useEffect(() => {
    if (workShopId) {
      getAllHistoryBookings();
    }
  }, [workShopId, fromDate, toDate]);

  return (
    <>
      <Row className="top-row">
        <Col xs={1} sm={1} md={1} lg={6} xl={6} />
        <Col span={2}>
          <Select defaultValue="All" style={{ width: 120 }} onChange={handleChange}>
            <Option value="all">All</Option>
            {Object.values(PRIORITY).map((type: string) => (
              <Option value={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</Option>
            ))}
          </Select>
        </Col>
        <Col xs={5} sm={5} md={5} lg={2} xl={2}>
          <div style={{ float: 'right', paddingRight: '4px' }}><h3 style={{ color: '#8c8c8c' }}>{`${t('From')}:`}</h3></div>
        </Col>
        <Col xs={4} sm={4} md={4} lg={3} xl={3}>
          <DatePicker
            onChange={getFromDate}
            className="ks_drp_vcl-ty selection_syl"
            placeholder={moment().add(-7, 'days').format('YYYY-MM-DD')}
          />
        </Col>
        <Col xs={2} sm={2} md={2} lg={1} xl={1}><div style={{ float: 'right', paddingRight: '4px' }}><h3 style={{ color: '#8c8c8c' }}>{`${t('To')}:`}</h3></div></Col>
        <Col xs={4} sm={4} md={4} lg={3} xl={3}>
          <DatePicker
            onChange={getToDate}
            className="ks_drp_vcl-ty selection_syl"
            placeholder={moment().format('YYYY-MM-DD')}
          />
        </Col>
        <Col span={5} offset={1}>
          <Input
            style={{ width: '18rem', marginLeft: '-18px' }}
            onChange={onSearch}
            addonBefore={<SearchOutlined />}
            placeholder={t('Search')}
          />
        </Col>
      </Row>
      <DragDropContext>
        <div className="task-board-root">
          <div className="task-board-content">
            {taskBoardContent()}
          </div>
        </div>
      </DragDropContext>
    </>
  );
}
function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
  };
}

export default connect(mapStateToProps, {})(Taskboard);
