/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import {
  Link, useHistory, useLocation, useParams,
} from 'react-router-dom';
import {
  Row, Form, Card, message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { signIn } from './actions';
import { useAppDispatch } from '../../redux/hooks';
import i18n from '../../translation/i18n';
import { setStaffPage } from '../../redux/userSlice';
import PasswordForm from './passwordForm/PasswordForm';
import NewPasswordForm from './newPasswordForm/NewPasswordForm';

type props = { loggedUsername: string | undefined };

export const LoginPage = ({ loggedUsername = undefined }: props) => {
  const history = useHistory();
  const { staff }: any = useParams();
  const location = useLocation<any>();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [requestNewPassword, setRequestNewPassword] = useState(false);
  const { t } = useTranslation();

  const { from } = location.state || { from: { pathname: '/' } };

  const onLogin = async () => {
    const { username, password, newPassword } = form.getFieldsValue();
    if (staff && loggedUsername !== username) {
      return message.error('The username does not match your credentials.');
    }
    localStorage.setItem('lang', 'En');
    i18n.changeLanguage('En');
    const loggedIn = await signIn(username, password, dispatch, setRequestNewPassword, newPassword);
    if (loggedIn) {
      if (staff) {
        dispatch(setStaffPage(true));
      } else {
        history.replace(from, { from: 'login' });
      }
    }
    return null;
  };

  return (
    <div className="ks_frm ks_frm_lgn">
      <Card bordered className="ks_crd ks_crd_lgn">
        <Row style={{ paddingLeft: '5rem' }}>
          <img src="/assets/logo.png" className="ks_logo ks_logo_img_header_3" alt="logo" />
        </Row>
        <Row>
          {requestNewPassword
            ? (
              <NewPasswordForm
                form={form}
                staff={staff}
                requestNewPassword={requestNewPassword}
                onLogin={onLogin}
                t={t}
              />
            )
            : (
              <PasswordForm
                form={form}
                staff={staff}
                requestNewPassword={requestNewPassword}
                onLogin={onLogin}
                t={t}
              />
            ) }
        </Row>
      </Card>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loggedUsername: state?.userData?.user?.username,
  };
}

export default connect(mapStateToProps, {})(LoginPage);
