/* eslint-disable import/prefer-default-export */
export const INPUT_TYPES = {
  RADIO: 'radio',
  TEXT: 'text',
  TEXTAREA: 'text area',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
};

export type INPUT_TYPE = typeof INPUT_TYPES[keyof typeof INPUT_TYPES]

export const REPAIR_ITEM_TYPES = {
  reRe: 'RE/RE',
  reRi: 'RE/RI',
  nsr: 'NSR',
  pdistd: 'PDI STD',
};
