/* eslint-disable import/order */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Taskboard from './taskboard/Taskboard';

export const HistoryPage = () => {
  const { Title } = Typography;

  const { t } = useTranslation();

  return (
    <div>
      <Title level={3} className="ks_lbl_titl">{t('Booking History')}</Title>
      <div>
        <Taskboard />
      </div>
    </div>
  );
};
export default HistoryPage;
