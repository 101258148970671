/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import {
  Drawer, Button, Row, Col, Popover, Typography, Tag,
} from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import '../workshop.scss';
import { getImageUrl } from '../actions';

export default function ViewProfile(props:any) {
  const [visible, setVisible] = useState<boolean>(false);
  const [image, setImage] = useState<string>('');

  const { Title } = Typography;
  const { workshop }: any = props;

  const workshopData = [
    {
      title: 'Workshop Name',
      value: workshop?.workshopName,
    },
    {
      title: 'Workshop Registration Number',
      value: workshop?.regNo,
    },
    {
      title: 'Contact Number',
      value: workshop?.contactNumber,
    },
    {
      title: 'Address',
      value: workshop?.address,
    },
    {
      title: 'Trade Name',
      value: workshop?.tradeName,
    },
  ];

  const adminData = [
    {
      title: 'Name',
      value: workshop?.name,
    },
    {
      title: 'Contact Details',
      value: workshop?.adminContactNumber,
    },
    {
      title: 'Username',
      value: workshop?.userName,
    },
    {
      title: 'Email',
      value: workshop?.email,
    },
    {
      title: 'Status',
      value:
  <Tag
    style={{
      textAlign: 'center',
      alignSelf: 'center',
      borderRadius: '8px',
      fontSize: 14,
      padding: 2,
      paddingLeft: 8,
      paddingRight: 8,
    }}
    color={workshop?.status === 'Active' ? '#27AE60' : '#EC0F0F'}
  >
    {workshop?.status === 'Active' ? 'Active' : 'Inactive'}
  </Tag>,
    },
  ];

  const taxDetails = [
    {
      title: 'VAT',
      value: workshop?.vat,
    },
    {
      title: 'NDBT',
      value: workshop?.ndbt,
    },
    {
      title: 'Other Taxes',
      value: workshop?.otherTaxes,
    },
  ];

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const loadImages = () => {
    if (workshop.logo) {
      getImageUrl(workshop.logo, 'image/png', 'getObject').then((imageUrl) => {
        const imageUrlObj = {
          status: 'done',
          url: imageUrl.url,
        };
        setImage(imageUrl.url);
        return imageUrlObj;
      });
    }
  };

  useEffect(() => {
    if (workshop) {
      loadImages();
    }
  }, [workshop]);

  return (
    <>
      <Popover placement="topLeft" content="View Workshop Details" trigger="hover">
        <Button onClick={showDrawer} type="link" icon={<EyeOutlined />} />
      </Popover>
      <Drawer
        title={(
          <Row>
            <Col span={16}>
              <Title level={5} className="step-title">Workshop Details</Title>
            </Col>
            <Col span={7}>
              <Row style={{ float: 'right' }}>
                <Tag
                  style={{
                    textAlign: 'center',
                    alignSelf: 'center',
                    borderRadius: '8px',
                    fontSize: 14,
                    padding: 2,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                  color={workshop?.status === 'Active' ? '#27AE60' : '#EC0F0F'}
                >
                  {workshop?.status === 'Active' ? 'Active' : 'Inactive'}
                </Tag>
              </Row>
            </Col>
          </Row>
        )}
        width={700}
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <Title level={5} className="step-title">General Details</Title>
        <Row className="tab-row">
          {workshopData.map((row) => (
            <Col key={row.title} xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <Col className="col-title" span={12}>{row?.title}</Col>
                <Col className="col-value" span={12}>{row?.value}</Col>
              </Row>
            </Col>
          ))}
        </Row>
        <br />
        <Title level={5} className="step-title">Logo</Title>
        <div><img src={image} style={{ width: '150px' }} /></div>
        <Title level={5} className="step-title">Tax Details</Title>
        <Row className="tab-row">
          {taxDetails.map((row) => (
            <Col key={row.title} xs={24} sm={24} md={12} lg={12} xl={12}>
              <Row>
                <Col className="col-title" span={12}>{row?.title}</Col>
                <Col className="col-value" span={12}>{row?.value}</Col>
              </Row>
            </Col>
          ))}
        </Row>
        <br />
        <Title level={5} className="step-title">Admin Details</Title>
        <Row className="tab-row">
          {adminData.map((row) => (
            <Col key={row.title} xs={24} sm={24} md={12} lg={12} xl={12}>
              <Row>
                <Col className="col-title" span={12}>{row?.title}</Col>
                <Col className="col-value" span={12}>{row?.value}</Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Drawer>
    </>
  );
}
