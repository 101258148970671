export const getAllCheckIn = `
query getAllCheckIn($workShopId: String!, $date1: String!, $date2: String!) {
  getAllCheckIn(workShopId: $workShopId, date1: $date1, date2: $date2) {
    batteryType
    batteryReplacementDate
    chassisNumber
    colourCode
    comment
    engineNumber
    fuelType
    insuranceNumber
    insuranceRenevalDate
    insuranceCompany
    jobType
    licenseNumber
    licenseRenevalDate
    make
    manufactureYear
    model
    ownerEmail
    totalPrice
    ownerName
    ownerTel
    plateNumber
    selectedDate
    selectedTime
    sortKey
    status
    tireSize
    userEmail
    userName
    userTel
    images
    bookingStdHrsMin
    workShopId
    updatedTime
    createdTime
    assigneeName
    assigneeId
    registerYear
    mileage
    investigationCompletedTime
  }
}
`;

export const getJobs = `
query getJobs($workShopId: String!, $bookingId: String!) {
  getJobs(workShopId: $workShopId, bookingId: $bookingId) {
    bookingId
    description
    plateNumber
    price
    qtyHours
    rate
    sortKey
    status
    qtyNo
    repairItem
    updatedTime
    createdTime
  }
}
`;

export const addJobs = `
mutation addJobs($checkInJobs: [inputJobs]) {
  addJobs(checkInJobs: $checkInJobs) {
    description
    plateNumber
    price
    qtyHours
    rate
    status
    updatedTime
    createdTime
  }
}
`;

export const deleteCheckIns = `
mutation deleteCheckIns($deleteJobs: [inputDeleteJobs]) {
  deleteCheckIns(deleteJobs: $deleteJobs) {
    description
    plateNumber
    status
  }
}
`;

export const getAllCheckInUnauth = `
query getAllCheckIn($workShopId: String!) {
  getAllCheckIn(workShopId: $workShopId) {
    selectedDate
    selectedTime
    sortKey
    workShopId
    plateNumber
    ownerName
    status
    progress
    searching
    comment
  }
}
`;
