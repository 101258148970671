/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
export enum TaskBoardItemStatus {
  TO_DO = 'To Do',
  IN_PROGRESS = 'In Progress',
  QC = 'Quality Control',
  PENDING_PAYMENT = 'Pending Payment',
}

export const PRIORITY = {
  REPAIR: 'repair',
  ACCIDENT: 'accident',
  BREAK_DOWN: 'breakdown',
  SERVICE: 'service',
  QUICK_JOBS: 'quickjobs',
};
