export const saveInvoice = `
mutation saveInvoice($newInvoice: InvoiceInput) {
  addInvoice(newInvoice: $newInvoice) {
    amount
    bookingId
    clientEmail
    clientMobile
    clientName
    plateNumber
    invoiceId
    status
    paymentMethod
    workShopId
    updatedTime
    createdTime
  }
}
`;

export const getInvoice = `
query getInvoice($invoiceId: String!) {
  getInvoice(invoiceId: $invoiceId) {
    amount
    bookingId
    clientEmail
    clientMobile
    clientName
    plateNumber
    paymentMethod
    status
    invoiceId
    workShopId
    updatedTime
    createdTime
  }
}
`;

export const updateInvoice = `
mutation updateInvoice($updatedInvoice: UpdatedInvoice) {
  updateInvoice(updatedInvoice: $updatedInvoice) {
    amount
    bookingId
    clientEmail
    clientMobile
    clientName
    plateNumber
    invoiceId
    paymentMethod
    status
    workShopId
    updatedTime
    createdTime
  }
}
`;
