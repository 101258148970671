import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import {
  APIS,
  deleteApi,
  getApi,
} from '../../../utils/apiUtils';
import { addUpdateJobCreationMutation, getAllJobs, searchJobQuary } from '../../../graphql-queries/jobCreation';

export const getSignedUrl = async (key: string, contentType: string, workShopId: string) => {
  try {
    return await getApi(APIS.JOB_CREATION_API, `jobcreation/signedurl?key=${key}&&contentType=${contentType}&&workShopId=${workShopId}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const createUpdateJob = async (jobCreation: any) => {
  try {
    return await API.graphql(graphqlOperation(addUpdateJobCreationMutation, { jobCreation }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const deleteJob = async (workShopId: string, sortKey: string) => {
  try {
    return await deleteApi(APIS.JOB_CREATION_API, `jobcreation?workShopId=${workShopId}&sortKey=${sortKey}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getAllJob = async (workShopId: string, limit: number, nextToken: any) => {
  try {
    return await API.graphql(graphqlOperation(getAllJobs, { workShopId: `${workShopId}`, limit: `${limit}`, nextToken }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const searchJob = async (workShopId: string, keyword: string) => {
  try {
    return await API.graphql(graphqlOperation(searchJobQuary, { workShopId: `${workShopId}`, searching: `${keyword}` }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};
