import { Tooltip, TooltipProps } from 'antd';

type BaseTooltipProps = TooltipProps;

function BaseTooltip(props: BaseTooltipProps) {
  // eslint-disable-next-line react/react-in-jsx-scope,react/jsx-props-no-spreading
  return <Tooltip mouseEnterDelay={1.5} {...props} />;
}

export default BaseTooltip;
