import React from 'react';
import {
  Card, Statistic, Progress, Row, Col, Avatar, Tooltip, Typography, Table,
} from 'antd';
import { UserOutlined, AntDesignOutlined } from '@ant-design/icons';
import { Line } from '@ant-design/charts';
import './home.scss';
import moment from 'moment';
import MainTour from './HomeTour';

export const DashboardPage = () => {
  const { Title } = Typography;

  const data = [
    { year: '1991', value: 3 },
    { year: '1992', value: 4 },
    { year: '1993', value: 3.5 },
    { year: '1994', value: 5 },
    { year: '1995', value: 4.9 },
    { year: '1996', value: 6 },
    { year: '1997', value: 7 },
    { year: '1998', value: 9 },
    { year: '1999', value: 13 },
  ];

  const config = {
    data,
    height: 400,
    xField: 'year',
    yField: 'value',
    point: {
      size: 5,
      shape: 'diamond',
    },
  };

  const tableColumns = [
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'VEHICLE OWNER',
      dataIndex: 'vehicleOwner',
      key: 'vehicleOwner',
    },
    {
      title: 'ASSIGNEE',
      dataIndex: 'assignee',
      key: 'assignee',
    },
  ];

  const jobsTableData = [
    {
      key: '1',
      type: 'John Brown',
      vehicleOwner: 32,
      assignee: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      type: 'John Brown',
      vehicleOwner: 32,
      assignee: 'New York No. 1 Lake Park',
    },
    {
      key: '3',
      type: 'John Brown',
      vehicleOwner: 32,
      assignee: 'New York No. 1 Lake Park',
    },
  ];

  const accidentsTableData = [
    {
      key: '1',
      type: 'John Brown',
      vehicleOwner: 32,
      assignee: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      type: 'John Brown',
      vehicleOwner: 32,
      assignee: 'New York No. 1 Lake Park',
    },
  ];

  const ProgressCard = () => (
    <Card className="card-styles">
      <Title level={5} className="title-styles">
        JOB CREATION
        <br />
        /PROCESSING
      </Title>
      <Progress type="circle" percent={75} style={{ marginLeft: '2vh' }} />
      <Row style={{ marginTop: 20 }}>
        <Col span={12} style={{ textAlign: 'center' }}>
          <span className="sub-title-styles" style={{ color: '#4ee83c' }}>{15}</span>
          <br />
          <span>Created</span>
        </Col>
        <Col span={12} style={{ borderLeft: '1px solid #8C8C8C', textAlign: 'center' }}>
          <span className="sub-title-styles" style={{ color: '#da0000' }}>{15}</span>
          <br />
          <span>Completed</span>
        </Col>
      </Row>
    </Card>
  );

  const StatisticCard = (props) => (
    <Card className="card-styles">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Statistic {...props} />
    </Card>
  );

  const UtilizationCard = () => (
    <Card className="card-styles">
      <Title level={5} className="title-styles">UTILIZATION 9/13</Title>
      <Avatar.Group>
        <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
        <Avatar style={{ backgroundColor: '#f56a00' }}>K</Avatar>
        <Tooltip title="Ant User" placement="top">
          <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
        </Tooltip>
        <Avatar style={{ backgroundColor: '#1890ff' }} icon={<AntDesignOutlined />} />
      </Avatar.Group>
    </Card>
  );

  // eslint-disable-next-line react/prop-types,no-shadow
  const TableCard = ({ title, columns, dataSource }) => (
    <Card className="card-styles">
      <Title level={5} className="title-styles" style={{ marginBottom: '15px' }}>{title}</Title>
      <Table columns={columns} dataSource={dataSource} pagination={false} />
    </Card>
  );

  // eslint-disable-next-line react/prop-types,no-shadow
  const ChartCard = ({ title }) => (
    <Card className="card-styles">
      <Title level={5}>{title}</Title>
      <span className="title-styles">{moment().format('LL')}</span>
      <br />
      <br />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Line {...config} />
    </Card>
  );

  return (
    <div className="dashboard-container">
      <MainTour />
      <Row style={{ paddingBottom: 15 }}>
        <Title level={3} style={{ color: '#8C8C8C' }}>Dashboard</Title>
      </Row>
      <Row gutter={16}>
        <Col md={5}>
          <StatisticCard title="BOOKINGS" value={200} style={{ height: '70px' }} />
        </Col>
        <Col md={5}>
          <StatisticCard title="ONGOING TICKETS" value={32} style={{ height: '70px' }} />
        </Col>
        <Col className="gutter-row" md={5}>
          <StatisticCard title="CLOSED TICKETS" value={70} style={{ height: '70px' }} />
        </Col>
        <Col className="gutter-row" md={9}>
          <UtilizationCard />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" md={5}>
          <div className="progress-percentage">
            <ProgressCard />
          </div>
        </Col>
        <Col className="gutter-row" md={10}>
          <TableCard title="JOBS" columns={tableColumns} dataSource={jobsTableData} />
        </Col>
        <Col className="gutter-row" lg={9}>
          <TableCard title="ACCIDENTS" columns={tableColumns} dataSource={accidentsTableData} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={24}>
          <ChartCard title="Jobs Completed Per Day" />
        </Col>
      </Row>
    </div>
  );
};

export default DashboardPage;
