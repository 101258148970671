/* eslint-disable no-return-await */
/* eslint-disable import/prefer-default-export */
import { message } from 'antd';
import { APIS, postApi } from '../../../utils/apiUtils';

export const addConfig = async (configData: Object) => {
  try {
    const res = await postApi(APIS.CONFIG_API, '/config', configData);
    message.destroy();
    message.success('Configurations Successfully Added');
    return res;
  } catch (err: any) {
    message.destroy();
    message.error(err);
    return null;
  }
};
