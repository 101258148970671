// eslint-disable-next-line import/prefer-default-export
export const INSURANCE_COMPANIES = [
  {
    value: 'Sri Lanka Insurance Corporation Ltd',
  },
  {
    value: 'AIG Insurance Limited',
  },
  {
    value: 'Continental Insurance Lanka Ltd',
  },
  {
    value: 'People’s Insurance PLC',
  },
  {
    value: 'Amana Takaful PLC',
  },
  {
    value: 'Allianz Insurance Lanka Ltd',
  },
  {
    value: 'Fairfirst Insurance Limited',
  },
  {
    value: 'HNB General Insurance Ltd',
  },
  {
    value: 'Sanasa General Insurance Company Limited',
  },
  {
    value: 'Ceylinco General Insurance Limited',
  },
  {
    value: 'LOLC General Insurance Limited',
  },
  {
    value: 'Orient Insurance Limited',
  },
  {
    value: 'Cooperative Insurance Company PLC',
  },
  {
    value: 'National Insurance Trust Fund',
  },
  {
    value: 'MBSL Insurance Company Limited',
  },
];
