import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Row,
  Col,
  Drawer,
  Button,
  message,
  Select,
  Radio,
} from 'antd';
import './addEditItem.scss';
import { ulid } from 'ulid';
import { createUpdateJob } from '../actions';
import { generateFields } from '../../../../utils/formUtil';

const { Option } = Select;

type AddEditItemType = {
  item: any
  fetchData: Function
  repairItems: any[]
  repairItemForms: any[]
  setModalVisible: Function
  isModalVisible: boolean
  workShopId: string
}

export const AddEditItem = ({
  item,
  fetchData,
  repairItems = [],
  repairItemForms = [],
  setModalVisible,
  isModalVisible,
  workShopId,
}: AddEditItemType) => {
  const [form] = Form.useForm();
  const [repairItem, setRepairItem] = useState<String>('');

  const onClose = () => {
    setModalVisible(false);
    form.resetFields();
  };

  const AddUpdateData = () => {
    const formData = form.getFieldsValue();
    const newJob = {
      workShopId,
      sortKey: `${ulid()}`,
      ...formData,
      price: (formData.labourRate * formData.stdHrsMin) / 60,
    };
    createUpdateJob(newJob).then(() => {
      fetchData();
      message.success('Successfully saved').then();
      onClose();
    }).catch(() => {
      message.error('Something went to wrong').then();
    });
  };

  const getRepairItem = (selectedItem: any) => {
    setRepairItem(selectedItem);
  };

  useEffect(() => {
    if (item) {
      form.setFieldsValue(item);
      setRepairItem(item.labourRateType);
    }
  }, [item]);

  return (
    <Drawer
      title={item ? 'Edit Job Detail' : 'Add Job Detail'}
      placement="right"
      width={800}
      onClose={onClose}
      visible={isModalVisible}
    >

      <Form
        form={form}
        onFinish={AddUpdateData}
        layout="vertical"
        hideRequiredMark
      >
        <Row gutter={16}>
          <Col span={12}>
            {repairItems && (
            <Form.Item
              name="labourRateType"
              label="Labour Rate Type"
              rules={[{ required: true, message: 'Please Enter Labour Rate Type' }]}
            >
              <Select
                onChange={getRepairItem}
                showSearch
                placeholder="Select a person"
                optionFilterProp="children"
              >
                {repairItems.map((i: any) => (
                  <Option
                    key={i.value}
                    value={i.value}
                  >
                    {i.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please Enter Description' }]}
            >
              <Input placeholder="Enter Description" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name="checkList"
              label="Check List"
              rules={[{ required: true, message: 'Please Enter Check List Have (1) / Don’t Have (0)' }]}
            >
              <Radio.Group optionType="button">
                <Radio value="0">0</Radio>
                <Radio value="1">1</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="assistantAllowed"
              label="Assistant Allowed"
              rules={[{ required: true, message: 'Please Enter Check List Have (1) / Don’t Have (0)' }]}
            >
              <Radio.Group optionType="button">
                <Radio value="0">0</Radio>
                <Radio value="1">1</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="uploadChecklist"
              label="Upload Check List"
            >
              <Input placeholder="Enter Upload Check List" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="mainTechnicianCategory"
              label="Main Technician category"
            >
              <Input placeholder="Enter Main Technician category" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="mainTechIncentive"
              label="Main Tech Incentive"
              rules={[{ required: true, message: 'Please Enter Main Tech Incentive' }, { pattern: new RegExp(/^(?!0\d)\d*(\.\d+)?$/), message: 'Input should be only positive numbers' }]}
            >
              <Input placeholder="Enter Main Tech Incentive" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="assistantTechnicianCategory"
              label="Assistant Technician category"
            >
              <Input placeholder="Enter Main Technician category" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="assistantTechIncentive"
              label="Assistant Tech Incentive"
              rules={[{ required: true, message: 'Please Enter Assistant Tech Incentive' }, { pattern: new RegExp(/^(?!0\d)\d*(\.\d+)?$/), message: 'Input should be only positive numbers' }]}
            >
              <Input placeholder="Enter Assistant Tech Incentive" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="category"
              label="Vehicle Category"
              rules={[{ required: true, message: 'Please Enter Vehicle Category' }]}
            >
              <Input placeholder="Enter Vehicle Category" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="applicableVehicleMake"
              label="Applicable Vehicle Make"
              rules={[{ required: true, message: 'Please Enter Applicable Vehicle Make' }]}
            >
              <Input placeholder="Enter Applicable Vehicle Make" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="applicableVehicleModel"
              label="Applicable Vehicle Model"
              rules={[{ required: true, message: 'Please Enter Applicable Vehicle Model' }]}
            >
              <Input placeholder="Enter Applicable Vehicle Model" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tax1Vat"
              label="Tax 1 (VAT %)"
              rules={[{ required: true, message: 'Please Enter Tax' }, { pattern: new RegExp(/^[0-9]+$/), message: 'Input should be only positive numbers' }]}
            >
              <Input placeholder="Enter Tax" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="tax2Vat"
              label="Tax 2 (NBT %)"
              rules={[{ required: true, message: 'Please Enter Tax' }, { pattern: new RegExp(/^[0-9]+$/), message: 'Input should be only positive numbers' }]}
            >
              <Input placeholder="Enter Tax" type="number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tax3"
              label="Tax 3 (Other %)"
              rules={[{ required: true, message: 'Please Enter Tax' }, { pattern: new RegExp(/^[0-9]+$/), message: 'Input should be only positive numbers' }]}
            >
              <Input placeholder="Enter Tax" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="incomeAccountCode"
              label="Income Account code"
            >
              <Select placeholder="Enter Income Account code">
                <Option value="0">0</Option>
                <Option value="1">1</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="instructionsNotes"
              label="Instructions / Notes"
              rules={[{ required: true, message: 'Please Enter INSTRUCTIONS / Notes' }]}
            >
              <Input placeholder="Enter Instructions / Notes" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="imageLink"
              label="Images (Hyper Link)"
              rules={[
                { required: true, message: 'Please Enter Images (Hyper Link)' },
                // eslint-disable-next-line
                { pattern: new RegExp(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi), message: 'Please enter valid Hyper Link' },
              ]}
            >
              <Input placeholder="Enter Images (Hyper Link)" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="videoLink"
              label="Videos (HyperLink)"
              rules={[
                { required: true, message: 'Please Enter Videos (HyperLink)' },
                // eslint-disable-next-line
                { pattern: new RegExp(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi), message: 'Please enter valid Hyper Link' },
              ]}
            >
              <Input placeholder="Enter Videos (HyperLink)" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {generateFields(repairItem, repairItems, repairItemForms)}
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="labourRate"
              label="Labour Rate"
              rules={[{
                required: true,
                message: 'Please Enter Labour Rate',
              },
              {
                pattern: new RegExp(/^(?!0\d)\d*(\.\d+)?$/),
                message: 'Please enter valid Labour Rate',
              }]}
            >
              <Input placeholder="Enter Labour Rate" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ float: 'right' }}>
          <Button className="sav-btn" htmlType="submit">
            Submit
          </Button>
        </Row>
      </Form>
    </Drawer>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    repairItems: state.userData.configData.repairItems,
    repairItemForms: state.userData.configData?.repairItemForms || [],
  };
}

export default connect(mapStateToProps, {})(AddEditItem);
