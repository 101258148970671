/* eslint-disable import/prefer-default-export */
export const addWorkshop = `
mutation addWorkshop($workshopName: String!, $regNo: String, $contactNumber: String, $address : String, $tradeName: String, $name: String, $adminContactNumber: String, $userName: String!, $email: String!, $status: String, $vat: Float, $ndbt: Float, $otherTaxes: Float, $logo: String) {
  addWorkshop(newWorkshop: {workshopName: $workshopName, regNo: $regNo, contactNumber: $contactNumber, address: $address, tradeName: $tradeName, name: $name, adminContactNumber: $adminContactNumber, userName: $userName, email: $email, status: $status, vat: $vat, ndbt: $ndbt, otherTaxes: $otherTaxes, logo: $logo}) {
    workshopName
    workShopId
    regNo
    contactNumber
    address
    tradeName
    name
    adminContactNumber
    userName
    email
    vat
    ndbt
    otherTaxes
    logo
    status
  }
}
`;

export const getAllWorkshops = `
query getAllWorkshops($limit: Int!, $nextToken: String) {
  getAllWorkshops(limit: $limit, nextToken: $nextToken) {
    workshop {
      address
      adminContactNumber
      category
      contactNumber
      email
      logo
      name
      ndbt
      otherTaxes
      regNo
      status
      tradeName
      userName
      vat
      workShopId
      workshopName  
    }
    nextToken
  }
}
`;

export const getWorkshop = `
query getWorkshop($workShopId: String!) {
  getWorkshop(workShopId: $workShopId) {
    workshopName
    vat
    workShopId
    userName
    tradeName
    status
    otherTaxes
    regNo
    ndbt
    logo
    name
    contactNumber
    email
    category
    adminContactNumber
    address
  }
}
`;

export const searchWorkshop = `
  query searchWorkshop($searching: String!) {
    searchWorkshop(searching: $searching) {
      workshopName
      vat
      workShopId
      userName
      tradeName
      status
      otherTaxes
      regNo
      ndbt
      logo
      name
      contactNumber
      email
      category
      adminContactNumber
      address
    }
  }
`;

export const updateWorkshop = `
mutation updateWorkshop($updateWorkshop: UpdateWorkshop) {
  updateWorkshop(updateWorkshop: $updateWorkshop) {
    address
    adminContactNumber
    category
    contactNumber
    email
    logo
    name
    ndbt
    otherTaxes
    regNo
    status
    tradeName
    userName
    vat
    workShopId
    workshopName
  }
}
`;
