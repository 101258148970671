import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Card, DatePicker } from 'antd';
import { Bar } from '@ant-design/plots';
import { getByEstimatedReceivedPayments } from '../action';
import { generateFromDate, generateToDate } from '../common';

const { RangePicker } = DatePicker;

type ChartDataType = {
  type: string,
  value: number
}

const mockData: ChartDataType[] = [];

const EstimateAndReceived = () => {
  const [chartData, setChartData] = useState<any[]>(mockData);
  const [date, setDate] = useState<any>({ from: generateFromDate(), to: generateToDate() });
  const dateFormat = 'YYYY-MM-DD';

  const onChange = (value, dateString) => {
    setDate({ from: dateString[0], to: dateString[1] });
  };

  const fetchData = async () => {
    const from: string = moment(date.from).format('YYYY-MM-DD');
    const to: string = moment(date.to).format('YYYY-MM-DD');
    const { data } : any = await getByEstimatedReceivedPayments(from, to);
    let dataArray;
    if (data) {
      dataArray = Object.values(data)
        .map(({ label, value }: any) => ({ label, value }));
    }
    setChartData(dataArray);
  };

  useEffect(() => {
    if (date) {
      fetchData();
    }
  }, [date]);

  return (
    <div>
      <Card
        title="Workshop estimated and received payments"
        extra={(
          <RangePicker
            defaultValue={[
              generateFromDate(),
              generateToDate(),
            ]}
            format={dateFormat}
            onChange={onChange}
          />
)}
        style={{ width: 1170 }}
      >
        <Bar
          data={chartData}
          xField="value"
          yField="label"
          seriesField="label"
          legend={{ position: 'top-left' }}
        />
      </Card>
    </div>
  );
};

export default EstimateAndReceived;
