import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_SN } from './sn/translations';
import { TRANSLATIONS_EN } from './en/translations';
import { TRANSLATIONS_TM } from './tm/translations';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      En: {
        translation: TRANSLATIONS_EN,
      },
      Sn: {
        translation: TRANSLATIONS_SN,
      },
      Tm: {
        translation: TRANSLATIONS_TM,
      },
    },
  });
export default i18n;
