/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Button, Row, Col, Popover, Form, Input, Divider, Collapse,
  DatePicker, message, AutoComplete, Select, Upload,
} from 'antd';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { onPreview } from '../../../../utils/commonUtil';
import { addVehicleDetails, getVehicleDetails, uploadVehicleContent } from '../actions';
import './components.scss';
import { S3_METHODS } from '../../../../enum/ConfigurationData';
import { INSURANCE_COMPANIES } from '../../../../enum/insuranceCompany';

function AddNewVehicle({ workShopId }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [plateNumberList, setPlateNumberList] = useState([]);
  const [fileList, setFileList] = useState<any[]>([]);

  const plateNumberHadler = (plateNumber: any) => {
    if (plateNumber !== '') {
      getVehicleDetails(workShopId, plateNumber.toLowerCase()).then((res: any) => {
        const array = [];
        res.data.getVehicle.map((item) => array.push({ value: item.plateNumber }));
        setPlateNumberList(array);
      });
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setFileList([]);
    setVisible(false);
  };

  const updateImages = async (vehicleId: string) => {
    if (fileList.length) {
      const [image] = fileList;
      message.destroy();
      message.loading('Uploading new images', 0);
      const contentType = image.type;
      const fileName = `${vehicleId}.${image.type.split('/').pop()}`;
      const key = `${workShopId}/${fileName}`;

      if (image.originFileObj) {
        const { body }: any = await uploadVehicleContent(key, S3_METHODS.put, contentType);
        if (!body) {
          message.destroy();
          message.error('Something went wrong when getting signed url');
          return (null);
        }
        await fetch(body.url, {
          method: 'PUT',
          headers: {
            'Content-Type': image.type,
          },
          body: image.originFileObj,
        })
          .then((ff) => ff.text())
          .then(() => {
            message.destroy();
            message.success('image uploaded successfully');
          });
      }
    }
    return null;
  };

  const addVehicle = async () => {
    const formData = form.getFieldsValue();

    const obj = { workShopId, ...formData };
    obj.manufactureYear = moment(obj.manufactureYear).year();
    obj.registerYear = moment(obj.registerYear).year();
    obj.mileage = parseFloat(obj.mileage);

    const { data = undefined }: any = await addVehicleDetails(obj);
    if (!data) {
      message.destroy();
      message.error('Something went wrong');
      return (null);
    }

    await updateImages(data.addVehicle.vehicleId);
    message.success('Vehicle details saved successfully');
    form.resetFields();
    onClose();
    return (null);
  };
  const validation = ({ errorFields }: any) => {
    if (errorFields.length) {
      const errorMessage = errorFields.length > 1 ? 'Please fill all the required fields' : errorFields[0].errors[0];
      message.destroy();
      message.error(errorMessage);
    }
  };
  const onImageChange = (info) => {
    const { fileList: newFileList } = info;
    setFileList(newFileList);
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  const fuelTypeOptions = [{ value: 'Petrol', label: 'Petrol' }, { value: 'Diesel', label: 'Diesel' }, { value: 'Hybrid', label: 'Hybrid' }, { value: 'Electric', label: 'Electric' }, { value: 'Other', label: 'Other' }];

  return (
    <>
      <Popover placement="leftBottom" content="Add New Vehicle" trigger="hover">
        <Button className="add-new-vehicle-button" onClick={showDrawer} style={{ backgroundColor: '#131A54' }} shape="circle" icon={<PlusOutlined style={{ color: 'white' }} />} size="large" />
      </Popover>

      <Drawer title={t('Add New Vehicle')} placement="right" width={800} onClose={onClose} visible={isVisible} className="add-new-vehicle-drawer">
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={addVehicle}
          className="add-new-vehicle"
          onFinishFailed={validation}
          requiredMark
        >
          <Collapse defaultActiveKey={['vehicleDetails']} accordion ghost bordered={false}>
            <Collapse.Panel className="collapse-header" header={t('Vehicle Details')} key="vehicleDetails">
              <Row gutter={16}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="plateNumber"
                    label={t('Vehicle Registration Number')}
                    rules={[() => ({
                      required: true,
                      validator(rule, value) {
                        if (plateNumberList.some((item: any) => item.value === value)) {
                          return Promise.reject(Error('Plate Number Already Exists'));
                        }
                        if (!value) return Promise.reject(Error('Please enter vehicle registration number'));
                        return Promise.resolve();
                      },
                    })]}
                  >
                    <AutoComplete onChange={plateNumberHadler} placeholder={t('Enter Plate Number')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="make"
                    label={t('Vehicle Make')}
                    rules={[{ required: true, message: `${t('Please enter vehicle make')}` }]}
                  >
                    <Input placeholder={t('Enter Vehicle Make')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="model"
                    label={t('Vehicle Model')}
                    rules={[{ required: true, message: `${t('Please enter vehicle model')}` }]}
                  >
                    <Input placeholder={t('Enter Vehicle Model')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="manufactureYear"
                    label={t('Year Of Manufacture')}
                    rules={[{ required: true, message: `${t('Please select year of manufacture')}` }]}
                  >
                    <DatePicker picker="year" style={{ width: '100%' }} placeholder={t('Select Year')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="registerYear"
                    label={t('Year of Register')}
                    rules={[{ required: true, message: `${t('Please select year of register')}` }]}
                  >
                    <DatePicker picker="year" style={{ width: '100%' }} placeholder={t('Select Year')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="engineNumber"
                    label={t('Engine Number')}
                    rules={[{ required: true, message: `${t('Please enter engine number')}` }]}
                  >
                    <Input placeholder={t('Enter Engine Number')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="chassisNumber"
                    label={t('Chassis Number')}
                    rules={[{ required: true, message: `${t('Please enter chassis number')}` }]}
                  >
                    <Input placeholder={t('Enter Chassis Number')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="mileage"
                    label={t('Mileage')}
                    rules={[{
                      required: true,
                      message: `${t('Please enter mileage')}`,
                    },
                    {
                      pattern: /^\d+$/,
                      message: 'Please enter a valid mileage ( mileage must be a number )',
                    }]}
                  >
                    <Input type="number" placeholder={t('Mileage')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="colourCode"
                    label={t('Color Code')}
                    rules={[{ required: true, message: `${t('Please enter color code')}` }]}
                  >
                    <Input placeholder={t('Enter Color Code')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="tireSize"
                    label={t('Tyre Size')}
                    rules={[{ required: true, message: `${t('Please enter Tyre size')}` }]}
                  >
                    <Input placeholder={t('Enter Tyre Size')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="batteryReplacementDate"
                    label={t('Battery Replacement Date')}
                    rules={[{ required: true, message: `${t('Please select battery replacement date')}` }]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                      placeholder={t('battery Replacement Date')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="fuelType"
                    label={t('Fuel Type')}
                    rules={[{ required: true, message: `${t('Please select fuel type')}` }]}
                  >
                    <Select options={fuelTypeOptions} placeholder={t('Enter Fuel Type')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={t('License Details')}
                    required
                  >
                    <Upload disabled />
                    <Upload
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onImageChange}
                      onPreview={onPreview}
                      customRequest={dummyRequest}
                      accept="image/png, image/jpeg"
                    >
                      + Upload
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="insuranceCompany"
                    label={t('Insurance Details')}
                    rules={[{ required: true, message: `${t('Please select insurance company')}` }]}
                  >
                    <Select
                      placeholder={t('Select Insurance Company')}
                      size="middle"
                    >
                      {INSURANCE_COMPANIES.map(({ value }: any) => (
                        <Select.Option value={value}>{value}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="insuranceRenevalDate"
                    rules={[{ required: true, message: `${t('Please select insurance expiry date')}` }]}
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                      placeholder={t('Insurance Expiry Date')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Divider style={{ margin: 10 }} />
            <Collapse.Panel className="collapse-header" header={t('Owner Details')} key="ownerDetails">
              {/* Row 1 */}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="ownerName"
                    label={t('Owner Name')}
                    rules={[{ required: true, message: `${t('Please enter vehicle owner name')}` },
                      { pattern: new RegExp(/^[a-zA-Z ]*$/), message: 'Input should contain only letters' }]}
                  >
                    <Input placeholder={t('Enter Owner Name')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="ownerTel"
                    label={t('Contact Number')}
                    rules={[
                      { required: true, message: `${t('Please enter contact number')}` },
                      {
                        pattern: /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g,
                        message: 'Invalid Contact number',
                      },
                    ]}
                  >
                    <Input placeholder={t('Enter Contact Number')} />
                  </Form.Item>
                </Col>
              </Row>
              {/* Row 2 */}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="ownerEmail"
                    label={t('Email')}
                    rules={[
                      {
                        type: 'email',
                        message: `${t('Please enter a valid email address')}`,
                      },
                      {
                        required: true,
                        message: `${t('Please enter email address')}`,
                      }]}
                  >
                    <Input placeholder={t('Enter Email')} />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Divider style={{ margin: 10 }} />
            <Collapse.Panel className="collapse-header" header={t('User Details (Optional)')} key="userDetails">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="userName"
                    label={t('Vehicle User Name')}
                    rules={[
                      { pattern: new RegExp(/^[a-zA-Z ]*$/), message: 'Input should contain only letters' }]}
                  >
                    <Input placeholder={t('Enter User Name')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="userTel"
                    rules={[{
                      pattern: /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g,
                      message: 'Invalid Contact number',
                    }]}
                    label={t('Contact Number')}
                  >
                    <Input placeholder={t('Enter Contact Number')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="userEmail"
                    label={t('email')}
                    rules={[
                      {
                        type: 'email',
                        message: `${t('Please enter a valid email address')}`,
                      },
                    ]}
                  >
                    <Input placeholder={t('Enter Email')} />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Divider style={{ margin: 10 }} />
          </Collapse>
          <Row>
            <Col span={21} />
            <Col span={3}>
              <Button className="sav-btn" htmlType="submit">
                {t('Save')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
  };
}

export default connect(mapStateToProps, {})(AddNewVehicle);
