import { ulid } from 'ulid';
import { FORMS } from './repairItemform';

export const repairItemGroups = [
  {
    id: ulid(),
    label: 'Labour only',
    value: 'labourOnly',
  },
  {
    id: ulid(),
    label: 'Parts',
    value: 'parts',
  },
  {
    id: ulid(),
    label: 'Materials',
    value: 'materials',
  },
  {
    id: ulid(),
    label: 'Materials + Labour',
    value: 'materialsLabour',
  },
  {
    id: ulid(),
    label: 'Quickjobs',
    value: 'quickjobs',
  },
];

export const repairItems = [
  {
    id: ulid(),
    group: repairItemGroups[0].id,
    formId: FORMS[4].id,
    label: 'Remove and Refitting charges -STD',
    value: 'Remove and Refitting charges -STD',
  },
  {
    id: ulid(),
    group: repairItemGroups[0].id,
    formId: FORMS[0].id,
    label: 'Panel Correction',
    value: 'Panel Correction',
  },
  {
    id: ulid(),
    group: repairItemGroups[1].id,
    formId: FORMS[4].id,
    label: 'Spare Parts - STD',
    value: 'Spare Parts - STD',
  },
  {
    id: ulid(),
    group: repairItemGroups[2].id,
    formId: FORMS[0].id,
    label: 'Sundries/Consumables',
    value: 'Sundries/Consumables',
  },
  {
    id: ulid(),
    group: repairItemGroups[3].id,
    formId: FORMS[4].id,
    label: 'Paint Process-OEM Approved Paint Process-STD',
    value: 'Paint Process-OEM Approved Paint Process-STD',
  },
  {
    id: ulid(),
    group: repairItemGroups[0].id,
    formId: FORMS[3].id,
    label: 'Pre Delivery Inspection - STD',
    value: 'Pre Delivery Inspection - STD',
  },
  {
    id: ulid(),
    group: repairItemGroups[0].id,
    formId: FORMS[0].id,
    label: 'RE/RE',
    value: 'RE/RE',
  },
  {
    id: ulid(),
    group: repairItemGroups[0].id,
    formId: FORMS[1].id,
    label: 'RE/RI',
    value: 'RE/RI',
  },
  {
    id: ulid(),
    group: repairItemGroups[3].id,
    formId: FORMS[4].id,
    label: 'Structural Repair Job - Non STD',
    value: 'Structural Repair Job - Non STD',
  },
  {
    id: ulid(),
    group: repairItemGroups[3].id,
    formId: FORMS[4].id,
    label: 'Mechanical Repair Job - Non STD',
    value: 'Mechanical Repair Job - Non STD',
  },
  {
    id: ulid(),
    group: repairItemGroups[3].id,
    formId: FORMS[0].id,
    label: 'Paint process job-inclusive of sub assemble material',
    value: 'Paint process job-inclusive of sub assemble material',
  },
  {
    id: ulid(),
    group: repairItemGroups[0].id,
    formId: FORMS[4].id,
    label: 'Periodic Maintenance - STD',
    value: 'Periodic Maintenance - STD',
  },
  {
    id: ulid(),
    group: repairItemGroups[4].id,
    formId: FORMS[5].id,
    label: 'Quickjobs',
    value: 'quickjobs',
  },
];
