import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';

const PasswordForm = ({
  form, staff, requestNewPassword, onLogin, t,
}: any) => (
  <Form form={form} layout="vertical" requiredMark={false} onFinish={onLogin}>
    <Form.Item label={`${t('Username')}`} className="ka_lbl_lgnfrm" name="username" rules={[{ required: true, message: 'Please input your username' }]}>
      <Input className="ks_int ks_int_lgnfrm" />
    </Form.Item>
    <Form.Item label={`${t('Password')}`} name="password" className="ka_lbl_lgnfrm" rules={[{ required: true, message: 'Please input your password' }]}>
      <Input.Password className="ks_int ks_int_lgnfrm" />
    </Form.Item>
    {(!staff && !requestNewPassword) && <Link to="/forget-password">Forgotten password?</Link>}
    <Form.Item>
      <Button type="primary" className="ka_btn_lgn" htmlType="submit">Sign in</Button>
    </Form.Item>
  </Form>
);

export default PasswordForm;
