import React from 'react';
import { Menu, Select } from 'antd';
import i18n from '../../../translation/i18n';

function LanguageMenu() {
  const { Option } = Select;

  function selectLanguage(e: any) {
    localStorage.setItem('lang', e);
    i18n.changeLanguage(e);
  }

  return (
    <Menu>
      <Select
        id="selLan"
        style={{ width: 200 }}
        onChange={(value) => selectLanguage(value)}
        placeholder="Select a language"
        defaultValue="En"
      >
        <Option value="En">English</Option>
        <Option value="Sn">Sinhala</Option>
        <Option value="Tm">Tamil</Option>
      </Select>
    </Menu>
  );
}

export default LanguageMenu;
