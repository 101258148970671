import { INPUT_TYPE, INPUT_TYPES } from './JobCreation';

export type FieldType = {
  sequence?: number,
  inputType: INPUT_TYPE,
  name: string,
  label: string,
  rules?: any[]
}

export type FormType = {
  id: string,
  label: string,
  form: FieldType[],
}

export const reReFields: FieldType[] = [
  {
    sequence: 1,
    label: 'STD Hrs (min)',
    inputType: INPUT_TYPES.TEXT,
    name: 'stdHrsMin',
  },
  {
    sequence: 2,
    label: 'FRT',
    inputType: INPUT_TYPES.CHECKBOX,
    name: 'frt',
  },
  {
    sequence: 3,
    label: 'RER',
    inputType: INPUT_TYPES.CHECKBOX,
    name: 'rer',
  },
  {
    sequence: 4,
    label: 'RH',
    inputType: INPUT_TYPES.CHECKBOX,
    name: 'rh',
  },
  {
    sequence: 5,
    label: 'LH',
    inputType: INPUT_TYPES.CHECKBOX,
    name: 'lh',
  },
];

export const reRiFields: FieldType[] = [
  {
    sequence: 1,
    label: 'STD Hrs (min)',
    inputType: INPUT_TYPES.TEXT,
    name: 'stdHrsMin',
  },
  {
    sequence: 2,
    label: 'FRT',
    inputType: INPUT_TYPES.CHECKBOX,
    name: 'frt',
  },
  {
    sequence: 3,
    label: 'RER',
    inputType: INPUT_TYPES.CHECKBOX,
    name: 'rer',
  },
  {
    sequence: 4,
    label: 'RH',
    inputType: INPUT_TYPES.CHECKBOX,
    name: 'rh',
  },
  {
    sequence: 5,
    label: 'LH',
    inputType: INPUT_TYPES.CHECKBOX,
    name: 'lh',
  },
];

export const nsmrFields: FieldType[] = [
  {
    sequence: 1,
    label: 'Repair Time',
    name: 'stdHrsMin',
    inputType: INPUT_TYPES.TEXT,
    rules: [
      { required: true, message: 'Please Enter Repair Time' },
      { pattern: new RegExp(/^(([1-9])+)$/), message: 'Invalid time value' },
    ],
  },
  {
    sequence: 2,
    label: 'FRT',
    name: 'frt',
    inputType: INPUT_TYPES.CHECKBOX,
  },
  {
    sequence: 3,
    label: 'RER',
    name: 'rer',
    inputType: INPUT_TYPES.CHECKBOX,
  },
  {
    sequence: 4,
    label: 'RH',
    name: 'rh',
    inputType: INPUT_TYPES.CHECKBOX,
  },
  {
    sequence: 5,
    label: 'LH',
    name: 'lh',
    inputType: INPUT_TYPES.CHECKBOX,
  },
];

export const nssrFields: FieldType[] = [
  {
    sequence: 1,
    label: 'Repair Time',
    inputType: INPUT_TYPES.TEXT,
    name: 'stdHrsMin',
    rules: [{ required: true, message: 'Please Enter Repair Time' }],
  },
  {
    sequence: 2,
    label: 'FRT',
    name: 'frt',
    inputType: INPUT_TYPES.CHECKBOX,
  },
  {
    sequence: 3,
    label: 'RER',
    name: 'rer',
    inputType: INPUT_TYPES.CHECKBOX,
  },
  {
    sequence: 4,
    label: 'RH',
    name: 'rh',
    inputType: INPUT_TYPES.CHECKBOX,
  },
  {
    sequence: 5,
    label: 'LH',
    name: 'lh',
    inputType: INPUT_TYPES.CHECKBOX,
  },
];

export const pdiStdFields: FieldType[] = [
  {
    sequence: 1,
    label: 'STD Hrs (min)',
    name: 'stdHrsMin',
    inputType: INPUT_TYPES.TEXT,
  },
];

export const quickjobsFields: FieldType[] = [
  {
    label: 'STD Hrs (min)',
    inputType: INPUT_TYPES.TEXT,
    name: 'stdHrsMin',
  },
];

export const serviceFields: FieldType[] = [
  {
    label: 'STD Hrs (min)',
    inputType: INPUT_TYPES.TEXT,
    name: 'stdHrsMin',
  },
];

export const FORMS: FormType[] = [
  {
    id: '01G6HQHPFBHNEF5J2WKY19M80Z',
    label: 'RE/RE',
    form: reReFields,
  },
  {
    id: '01G6HQHPFBF0ESTMFQ3QB6FMW6',
    label: 'RE/RI',
    form: reRiFields,
  },
  {
    id: '01G6HQHPFB25CSPC5M9BH7B5EM',
    label: 'NSMR',
    form: nsmrFields,
  },
  {
    id: '01G6HQHPFB15XDX2HJF4JVHKEQ',
    label: 'NSSR',
    form: nssrFields,
  },
  {
    id: '01G6HQHPFB6E8T6N8QG94W22G3',
    label: 'PDI STD',
    form: pdiStdFields,
  },
  {
    id: '01G76NTD9Y6EY0HCXQ83H4R59A',
    label: 'Quick Jobs',
    form: quickjobsFields,
  },
  {
    id: '01G76NVA8GJF63GKG8ZP5KRANY',
    label: 'Service',
    form: serviceFields,
  },
];
