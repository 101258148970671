/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow,import/prefer-default-export
export enum USER_ROLES {
    RECEPTIONIST = 'Receptionist',
    TECHNICIAN = 'Technician',
    SERVICE_ADVISOR = 'Service-Advisor',
    ADMIN = 'Admin',
    KARSTATION_ADMIN = 'Karstation-Admin',
    WORKSHOP_ADMIN = 'Workshop-Admin',
}
