import React, { useState } from 'react';
import './components.scss';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import validator from 'validator';
import {
  Drawer, Button, Row, Col, Popover, Typography, Tabs, Input, message, DatePicker,
} from 'antd';
import type { DatePickerProps } from 'antd';
import { CarOutlined } from '@ant-design/icons';
import moment from 'moment';
import { updateVehicleDetails } from '../actions';
import { INSURANCE_COMPANIES } from '../../../../enum/insuranceCompany';

function OwnerDetails(props: any) {
  const { Title } = Typography;
  const [visible, setVisible] = useState(false);
  const { details, userRoles } = props;

  const [updatedVehicleDetail, setUpdatedVehicleDetail] = useState(details || {});
  const [isClicked, setIsClicked] = useState(false);
  const [Error, setError] = useState<String>('');

  const { t } = useTranslation();

  const vehicleDetails = [
    {
      title: `${t('Vehicle Registration Number')}`,
      value: details?.plateNumber,
      key: 'plateNumber',
    },
    {
      title: `${t('Vehicle Make')}`,
      value: details?.make,
      key: 'make',
    },
    {
      title: `${t('Vehicle Model')}`,
      value: details?.model,
      key: 'model',
    },
    {
      title: `${t('Manufacture Year')}`,
      value: details?.manufactureYear,
      key: 'manufactureYear',
    },
    {
      title: `${t('Year of Register')}`,
      value: details?.registerYear,
      key: 'registerYear',
    },
    {
      title: `${t('Mileage')}`,
      value: details?.mileage,
      key: 'mileage',
    },
    {
      title: `${t('Color Code')}`,
      value: details?.colourCode,
      key: 'colourCode',
    },
    {
      title: `${t('Tyre Size')}`,
      value: details?.tireSize,
      key: 'tireSize',
    },
    {
      title: `${t('Fuel Type')}`,
      value: details?.fuelType,
      key: 'fuelType',
    },
    {
      title: `${t('Engine Number')}`,
      value: details?.engineNumber,
      key: 'engineNumber',
    },
    {
      title: `${t('Chassis Number')}`,
      value: details?.chassisNumber,
      key: 'chassisNumber',
    },
    {
      title: `${t('Insurance Company')}`,
      value: details?.insuranceCompany,
      key: 'insuranceCompany',
    },
    {
      title: `${t('Insurance Expiry Date')}`,
      value: moment(details?.insuranceRenevalDate).format('MMMM Do YYYY'),
      key: 'insuranceRenevalDate',
    },
    {
      title: `${t('Battery Replacement Date')}`,
      value: moment(details?.batteryReplacementDate).format('MMMM Do YYYY'),
      key: 'batteryReplacementDate',
    },
  ];

  const ownerDetails = [
    {
      title: `${t('Owner Name')}`,
      value: details?.ownerName,
      key: 'ownerName',
    },
    {
      title: `${t('Contact Number')}`,
      value: details?.ownerTel,
      key: 'ownerTel',
    },
    {
      title: `${t('Email')}`,
      value: details?.ownerEmail,
      key: 'ownerEmail',
    },
  ];

  const userDetails = [
    {
      title: `${t('Vehicle User Name')}`,
      value: details?.userName,
      key: 'userName',
    },
    {
      title: `${t('Contact Number')}`,
      value: details?.userTel,
      key: 'userTel',
    },
    {
      title: `${t('Email')}`,
      value: details?.userEmail,
      key: 'userEmail',
    },
  ];

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setIsClicked(false);
  };

  const onSave = () => {
    updateVehicleDetails(updatedVehicleDetail).then((res: any) => {
      if (res) {
        message.destroy();
        message.success('Successfully updated');
        onClose();
      } else {
        message.destroy();
        message.error('Failed to update');
      }
    });
  };
  const validateEmail = (e) => {
    const email = e.target.value;
    if (!validator.isEmail(email)) return setError('Invalid Email');
    return true;
  };
  const fuelTypeOptions = [{ value: 'Petrol', label: 'Petrol' }, { value: 'Diesel', label: 'Diesel' }, { value: 'Hybrid', label: 'Hybrid' }, { value: 'Electric', label: 'Electric' }, { value: 'Other', label: 'Other' }];

  const updateDetail = (event, item) => {
    const obj = updatedVehicleDetail;
    obj[item.key] = event;
    setUpdatedVehicleDetail(obj);
  };

  const hadleClick = () => {
    setIsClicked(true);
  };

  const footer = (
    <>
      <hr />
      {!isClicked ? (
        <Button
          type="primary"
          style={{
            float: 'right', marginBottom: '100px', marginTop: '10px', marginRight: '10px',
          }}
          onClick={hadleClick}
          enabled={userRoles.includes('Workshop-Admin') || userRoles.includes('Receptionist')}
        >
          {t('Edit')}

        </Button>
      ) : (
        <Button
          type="primary"
          style={{
            float: 'right', marginBottom: '100px', marginTop: '10px', marginRight: '10px',
          }}
          onClick={onSave}
        >
          {t('Save')}

        </Button>
      )}

      <Button style={{ marginRight: '10px', float: 'right', marginTop: '10px' }} onClick={onClose}>{t('Cancel')}</Button>
    </>
  );

  const onChangeM: DatePickerProps['onChange'] = (dateString) => {
    const obj = {
      ...updatedVehicleDetail,
      manufactureYear: moment(dateString).year(),
    };
    setUpdatedVehicleDetail(obj);
  };

  const onChangeR: DatePickerProps['onChange'] = (dateString) => {
    const obj = {
      ...updatedVehicleDetail,
      registerYear: moment(dateString).year(),
    };
    setUpdatedVehicleDetail(obj);
  };

  const inputFielsGenerator = (key, value, row) => {
    if (key === 'manufactureYear') {
      return (
        <DatePicker
          onChange={onChangeM}
          picker="year"
          defaultValue={value ? moment(value, 'YYYY') : undefined}
        />
      );
    }
    if (key === 'registerYear') {
      return (
        <DatePicker
          onChange={onChangeR}
          picker="year"
          defaultValue={value ? moment(value, 'YYYY') : undefined}
        />
      );
    }
    if (key === 'mileage') {
      return (
        <Input
          type="number"
          min="0"
          max="999999"
          defaultValue={value}
          onChange={(event) => updateDetail(event.target.value, row)}
        />
      );
    }
    if (key === 'fuelType') {
      return (
        <>
          <select className="fuelType" defaultValue={value} onChange={(event) => updateDetail(event.target.value, row)}>
            {fuelTypeOptions.map(({ value: _value }: any) => (
              <option value={_value}>{value}</option>
            ))}
          </select>
        </>
      );
    }
    if (key === 'insuranceCompany') {
      return (
        <>
          <select className="insuranceName" defaultValue={value} onChange={(event) => updateDetail(event.target.value, row)}>
            {INSURANCE_COMPANIES.map(({ value: _value }: any) => (
              <option value={_value}>{value}</option>
            ))}
          </select>
        </>
      );
    }
    if (key === 'ownerTel' || key === 'userTel') {
      return (
        <Input
          type="number"
          defaultValue={value}
          onChange={(event) => updateDetail(event.target.value, row)}
        />
      );
    }
    if (key === 'ownerEmail' || key === 'userEmail') {
      return (
        <Input
          type="email"
          pattern="/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/"
          defaultValue={value}
          onChange={(event) => validateEmail(event) && updateDetail(event.target.value, row)}
        />
      );
    }
    return <Input type="text" defaultValue={value} onChange={(event) => updateDetail(event.target.value, row)} />;
  };
  return (
    <>
      <Popover placement="rightBottom" content={t('info')} trigger="hover">
        <Button onClick={showDrawer} style={{ backgroundColor: '#27AE60' }} shape="circle" icon={<CarOutlined style={{ color: 'white' }} />} size="large" />
      </Popover>
      <Drawer width={window.innerWidth > 1001 ? 1000 : window.innerWidth - 2} placement="right" onClose={onClose} visible={visible} footer={footer}>
        <Tabs defaultActiveKey="1" type="card" animated>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-titl">{t('Vehicle Details')}</Title>
              </span>
            )}
            key="2"
          >
            <Row className="tab-row" id="vehicleDetails">
              {vehicleDetails.map((row) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>
                      {!isClicked ? row?.value : inputFielsGenerator(row?.key, row?.value, row)}
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-titl">{t('Owner Details')}</Title>
              </span>
            )}
            key="3"
          >
            <Row className="tab-row">
              {ownerDetails.map((row) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>
                      {!isClicked ? row?.value : inputFielsGenerator(row?.key, row?.value, row)}
                      <span style={{
                        fontWeight: 'bold',
                        color: 'red',
                      }}
                      >
                        {row.key === 'ownerEmail' && Error}

                      </span>

                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-titl">{t('User Details')}</Title>
              </span>
            )}
            key="4"
          >
            <Row className="tab-row">
              {userDetails.map((row) => (

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>
                      {!isClicked ? row?.value : inputFielsGenerator(row?.key, row?.value, row)}
                      <span style={{
                        fontWeight: 'bold',
                        color: 'red',
                      }}
                      >
                        {row.key === 'userEmail' && Error}

                      </span>

                    </Col>
                  </Row>
                </Col>

              ))}
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
    </>
  );
}
function mapStateToProps(state) {
  return {
    userRoles: state.userData.userRoles,
  };
}

export default connect(mapStateToProps, {})(OwnerDetails);
