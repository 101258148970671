import React, { useEffect, useState } from 'react';
import {
  Card, Typography, Row, Col, Tag, Button,
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import BaseTooltip from '../shared/BaseTooltip';
import ViewTask from './ViewTask';
import { useAppSelector } from '../../../../redux/hooks';
import { getColourByBookingType } from '../../../../utils/commonUtils';

const { Title } = Typography;

function TaskBoardItemCard(props: any) {
  const {
    item, status, getAllCheckin,
  } = props;
  const [colour, setColour] = useState('orange');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userId = useAppSelector((state) => state.userData.userId);
  const { t } = useTranslation();

  const onOpen = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (item) {
      setColour(getColourByBookingType(item.jobType.toLowerCase()));
    }
  }, [item]);

  return (
    <Card
      className="styled-card"
      size="small"
      style={{ height: '175px' }}
      title={(
        <BaseTooltip overlay={item.title}>
          <span>
            <Title className="task-board-item-card-title" level={5} ellipsis={{ rows: 2 }}>{t('Plate Number')}</Title>
          </span>
        </BaseTooltip>
      )}
      extra={(
        <Title className="task-board-item-card-title" level={5} ellipsis={{ rows: 2 }} style={{ marginRight: 16 }}>{item.plateNumber}</Title>
      )}
    >
      <BaseTooltip overlay={item.description}>
        <Row>
          <Col span={18} style={{ textAlign: 'left' }}>{item.ownerName}</Col>
          <Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              {moment(item.selectedDate).format('DD/MM/YYYY')}
            </Col>
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <Typography.Paragraph type="secondary" ellipsis={{ rows: 2 }}>
              {item.description}
            </Typography.Paragraph>
          </Col>
        </Row>
        <Row>
          <Col span={19}>
            <Tag className="jobStsTag" color={colour}>{t(item.jobType)}</Tag>
          </Col>
          <Col span={5}>
            <Button
              style={{
                backgroundColor: '#131A54', color: 'white', borderRadius: '8px', height: 25, width: 60, paddingTop: 0,
              }}
              onClick={onOpen}
            >
              {t('View')}

            </Button>
          </Col>
        </Row>
      </BaseTooltip>
      <Row style={{ float: 'right' }}>
        {userId && (
        <ViewTask
          item={item}
          status={status}
          getAllCheckin={getAllCheckin}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
        )}
      </Row>
    </Card>
  );
}

export default TaskBoardItemCard;
