/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { FileExcelOutlined } from '@ant-design/icons';
import {
  Button, Col, Drawer, Form, Input, message, Radio, Row, Typography, Upload, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { ulid } from 'ulid';
import {
  addWorkshopDetails, getSignedUrl, registerWorkshopAdmin, addConfig,
} from '../actions';
import { setConfigData } from '../../../redux/userSlice';
import { getConfigData } from '../../login/actions';
import { useAppDispatch } from '../../../redux/hooks';
import { CURRENCY_TYPE, CURRENCY_LANG_TYPE } from '../../../enum/ConfigurationData';
import '../workshop.scss';
import { repairItemGroups, repairItems } from '../../../enum/RepairItems';
import { FORMS } from '../../../enum/repairItemform';

export default function AddWorkshop({ fetchData }) {
  const [visible, setVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [signedUrl, setSignedUrl] = useState();
  const [logoKey, setLogoKey] = useState('');

  const { Title } = Typography;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useAppDispatch();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleChange = (info) => {
    let fileList = [...info.fileList];
    const fileType = info.file.name.split('.')[1];
    if (fileType !== 'png') {
      message.error(`${info.file.name} is not a png file`);
    } else {
      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      if (fileList.length && fileList[0].status === 'done') {
        const selectedFile = fileList[0].originFileObj;
        const key = `${ulid()}.png`;
        setLogoKey(key);
        const contentType = selectedFile.type;
        getSignedUrl(key, contentType).then((res) => {
          setSignedUrl(res.url);
        }).catch((err) => {
          message.destroy();
          message.error(err.message);
        });
      }

      // 2. Read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          // eslint-disable-next-line no-param-reassign
          file.url = file.response.url;
        }
        return file;
      });
      // @ts-ignore
      setFileList(fileList);
    }
  };

  const uploadToS3 = async () => {
    if (signedUrl && fileList.length && fileList[0]) {
      // @ts-ignore
      const selectedFile = fileList[0].originFileObj;
      // @ts-ignore
      fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': selectedFile.type,
        },
        body: selectedFile,
      })
        .then((res) => res.text())
        .then(() => {
          setFileList([]);
          message.success(`${selectedFile.name} uploaded successfully`);
        });
    }
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const props = {
    onChange: handleChange,
    multiple: true,
    fileList,
    customRequest: dummyRequest,
    accept: '.png, .jpg, .jpeg',
  };

  const saveConfigData = async (workShopId) => {
    const { currency } = form.getFieldsValue();

    const currencyInfo = { currency };

    if (currency) {
      switch (currency) {
        case CURRENCY_TYPE.AUD:
          currencyInfo.languageCode = CURRENCY_LANG_TYPE.AUD;
          break;
        case CURRENCY_TYPE.LKR:
          currencyInfo.languageCode = CURRENCY_LANG_TYPE.LKR;
          break;
        default:
          currencyInfo.languageCode = CURRENCY_LANG_TYPE.LKR;
          break;
      }
    }

    const obj = {
      workShopId,
      currency: currencyInfo,
      repairItemForms: FORMS,
      repairItems,
      repairItemGroups,
    };

    await addConfig(obj);
  };

  const loadConfigData = async (workShopId) => {
    const tempArray: any = [];
    const tempArray1: any = [];
    const configDataStore: any = await getConfigData(workShopId);
    configDataStore?.jobTypes?.map((item: any) => tempArray.push(item.value));
    configDataStore?.repairItems?.map((item: any) => tempArray1.push(item.value));
    dispatch(setConfigData(configDataStore));
  };

  const addWorkshops = async () => {
    const formData = form.getFieldsValue();
    uploadToS3();

    const obj = formData;
    obj.logo = logoKey;
    obj.vat = parseFloat(obj.vat);
    obj.ndbt = parseFloat(obj.ndbt);
    obj.otherTaxes = parseFloat(obj.otherTaxes);
    obj.tradeName = obj.workshopName;

    addWorkshopDetails(obj).then(async (res:any) => {
      if (!res) {
        message.error('Error');
      } else {
        await registerWorkshopAdmin(res.data.addWorkshop);
        await saveConfigData(res.data.addWorkshop.workShopId);
        await loadConfigData(res.data.addWorkshop.workShopId);
        onClose();
        message.success('Workshop Saved');
        form.resetFields();
        fetchData();
      }
    });
  };

  return (
    <>
      <Button onClick={showDrawer} className="workshop-button add-workshop-button">{t('AddNewWorkshops')}</Button>
      <Drawer title="Add New Workshop" visible={visible} onClose={onClose} width={700}>
        <Form
          form={form}
          onFinish={addWorkshops}
          layout="vertical"
          hideRequiredMark
        >
          <Title level={5} className="step-title">General Details</Title>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="workshopName"
                label="Workshop Name"
                style={{ marginBottom: '8px' }}
                rules={[{
                  required: true,
                  message: 'Please Enter Workshop Name',
                }]}
              >
                <Input placeholder="Enter Workshop Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="regNo"
                label="Registration Number"
                className="wrk-input"
                rules={[{ required: true, message: 'Please Enter Registration Number' }]}
              >
                <Input placeholder="Enter Registration Number" />
              </Form.Item>
              <Form.Item
                name="contactNumber"
                label="Workshop Contact Details"
                className="wrk-input"
                rules={[{
                  required: true,
                  message: 'Please Enter Workshop Contact Number',
                },
                {
                  pattern: /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g,
                  message: 'Please Enter Valid Contact Number',
                }]}
              >
                <Input placeholder="Enter Contact Number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="address"
                label="Address"
                className="wrk-input"
                rules={[{ required: true, message: 'Please Enter Address' }]}
              >
                <Input placeholder="Enter Address" style={{ height: '110px', textAlign: 'center' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Form.Item
              name="tradeName"
              label="Trade Name"
              className="wrk-input"
              hidden
            />
            <Col span={12}>
              <Form.Item
                name="logo"
                label="Upload Logo"
                className="wrk-input"
              >
                <Col md={3} span={3} style={{ paddingRight: '20px', paddingLeft: '20px' }}>
                  <Upload {...props}>
                    <Button style={{ width: 180 }} icon={<FileExcelOutlined />}>Select File</Button>
                  </Upload>
                </Col>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="currency"
                label="Currency"
                rules={[{ required: true, message: 'Please Select Currency' }]}
              >
                <Select placeholder="Currency">
                  {Object.values(CURRENCY_TYPE).filter((value) => typeof value === 'string').map((item) => <Option value={item}>{item}</Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Title level={5} className="step-title" style={{ marginBottom: '1px', marginTop: '15px' }}>Tax Details</Title>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="vat"
                label="VAT(%)"
                style={{ marginBottom: '8px' }}
                rules={[{
                  required: true,
                  message: 'Please Enter VAT rate',
                }, {
                  pattern: /[0-9]{1,2}((,|\.)[0-9]{1,10})?%?/,
                  message: 'Please Enter Valid VAT rate',
                }]}
              >
                <Input placeholder="Enter VAT rate" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="ndbt"
                label="NBT(%)"
                style={{ marginBottom: '8px' }}
                rules={[{
                  required: true,
                  message: 'Please Enter NBT rate',
                }, {
                  pattern: /[0-9]{1,2}((,|\.)[0-9]{1,10})?%?/,
                  message: 'Please Enter Valid NBT rate',
                }]}
              >
                <Input placeholder="Enter NBT rate" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="otherTaxes"
                label="Other Taxes(%)"
                className="wrk-input"
                rules={[{
                  required: true,
                  message: 'Please Enter Other Tax rates',
                }, {
                  pattern: /[0-9]{1,2}((,|\.)[0-9]{1,10})?%?/,
                  message: 'Please Enter Valid Other Tax rates',
                }]}

              >
                <Input placeholder="Enter Other Tax rates" />
              </Form.Item>
            </Col>
          </Row>
          <Title level={5} className="step-title" style={{ marginBottom: '1px', marginTop: '15px' }}>Admin Details</Title>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                style={{ marginBottom: '8px' }}
                rules={[{
                  required: true,
                  message: 'Please Enter Name',
                }, {
                  pattern: new RegExp(/^[a-zA-Z ]*$/),
                  message: 'Name should include only letters',
                }]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="adminContactNumber"
                label="Contact Details"
                style={{ marginBottom: '8px' }}
                rules={[{
                  required: true,
                  message: 'Please Enter Admin Contact Number',
                },
                {
                  pattern: /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g,
                  message: 'Please Enter Valid Contact Number',
                }]}
              >
                <Input placeholder="Enter Contact Details" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="userName"
                label="Username"
                className="wrk-input"
                rules={[{ required: true, message: 'Please Enter User Name' }]}
              >
                <Input placeholder="Enter Username" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                className="wrk-input"
                rules={[{
                  required: true,
                  message: 'Please Enter Email',
                },
                {
                  // eslint-disable-next-line no-useless-escape
                  pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: 'Please Enter Valid Email',
                }]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="status"
                label="Status"
                className="wrk-input"
                rules={[{ required: true, message: 'Please Select Status' }]}
              >
                <Radio.Group optionType="button">
                  <Radio value="Active">Active</Radio>
                  <Radio value="Inactive">Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}> </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'right' }}>
              <Button className="sav-btn" htmlType="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
