import {
  Button,
  message,
  Popover,
  Radio,
  Select,
  Tag,
} from 'antd';
import { CheckCircleOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { USER_ROLES } from '../../../../../enum/User';
import { JOB_STATUS } from '../../../../../enum/JobStatus';
import { sharedOnCell } from '../common';
import { updateJob, updateJobComment } from '../../actions';
import { BOOKING_TYPES } from '../../../../../enum/BookingStatus';
import { hasRole } from '../../../../../utils/commonUtil';

const preDefineReasons: any[] = [
  'Not enough resources',
  'Break/EOD',
  'Need Assistance',
  'Part Return - Damaged Part',
  'Part Return - Wrong Part',
  'Order Part',
  'Job Alteration',
  'Reason from the customer side',
];

const { Option } = Select;

const ToDoInProgressColumn = (
  t: Function,
  userRoles: any[],
  getJobs: Function,
  selectTechnician: Function,
  technicianList: any[],
  item: any,
  jobList: any[],
  setJobList: Function,
  getAllBookings: Function,
) => {
  const commentSelected = (e: any, job: any) => {
    updateJob(job.bookingId, job.sortKey, JOB_STATUS.PAUSED).finally();
    updateJobComment(job.bookingId, job.sortKey, e).then((res: any) => {
      getJobs();
      if (res) {
        if (job.comment) {
          message.destroy();
          message.success('Reason Updated').then();
        } else {
          message.destroy();
          message.success('Reason Added').then();
        }
      }
    }).catch((err) => {
      message.error(err);
    });
  };

  const jobProgressChange = async (job: any, status: any) => {
    let jobs = jobList.map(async (jobObj: any) => {
      const tempObj = jobObj;
      if (tempObj.sortKey === job.sortKey) {
        await updateJob(tempObj.bookingId, tempObj.sortKey, status.target.value);
        tempObj.status = status.target.value;
      }
      return tempObj;
    });
    jobs = await Promise.all(jobs);
    setJobList(jobs);
    getAllBookings();
  };

  return [
    {
      title: `${t('Description')}`,
      dataIndex: 'description',
      key: 'description',
      width: '140px',
      render: (title: string, job: any) => ({
        props: {
          style: job.title && {
            background: 'rgba(135,130,254,0.25)',
            fontWeight: 'bold',
            borderRadius: '4px',
          },
          colSpan: job.title ? 8 : 1,
          rowSpan: 1,
        },
        children:
  <>
    {job.description || job.title}
  </>,
      }),
    },
    {
      title: '',
      width: '5px',
      render: (title: string, job: any) => ({
        props: {
          colSpan: job.title ? 0 : 1,
        },
        children: (
          <>
            {job.qcComment && (
              <Popover content={job.qcComment} trigger="hover">
                <Button type="text" icon={<img src="/assets/MoreInfo.png" alt="QC comment" />} />
              </Popover>
            )}
          </>
        ),
      }),
    },
    {
      title: `${t('Standard Hours(min)')}`,
      dataIndex: 'qtyHours',
      key: 'qtyHours',
      width: '80px',
      align: 'center' as const,
      render: sharedOnCell,
    },
    {
      title: `${t('Qty')}`,
      dataIndex: 'qtyNo',
      key: 'qtyNo',
      width: '30px',
      render: sharedOnCell,
    },
    {
      title: `${t('Rate')}`,
      dataIndex: 'rate',
      key: 'rate',
      width: '80px',
      render: sharedOnCell,
      align: 'center' as const,
    },
    {
      title: `${t('Price')}`,
      dataIndex: 'price',
      key: 'price',
      width: '80px',
      render: sharedOnCell,
      align: 'right' as const,
    },
    {
      title: `${t('Status')}`,
      dataIndex: 'status',
      key: 'status',
      width: '80px',
      align: 'center' as const,
      render: (status: JOB_STATUS, job: any) => ({
        props: {
          colSpan: job.title ? 0 : 1,
        },
        children: (
          job.status === JOB_STATUS.REJECTED || job.status === JOB_STATUS.PENDING_PAYMENT
            ? (
              <Tag
                style={{
                  textAlign: 'center',
                  alignSelf: 'center',
                  borderRadius: '8px',
                  fontSize: 14,
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginLeft: 13,
                  marginRight: 13,
                }}
                color={job.status === JOB_STATUS.REJECTED ? 'red' : 'green'}
              >
                {job.status === JOB_STATUS.PENDING_PAYMENT ? t('QC Passed') : t('Rejected')}
              </Tag>
            )
            : (
              <Radio.Group
                disabled={hasRole([USER_ROLES.TECHNICIAN, USER_ROLES.RECEPTIONIST], userRoles)
                            || !(job.assigneeName
                                || job.assigneeName === 'undefined')}
                defaultValue={status || JOB_STATUS.START}
                style={{ width: 120 }}
                onChange={(change: any) => jobProgressChange(job, change)}
                buttonStyle="outline"
                size="small"
              >
                <Radio.Button className="radio-button-status" value={JOB_STATUS.START}>
                  <Popover placement="topLeft" content={t('Start')} trigger="hover">
                    <PlayCircleOutlined
                      className="status-icon"
                      style={{
                        fontSize: 20,
                        color: status === JOB_STATUS.START ? '#2F80ED' : '#131A54',
                      }}
                    />
                  </Popover>
                </Radio.Button>
                <Radio.Button
                  className="radio-button-status"
                  value={JOB_STATUS.PAUSED}
                  disabled={job?.comment === '' || !job?.comment}
                >
                  <Popover
                    placement="topLeft"
                    content={(
                      <div>
                        <Select
                          onSelect={(e: any) => commentSelected(e, job)}
                          style={{ width: '200px' }}
                          disabled={
                          hasRole([USER_ROLES.TECHNICIAN, USER_ROLES.RECEPTIONIST], userRoles)
                                          || !(job.assigneeName
                                              || job.assigneeName === 'undefined')
                              || job.status === BOOKING_TYPES.CHECKIN_TO_DO
}
                          value={job.comment || t('Select the Reason')}
                        >
                          {preDefineReasons.map((reason: string) => (
                            <Option value={reason}>
                              {reason}
                            </Option>
                          ))}
                        </Select>
                      </div>
                            )}
                    trigger="hover"
                  >
                    <PauseCircleOutlined
                      className="status-icon"
                      style={{
                        fontSize: 20,
                        color: status === JOB_STATUS.PAUSED ? '#E2B93B' : '#131A54',
                      }}
                    />
                  </Popover>
                </Radio.Button>
                <Radio.Button className="radio-button-status" value={JOB_STATUS.QC}>
                  <Popover placement="topLeft" content={t('Done')} trigger="hover">
                    <CheckCircleOutlined
                      className="status-icon"
                      style={{
                        fontSize: 20,
                        color: status === JOB_STATUS.QC ? '#27AE60' : '#131A54',
                      }}
                    />
                  </Popover>
                </Radio.Button>
              </Radio.Group>
            )
        ),
      }),
    },
    {
      title: `${t('Assignee')}`,
      dataIndex: 'assignee',
      key: 'assignee',
      align: 'left' as const,
      width: '140px',
      render: (assigneeId, job) => ({
        props: {
          colSpan: job.title ? 0 : 1,
        },
        children: (
          job.status === JOB_STATUS.REJECTED || job.status === JOB_STATUS.PENDING_PAYMENT
            ? <div />
            : (
              <Select
                showSearch
                optionFilterProp="children"
                placeholder={t('Assign to..')}
                style={{ width: 150, marginLeft: '-37px' }}
                defaultValue={job.assigneeName || null}
                onChange={(e: any) => selectTechnician(e, job)}
                disabled={hasRole([USER_ROLES.TECHNICIAN, USER_ROLES.RECEPTIONIST], userRoles)
                            || item.assigneeName === null}
                filterOption={(input: string, option: any) => {
                  const inputLowerCase = input.toLowerCase();
                  const optionChildren = option.children.toLowerCase();
                  return optionChildren.indexOf(inputLowerCase) >= 0;
                }}
                filterSort={(optionA: any, optionB: any) => {
                  const optionAChildren = optionA.children.toLowerCase();
                  const optionBChildren = optionB.children.toLowerCase();
                  return optionAChildren.localeCompare(optionBChildren);
                }}
              >
                {technicianList.map((technician: any) => (
                  <Option value={technician.employeeId}>{technician.name}</Option>
                ))}
              </Select>
            )
        ),
      }),
    },
  ];
};

export default ToDoInProgressColumn;
