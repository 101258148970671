import React, { useEffect, useState } from 'react';
import { Line } from '@ant-design/plots';
import moment from 'moment';
import { Card, DatePicker } from 'antd';
import { getBookingIncome } from '../action';
import { generateFromDateYear, generateToDateYear } from '../common';

const { RangePicker }: any = DatePicker;

  type ChartDataType = {
    label: string,
    value: number
  }

const mockData: ChartDataType[] = [];

const BookingIncome = () => {
  const [chartData, setChartData] = useState<any[]>(mockData);
  const [date, setDate] = useState<any>({ from: generateFromDateYear(), to: generateToDateYear() });
  const dateFormat = 'YYYY-MM-DD';

  const onChange = (value, dateString) => {
    setDate({ from: dateString[0], to: dateString[1] });
  };

  const fetchData = async () => {
    const from: string = moment(date.from).format('YYYY-MM-DD');
    const to: string = moment(date.to).format('YYYY-MM-DD');
    const { data } : any = await getBookingIncome(from, to);
    let dataArray: ChartDataType[] = mockData;
    if (data) {
      dataArray = Object.values(data)
        .map(({ label, value, year }: any) => (
          { label: `${year} ${label}`, value: parseFloat(value) }
        ));
    }
    setChartData(dataArray);
  };

  useEffect(() => {
    if (date) {
      fetchData();
    }
  }, [date]);

  return (
    <div>
      <Card
        title="Booking Income"
        extra={(
          <RangePicker
            onChange={onChange}
            defaultValue={[
              generateFromDateYear(),
              generateToDateYear(),
            ]}
            format={dateFormat}
          />
)}
        style={{ width: 1170 }}
      >
        <Line
          data={chartData}
          xField="label"
          yField="value"
          point={{
            size: 5,
            shape: 'circule',
            style: {
              fill: 'blue',
              stroke: '#5B8FF9',
              lineWidth: 2,
            },
          }}
          tooltip={{
            showMarkers: false,
          }}
          state={{
            active: {
              style: {
                shadowBlur: 4,
                stroke: '#000',
                fill: 'red',
              },
            },
          }}
          interactions={[{ type: 'marker-active' }]}
        />
      </Card>
    </div>
  );
};

export default BookingIncome;
