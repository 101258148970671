import Amplify, { Auth, API } from 'aws-amplify';
import { CONFIG } from '../configs/app-config';

export const APIS = {
  USER_API: 'user-service',
  BOOKING_API: 'booking-service',
  FINANCE_API: 'finance-api',
  CONFIG_API: 'config-api',
  INVENTORY_API: 'inventory-api',
  JOB_CREATION_API: 'job-creation-api',
  MOCK_BOOKING_API: 'get-all-bookings',
  CHECK_IN_API: 'check-in-api',
  EMPLOYEE_API: 'employee-api',
  EMAIL_API: 'email-service',
  WORKSHOP_API: 'workshop-api',
  VEHICLE_API: 'vehicle-api',
  GRN_API: 'grn-api',
};

const authHeader = async () => ({ Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` });

export const initApi = () => {
  // localStorage.clear();
  // Auth.currentCredentials();
  Amplify.configure({
    API: {
      endpoints: [
        {
          name: APIS.USER_API,
          endpoint: `${CONFIG.baseApiUrl}/user-api`,
          custom_header: authHeader,
        },
      ],
    },
  });
};
export async function postApi(api: String, path: String, body: any) {
  let options: any = { body };
  await Auth.currentSession().then((session) => {
    // @ts-ignore
    const { jwtToken } = session.idToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken,
      },
    };
  });
  return API.post(api, path, options);
}

export async function getApi(api: String, path: String) {
  let options: any = {};
  await Auth.currentSession().then((session) => {
    // @ts-ignore
    const { jwtToken } = session.idToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken,
      },
    };
  });
  return API.get(api, path, options);
}

export async function deleteApi(api: String, path: String) {
  let options: any = {};
  await Auth.currentSession().then((session) => {
    // @ts-ignore
    const { jwtToken } = session.idToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken,
      },
    };
  });
  return API.del(api, path, options);
}
