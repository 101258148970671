import { Tag } from 'antd';
import React from 'react';
import { JOB_STATUS } from '../../../../../enum/JobStatus';
import { sharedOnCell } from '../common';

const getColour = (status: JOB_STATUS) => {
  switch (status) {
    case JOB_STATUS.PENDING_PAYMENT:
      return '#27AE60';
    case JOB_STATUS.REJECTED:
      return 'red';
    default:
      return '#EC0F0F';
  }
};

const getStatus = (status: JOB_STATUS) => {
  switch (status) {
    case JOB_STATUS.PENDING_PAYMENT:
      return 'Pass';
    case JOB_STATUS.REJECTED:
      return 'Rejected';
    default:
      return 'Fail';
  }
};

const getStyles = (status: JOB_STATUS) => {
  const styles: any = {
    textAlign: 'center',
    alignSelf: 'center',
    borderRadius: '8px',
    fontSize: 14,
    padding: 2,
    paddingLeft: 4,
    paddingRight: 4,
  };
  if (status === JOB_STATUS.REJECTED) {
    styles.marginLeft = 13;
    styles.marginRight = 13;
    styles.paddingLeft = 5;
    styles.paddingRight = 5;
  } else {
    styles.width = 60;
  }
  return styles;
};

const PendingPaymentColumn = (t: Function, isQuickBooking: boolean) => [
  {
    title: `${t('Description')}`,
    dataIndex: 'description',
    key: 'description',
    width: '180px',
    render: (title, item) => ({
      props: {
        style: item.title && {
          background: '#8782FE80',
          fontWeight: 'bold',
          borderRadius: '4px',
        },
        colSpan: item.title ? 7 : 1,
        rowSpan: 1,
      },
      children: <div>{item.description || item.title}</div>,
    }),
  },
  {
    title: `${t('Standard Time')}`,
    dataIndex: 'qtyHours',
    key: 'qtyHours',
    width: '80px',
    align: 'center' as const,
    render: sharedOnCell,
  },
  {
    title: `${t('Rate')}`,
    dataIndex: 'rate',
    key: 'rate',
    width: '80px',
    render: sharedOnCell,
    align: 'left' as const,
  },
  {
    title: `${t('Qty')}`,
    dataIndex: 'qtyNo',
    key: 'qtyNo',
    width: '30px',
    render: sharedOnCell,
  },
  {
    title: `${t('Price')}`,
    dataIndex: 'price',
    key: 'price',
    width: '80px',
    render: sharedOnCell,
    align: 'left' as const,
  },
  {
    title: isQuickBooking ? '' : `${t('QC Status')}`,
    dataIndex: 'status',
    key: 'status',
    align: 'center' as const,
    width: '80px',
    render: (status, item) => ({
      props: {
        colSpan: item.title ? 0 : 1,
      },
      children: (
        isQuickBooking ? '' : (
          <Tag
            style={getStyles(item?.status)}
            color={getColour(item?.status)}
          >
            {t(getStatus(item?.status))}
          </Tag>
        )
      ),
    }),
  },
];

export default PendingPaymentColumn;
