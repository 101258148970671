import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { S3_METHODS } from '../../../enum/ConfigurationData';
import { searchGRNItem, getGRNItems, updateGRNInvoiceItemMutation } from '../../../graphql-queries/grnInvoice';
import {
  APIS, deleteApi, getApi, postApi,
} from '../../../utils/apiUtils';

export const updateGRN = async (grnInvoiceItem) => {
  try {
    return await API.graphql(graphqlOperation(updateGRNInvoiceItemMutation, { grnInvoiceItem }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getAllGRN = async (workShopId, limit, nextToken) => {
  try {
    return await API.graphql(graphqlOperation(getGRNItems, { workShopId: `${workShopId}`, limit: `${limit}`, nextToken }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const searchGRN = async (workShopId, keyword) => {
  try {
    return await API.graphql(graphqlOperation(searchGRNItem, { workShopId: `${workShopId}`, searching: `${keyword}` }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getSignedUrl = async (key, method, contentType = undefined) => {
  try {
    const contentTypeP: string = contentType ? `&contentType=${contentType}` : '';
    return await getApi(APIS.GRN_API, `signed-url?key=${key}&method=${method}${contentTypeP}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const deleteAttachment = async (key) => {
  try {
    return await postApi(APIS.GRN_API, 'delete-grn', { prefix: key });
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};
export const deleteImage = async (key: string) => {
  try {
    return await deleteApi(APIS.INVENTORY_API, `delete-image?key=${key}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getGrnContent = async (key) => {
  try {
    return await getApi(APIS.GRN_API, `signed-url?key=${key}&method=${S3_METHODS.get}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};
