import React, { useEffect, useState } from 'react';
import '../Grn.scss';
import {
  Drawer, Row, Col, Upload, message,
} from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getGrnContent } from '../actions';
import { dummyRequest, onPreviewFile } from '../../../../utils/commonUtil';

type PropType = {
  grnItems: any,
  isModalVisible: boolean,
  setModalVisible: Function,
  workShopId: string
}

const ViewItem = ({
  grnItems,
  isModalVisible,
  setModalVisible,
  workShopId,
}: PropType) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const itemData = [
    {
      title: `${t('Item ID')}`,
      value: grnItems?.grnInvoiceId,
    },
    {
      title: `${t('Min Issue Quantity')}`,
      value: grnItems?.minIssueQuantity,
    },
    {
      title: `${t('Invoice number')}`,
      value: grnItems?.invoiceNumber,
    },
    {
      title: `${t('Description')}`,
      value: grnItems?.description,
    },
    {
      title: `${t('Quantity')}`,
      value: grnItems?.quantity,
    },
    {
      title: `${t('Units')}`,
      value: grnItems?.units,
    },
    {
      title: `${t('Min Order Quantity')}`,
      value: grnItems?.minOrderQty,
    },
  ];

  const onClose = () => {
    setModalVisible(false);
  };

  const loadAttachments = async () => {
    const promises = await grnItems.image.map(async (attachment: string) => {
      const { body: { url } } = await getGrnContent(`${workShopId}/${attachment}`);
      return {
        uid: attachment?.split('.')[0],
        name: grnItems.image.length > 1 ? attachment : 'View attachment',
        status: 'done',
        url,
      };
    });
    const files = await Promise.all(promises);
    setFileList(files);
  };

  const onImageChange = (info) => {
    const { fileList: newFileList } = info;
    setFileList(newFileList);
  };

  useEffect(() => {
    try {
      if (grnItems?.image?.length) {
        loadAttachments().finally();
      }
    } catch (e: any) {
      message.error(e.message);
    }
  }, [grnItems]);

  return (
    <Drawer
      title="View Invoice"
      width={700}
      placement="right"
      onClose={onClose}
      visible={isModalVisible}
    >
      <Row className="tab-row">
        {itemData.map((row: any) => (
          <Col key={row.title} xs={24} sm={24} md={12} lg={12} xl={12}>
            <Row>
              <Col className="col-title" span={12}>{row?.title}</Col>
              <Col className="col-value" span={12}>{row?.value}</Col>
            </Row>
          </Col>
        ))}
        {grnItems?.image && (
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row>
            <Col className="col-title" span={12}>{t('Attachments')}</Col>
            <Col className="col-value" span={12}>
              <Upload
                fileList={fileList}
                onChange={onImageChange}
                onPreview={onPreviewFile}
                customRequest={dummyRequest}
                accept=".pdf"
                disabled
              />
            </Col>
          </Row>
        </Col>
        )}
      </Row>

    </Drawer>
  );
};
function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
  };
}
export default connect(mapStateToProps, {})(ViewItem);
