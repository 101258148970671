import React, { useEffect } from 'react';
import { Typography, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useAppDispatch } from '../../../redux/hooks';
import { setConfigData } from '../../../redux/userSlice';
import { getConfigData } from '../../login/actions';
import General from './componentes/General';

const { TabPane } = Tabs;

export const Settings = ({ workShopId }: any) => {
  const { Title } = Typography;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const loadConfigData = async () => {
    const tempArray: any = [];
    const tempArray1: any = [];
    const configDataStore: any = await getConfigData(workShopId);
    configDataStore?.jobTypes?.map((item: any) => tempArray.push(item.value));
    configDataStore?.repairItems?.map((item: any) => tempArray1.push(item.value));
    dispatch(setConfigData(configDataStore));
  };

  useEffect(() => {
    if (workShopId) {
      loadConfigData().finally();
    }
  }, [workShopId]);

  return (
    <>
      <Title level={3} style={{ color: '#8C8C8C' }}>{t('Configuration Data')}</Title>
      <div style={{ color: '#8C8C8C', fontSize: '16px' }}>
        <p>
          Please select the “Type of Jobs” & “Repair Items” that
          your business provides to the customer
          along with the “currency” handled
        </p>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="General" key="1">
          <General t={t} loadConfigData={loadConfigData} />
        </TabPane>
        {/* <TabPane tab="Repair Items" key="2"> */}
        {/*  <Row> */}
        {/*    <Col md={10}> */}
        {/*      <RepairItemGroup t={t} loadConfigData={loadConfigData} /> */}
        {/*    </Col> */}
        {/*    <Col md={1} /> */}
        {/*    <Col md={10}> */}
        {/*      <RepairItem t={t} loadConfigData={loadConfigData} /> */}
        {/*    </Col> */}
        {/*  </Row> */}
        {/* </TabPane> */}
      </Tabs>
    </>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    configData: state.userData.configData,
  };
}

export default connect(mapStateToProps, {})(Settings);
