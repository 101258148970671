/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import TaskBoardItemCard from './TaskBoardItemCard';
import './taskboard.scss';
import { BOOKING_TYPES } from '../../../../enum/BookingStatus';

function TaskBoardCol({
  items,
  status,
  getAllCheckin,
}: any) {
  const [checkInTitle, setCheckInTitle] = useState<string>('');
  const { t } = useTranslation();
  const selectTitle = () => {
    let title = '';
    if (status === BOOKING_TYPES.CHECKIN_TO_DO) {
      title = `${t('CHECK-IN')}`;
    } else if (status === BOOKING_TYPES.CHECKIN_IN_PROGRESS) {
      title = `${t('PENDING CUSTOMER APPROVAL')}`;
    } else if (status === BOOKING_TYPES.TO_DO) {
      title = `${t('JOBS IN QUEUE')}`;
    }
    setCheckInTitle(`${title} (${items.length})`);
  };

  useEffect(() => {
    if (status) {
      selectTitle();
    }
  }, [status, items]);

  return (
    <Card
      className="task-board-col-root"
      style={{ width: '550px' }}
      title={checkInTitle}
    >
      <Droppable droppableId={status}>
        {(provided) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div className="droppable-root" ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {/* eslint-disable-next-line no-shadow */}
                {(provided, snapshot) => (
                  <div key={item.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <TaskBoardItemCard
                      item={item}
                      status={status}
                      isDragging={snapshot.isDragging}
                      getAllCheckin={getAllCheckin}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Card>
  );
}

export default TaskBoardCol;
