import {
  Button, Col, Form, Row, Select, Switch,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CURRENCY_TYPE, globalPartArr, JOB_TYPES } from '../../../../enum/ConfigurationData';
import { addConfig } from '../actions';
import { FORMS } from '../../../../enum/repairItemform';

type generalType = {
  workShopId: string,
  loadConfigData: Function,
  configData: any,
  t: Function
};

const General = ({
  workShopId,
  loadConfigData,
  configData,
  t,
}: generalType) => {
  const [globalPart, setGlobalParts] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { Option } = Select;

  const saveConfigData = async () => {
    const {
      jobTypes = [],
      currency,
      repairItemForms = [],
    } = form.getFieldsValue();
    const currencyInfo = {
      currency,
      languageCode: currency && (currency === 'AUD' ? 'AU' : 'si-LK'),
    };
    const jTypes: any[] = jobTypes.map((item) => ({ value: item, label: item }));
    const rItemFormData: any[] = FORMS.filter(({ id }) => repairItemForms.includes(id));

    const obj = {
      ...configData,
      workShopId,
      jobTypes: jTypes,
      repairItemForms: rItemFormData,
      globalParts: globalPart ? globalPartArr : null,
      currency: currencyInfo,
    };

    await addConfig(obj);
    await loadConfigData();
  };

  const loadGeneralData = () => {
    const {
      jobTypes = [],
      currency = { currency: '' },
      globalParts = [],
      repairItemForms = [],
    }: any = configData;

    const jTypes = jobTypes.map((job: any) => job.value);
    const rItemForms = repairItemForms.map(({ id }: any) => (id));

    setGlobalParts(!!globalParts);
    form.setFieldsValue({
      jobTypes: jTypes,
      currency: currency?.currency,
      repairItemForms: rItemForms,
    });
  };

  useEffect(() => {
    if (configData) {
      loadGeneralData();
    }
  }, [configData]);

  return (
    <Form layout="vertical" form={form}>
      <Form.Item label={`${t('JobTypes')}`} name="jobTypes">
        {form.getFieldValue('jobTypes') && (
        <Select mode="multiple" style={{ width: '50%' }}>
          {Object.values(JOB_TYPES)
            .filter((value) => typeof value === 'string')
            .map((item: any) => (
              <Option
                value={item.toString().toLowerCase()}
                key={item.toString().toLowerCase()}
              >
                {item}
              </Option>
            ))}
        </Select>
        )}
      </Form.Item>
      <Form.Item label={`${t('Repair Item Forms')}`} name="repairItemForms">
        <Select mode="multiple" style={{ width: '50%' }}>
          {FORMS.map(({ id, label }: any) => (
            <Option
              value={id}
              key={id}
            >
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Currency" name="currency">
        <Select style={{ width: '10%' }} placeholder="Currency">
          {Object.values(CURRENCY_TYPE)
            .map((item: string) => (
              <Option
                value={item}
                key={item}
              >
                {item}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Row>
        <Col span={6}>
          <h3>Global Parts Search</h3>
        </Col>
        <Col span={12}>
          <Switch checked={globalPart} onChange={(e: boolean) => setGlobalParts(e)} />
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={12} style={{ display: 'flex', justifyContent: 'right' }}>
          <Button className="sav-btn" htmlType="submit" onClick={saveConfigData}>
            Save
          </Button>
        </Col>
        <Col span={3} />
      </Row>
      <br />
    </Form>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    configData: state.userData.configData,
  };
}

export default connect(mapStateToProps, {})(General);
