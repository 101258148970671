import React, { useEffect } from 'react';
import '../staff.scss';
import {
  Button, Col, DatePicker, Form, Input, message, Radio, Row, Select, Tabs, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import { updateEmployee } from '../action';

const EditEmployee = (props: any) => {
  const {
    employee, workShopId, updateStaff, setVisible,
  } = props;
  const [form] = Form.useForm();

  const { Title } = Typography;
  const { t } = useTranslation();

  const onSubmit = () => {
    const updatedEmployee = form.getFieldsValue();
    updatedEmployee.employeeId = employee.employeeId;
    updatedEmployee.workShopId = workShopId;
    message.loading('Updating..');
    updateEmployee(updatedEmployee).then(() => {
      updateStaff();
      setVisible(false);
      message.destroy();
      message.success('Employee details updated successfully');
    }).catch((error) => {
      console.log(error);
    });
  };

  const setInitialValue = () => {
    form.setFieldsValue({
      name: employee?.name,
      role: employee?.role,
      nic: employee?.nic,
      dob: moment(employee?.dob),
      userName: employee?.userName,
      contactNumber: employee?.contactNumber,
      address: employee?.address,
      status: employee?.status,
      accountHolderName: employee?.accountHolderName,
      accountNumber: employee?.accountNumber,
      bank: employee?.bank,
      branch: employee?.branch,
      basicSalary: employee?.basicSalary,
      epfEmployeeContribution: employee?.epfEmployeeContribution,
      epfCompanyContribution: employee?.epfCompanyContribution,
      etf: employee?.etf,
    });
  };

  useEffect(() => {
    setInitialValue();
  }, [employee]);

  return (
    <Form
      form={form}
      layout="vertical"
      hideRequiredMark
      size="middle"
      onFinish={onSubmit}
    >
      <Tabs defaultActiveKey="1" type="card" animated>
        <Tabs.TabPane
          tab={(
            <span>
              <Title level={5} className="drawr-titl">{t('General')}</Title>
            </span>
            )}
          key="1"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label={t('Employee Name')}
                rules={[{ required: true, message: `${t('Please Enter Employee Name')}` },
                  { pattern: new RegExp(/^[a-zA-Z ]*$/), message: 'Input should contain only letters' }]}
              >
                <Input placeholder={t('Enter Employee Name')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="role"
                label={`${t('Employee Role')}`}
                rules={[{ required: true, message: `${t('Please Select Employee Role')}` }]}
              >
                <Select>
                  <Select.Option value="Karstation-Admin">Karstation Admin</Select.Option>
                  <Select.Option value="Admin">Admin</Select.Option>
                  <Select.Option value="Receptionist">Receptionist</Select.Option>
                  <Select.Option value="Technician">Technician</Select.Option>
                  <Select.Option value="Service-Advisor">Service Advisor</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={`${t('NIC Number')}`}
                name="nic"
                rules={[
                  {
                    required: true,
                    message: `${t('Please Enter Employee NIC Number')}`,
                  },
                ]}
              >
                <Input placeholder={t('Enter NIC Number')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={`${t('Date of Birth (Optional)')}`}
                    name="dob"
                    rules={[{ required: true, message: `${t('Please Enter Date of Birth')}` }]}
                  >
                    <DatePicker style={{ width: '100%' }} format="YYYY/MM/DD" placeholder={t('Select Date of Birth')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={`${t('Contact Number')}`}
                    name="contactNumber"
                    rules={[
                      {
                        required: true,
                        message: `${t('Please Enter Employee Contact Number')}`,
                      },
                      {
                        pattern: /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g,
                        message: 'Invalid Contact number',
                      },
                    ]}
                  >
                    <Input placeholder={t('Enter Contact Number')} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Form.Item
                label={`${t('Address (Optional)')}`}
                name="address"
              >
                <Input.TextArea placeholder={t('Enter Employee Address')} showCount maxLength={100} rows={5} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="userName"
                label={`${t('Username')}`}
                rules={[{ required: true, message: `${t('Please Enter User Name')}` }]}
              >
                <Input placeholder={t('Enter User Name')} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="status"
                label={`${t('Status')}`}
                rules={[{ required: true, message: `${t('Please Select Status')}` }]}
              >
                <Radio.Group optionType="button">
                  <Radio value="Active">{t('Active')}</Radio>
                  <Radio value="Inactive">{t('Inactive')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={(
            <span>
              <Title level={5} className="drawr-titl">{t('Qualifications')}</Title>
            </span>
            )}
          key="2"
        >
          <Row gutter={16}>
            <Col span={24} />
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={(
            <span>
              <Title level={5} className="drawr-titl">{t('Salary')}</Title>
            </span>
            )}
          key="3"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="accountHolderName"
                label={`${t('Account Holder Name')}`}
                rules={[{ required: true, message: `${t('Please Enter Account Holder Name')}` },
                  { pattern: new RegExp(/^[a-zA-Z ]*$/), message: 'Input should contain only letters' }]}
              >
                <Input placeholder={t('Enter Account Holder Name')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="bank"
                label={`${t('Employee Bank')}`}
                rules={[{ required: true, message: `${t('Please Enter Employee Bank')}` }]}
              >
                <Input placeholder={t('Enter Employee Bank')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="branch"
                label={`${t('Branch')}`}
                rules={[{ required: true, message: `${t('Please Enter Branch')}` }]}
              >
                <Input placeholder={t('Enter The Branch')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="accountNumber"
                label={`${t('Account Number')}`}
                rules={[{ required: true, message: `${t('Please Enter Account Number')}` },
                  { pattern: new RegExp(/^[0-9]*$/), message: 'Input should contain only numbers' }]}
              >
                <Input placeholder={t('Enter Account Number')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="basicSalary"
                label={`${t('Basic Salary')}`}
                rules={[{ required: true, message: `${t('Please Enter Basic Salary')}` },
                  { pattern: new RegExp(/^[0-9]*$/), message: 'Input should be only numbers' }]}
              >
                <Input placeholder={t('Enter Basic Salary')} />
              </Form.Item>
            </Col>
          </Row>
          <Title level={5}>{t('Salary Details')}</Title>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="epfEmployeeContribution"
                label={`${t('EPF Employee Contribution')}`}
                rules={[{ required: true, message: `${t('Please Enter EPF')}` }]}
              >
                <Input placeholder={t('Enter EPF')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="etf"
                label={`${t('ETF Company Contribution')}`}
                rules={[{ required: true, message: `${t('Please Enter ETF')}` }]}
              >
                <Input placeholder={t('Enter ETF')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="epfCompanyContribution"
                label={`${t('EPF Company Contribution')}`}
                rules={[{ required: true, message: `${t('Please Enter EPF')}` }]}
              >
                <Input placeholder={t('Enter EPF')} />
              </Form.Item>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
      <Col span={24}>
        <Row style={{ float: 'right' }}>
          <Button htmlType="submit" className="inventory-button goods-received-button">Save</Button>
        </Row>
      </Col>
    </Form>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
  };
}

export default connect(mapStateToProps, {})(EditEmployee);
