import React from 'react';
import Lottie from 'react-lottie';
import * as noData from '../animations/noData.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: noData.default,
};

export default function NoData() {
  return (
    <div style={{ marginTop: '6rem' }}>
      <Lottie options={defaultOptions} height={300} width={300} />
    </div>
  );
}
