export const getItems = `
query getItems($workShopId: String!, $limit: Int!, $nextToken: String) {
  getItems(workShopId: $workShopId, limit: $limit, nextToken: $nextToken) {
    inventory {
      anticipatedUsage
      description
      warrantyDetails
      workShopId
      warehouseType
      sortKey
      supplierInformation
      searching
      quantityType
      partNumber
      partName
      minOrderQuantityToSupplier
      otherNames
      itemType
      goodsOut
      image
      goodsIn
      binLocation
    }
    nextToken
  }
}
`;

export const addUpdateInventoryItemMutation = `
mutation addUpdateInventoryItem($inventoryItem: InventoryItem) {
    addUpdateInventoryItem(newItem: $inventoryItem) {
      anticipatedUsage
      description
      warrantyDetails
      workShopId
      warehouseType
      sortKey
      supplierInformation
      searching
      quantityType
      partNumber
      partName
      minOrderQuantityToSupplier
      otherNames
      itemType
      goodsOut
      image
      goodsIn
      binLocation
    }
}
`;

export const searchItem = `
  query searchItem($workShopId: String!, $searching: String!) {
    searchItem(workShopId: $workShopId, searching: $searching) {
      anticipatedUsage
      description
      warrantyDetails
      workShopId
      warehouseType
      sortKey
      supplierInformation
      searching
      quantityType
      partNumber
      partName
      minOrderQuantityToSupplier
      otherNames
      itemType
      goodsOut
      image
      goodsIn
      binLocation
    }
  }
`;
