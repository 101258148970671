import {
  message, Select, Switch, Tag,
} from 'antd';
import React from 'react';
import { USER_ROLES } from '../../../../../enum/User';
import { JOB_STATUS } from '../../../../../enum/JobStatus';
import { sharedOnCell } from '../common';
import { updateJob, updateQCComments } from '../../actions';
import { hasRole } from '../../../../../utils/commonUtil';

const { Option } = Select;
const qcComments: string[] = [
  'Abnormal sound new',
  'Customer issue not fixed',
  'fixing issue',
  'Paint colour wrong',
  'Paint quality poor',
  'Panels not aligning',
  'Surface not proper',
  'Lights not working',
  'Error light on',
  'Not cooling',
  'Does not work',
];

const QCColumn = (
  t: Function,
  userRoles: any[],
  getJobs: Function,
) => {
  const qcCommentSelected = (e: any, job: any) => {
    message.destroy();
    message.loading('Updating the QC comments').then();
    updateQCComments(job.bookingId, job.sortKey, e).then((res: any) => {
      getJobs();
      if (res) {
        message.destroy();
        message.success('QC Comment updated successfully').then();
      }
    }).catch(() => {
      message.destroy();
      message.error('Something went wrong when updating the QC comment').then();
    });
  };

  const qcStatus = async (e: any, job: any) => {
    const jobStatus: JOB_STATUS = e ? JOB_STATUS.PENDING_PAYMENT : JOB_STATUS.QC;
    if (e && (job.qcComment || job.qcComment !== '')) {
      await updateQCComments(job.bookingId, job.sortKey, '');
    }
    await updateJob(job.bookingId, job.sortKey, jobStatus);
    getJobs();
  };

  return [
    {
      title: `${t('Description')}`,
      dataIndex: 'description',
      key: 'description',
      width: '180px',
      render: (title, item) => ({
        props: {
          style: item.title && {
            background: '#8782FE80',
            fontWeight: 'bold',
            borderRadius: '4px',
          },
          colSpan: item.title ? 7 : 1,
          rowSpan: 1,
        },
        children: <div>{item.description || item.title}</div>,
      }),
    },
    {
      title: `${t('Assignee')}`,
      dataIndex: 'assigneeName',
      key: 'assigneeName',
      width: '160px',
      align: 'left' as const,
      render: sharedOnCell,
    },
    {
      title: `${t('Reason')}`,
      dataIndex: 'qcComment',
      key: 'qcComment',
      align: 'left' as const,
      render: (status: JOB_STATUS, job) => ({
        props: {
          colSpan: job.title || status === JOB_STATUS.REJECTED ? 0 : 1,
        },
        children: (
          job.status === JOB_STATUS.REJECTED ? <div />
            : (
              <Select
                placeholder={t('Select reason')}
                onSelect={(e: any) => qcCommentSelected(e, job)}
                disabled={job.status === JOB_STATUS.PENDING_PAYMENT
                      || hasRole([USER_ROLES.TECHNICIAN, USER_ROLES.RECEPTIONIST], userRoles)}
                value={job.qcComment}
                style={{ width: '180px' }}
              >
                {job.status === JOB_STATUS.PENDING_PAYMENT
                    && <Option value={job.qcComment}>{job.qcComment}</Option>}
                {qcComments.map((option: string) => <Option value={option}>{option}</Option>)}
              </Select>
            )
        ),
      }),
    },
    {
      title: `${t('QC status')}`,
      dataIndex: 'switch',
      key: 'switch',
      width: '140px',
      align: 'right' as const,
      render: (status: JOB_STATUS, job) => ({
        props: {
          colSpan: job.title ? 0 : 1,
        },
        children: (
          job.status === JOB_STATUS.REJECTED
            ? (
              <Tag
                style={{
                  textAlign: 'center',
                  alignSelf: 'center',
                  borderRadius: '8px',
                  fontSize: 14,
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginLeft: 13,
                  marginRight: 13,
                }}
                color="red"
              >
                {t('Rejected')}
              </Tag>
            )
            : (
              <Switch
                checkedChildren={t('Pass')}
                unCheckedChildren={t('Fail')}
                defaultChecked={job.status === JOB_STATUS.PENDING_PAYMENT}
                onChange={(e: boolean) => qcStatus(e, job)}
                disabled={hasRole([USER_ROLES.RECEPTIONIST, USER_ROLES.TECHNICIAN], userRoles)}
              />
            )
        ),
      }),
    },
  ];
};

export default QCColumn;
