import React from 'react';
import moment from 'moment';
import { Button, Popover } from 'antd';
import { USER_ROLES } from '../../../../enum/User';
import { BOOKING_TYPES } from '../../../../enum/BookingStatus';
import { hasRole } from '../../../../utils/commonUtil';

const awaitingApprovalColumn = (t: Function, triggerModal: Function, userRoles: any, tabStatus) => [
  {
    title: `${t('Plate number')}`,
    dataIndex: 'plateNumber',
    key: 'plateNumber',
    width: '200px',
    align: 'left' as const,
  },
  {
    title: `${t('Owner name')}`,
    dataIndex: 'ownerName',
    key: 'ownerName',
    width: '300px',
    align: 'left' as const,
  },
  {
    title: `${t('Date')}`,
    dataIndex: 'selectedDate',
    key: 'selectedDate',
    width: '200px',
    className: 'price-colomn',
    align: 'left' as const,
    render: (value: string) => {
      if (value) {
        return moment(value).format('YYYY-MM-DD');
      }
      return '-';
    },
  },
  {
    title: `${t('Total amount')}`,
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    width: '200px',
    align: 'left' as const,
  },
  {
    title: `${t('Job type')}`,
    dataIndex: 'jobType',
    key: 'jobType',
    width: '200px',
    align: 'left' as const,
  },
  {
    title: `${t('Action')}`,
    dataIndex: 'action',
    width: '200px',
    key: 'action',
    render: (action: any, item: any) => (
      <>
        <Popover placement="topLeft" content="View Item" trigger="hover">
          <Button
            type="text"
            disabled={tabStatus === BOOKING_TYPES.AWAITING_APPROVAL
              && hasRole([USER_ROLES.SERVICE_ADVISOR, USER_ROLES.TECHNICIAN], userRoles)}
            onClick={() => triggerModal(item)}
          >
            <u style={{ color: '#131A54', fontWeight: 'bold' }}>View</u>
          </Button>
        </Popover>
      </>
    ),
  },
];

export default awaitingApprovalColumn;
