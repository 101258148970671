import React from 'react';
import {
  Button, Form, Row, Col,
  Typography, Upload,
} from 'antd';
import { useTranslation } from 'react-i18next';

type propType = {
  form: any,
  onSubmitQualifications: any,
  fileList: any,
  onPdfChange: any,
  dummyRequest: any,
  prev: any,
}

const QualificationsForm = ({
  form, onSubmitQualifications, fileList, onPdfChange, dummyRequest, prev,
}: propType) => {
  const { Title } = Typography;
  const { t } = useTranslation();
  return (
    <Form
      form={form}
      layout="vertical"
      hideRequiredMark
      size="middle"
      onFinish={onSubmitQualifications}
    >
      <Title level={3} className="step-title">{t('Qualifications')}</Title>
      <Row className="col-odd">
        <Col span={16}>
          <Title level={5} className="step-col-title">{t('G.C.E. O/L Certificate  [NVQ4]')}</Title>
        </Col>
        <Col span={8}>
          <Upload disabled />
          <Upload
            listType="text"
            fileList={fileList}
            onChange={onPdfChange}
            customRequest={dummyRequest}
            className="file-upload"
          >
            <Button type="text" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Upload>
        </Col>
      </Row>
      <Row className="col-even">
        <Col span={16}>
          <Title level={5} className="step-col-title">{t('G.C.E A/L Certificate  [NVQ5]')}</Title>
        </Col>
        <Col span={8}>
          <Upload disabled />
          <Upload
            listType="text"
            fileList={fileList}
            onChange={onPdfChange}
            customRequest={dummyRequest}
            className="file-upload"
          >
            <Button type="text" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Upload>
        </Col>
      </Row>
      <Row className="col-odd">
        <Col span={16}>
          <Title level={5} className="step-col-title">{t('NDT/HNDE/Diploma in Automobile Engineering  [NVQ 6]')}</Title>
        </Col>
        <Col span={8}>
          <Upload className="file-upload">
            <Button type="text" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Upload>
        </Col>
      </Row>
      <Row className="col-even">
        <Col span={16}>
          <Title level={5} className="step-col-title">{t('BSc (Engineering)/BEng Automobile [NVQ 7]')}</Title>
        </Col>
        <Col span={8}>
          <Upload className="file-upload">
            <Button type="text" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Upload>
        </Col>
      </Row>
      <Row className="col-odd">
        <Col span={16}>
          <Title level={5} className="step-col-title">{t('Msc Engineering/ MEng Automobile')}</Title>
        </Col>
        <Col span={8}>
          <Upload className="file-upload">
            <Button type="text" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Upload>
        </Col>
      </Row>
      <Row className="col-even">
        <Col span={16}>
          <Title level={5} className="step-col-title">{t('Other Professional  Registrations')}</Title>
        </Col>
        <Col span={8}>
          <Upload className="file-upload">
            <Button type="text" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Upload>
        </Col>
      </Row>
      <Row className="col-odd">
        <Col span={16}>
          <Title level={5} className="step-col-title">{t('Incorporated Engineer Registration')}</Title>
        </Col>
        <Col span={8}>
          <Upload className="file-upload">
            <Button type="text" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Upload>
        </Col>
      </Row>
      <Row className="col-even">
        <Col span={16}>
          <Title level={5} className="step-col-title">{t('Charted Engineer Registration')}</Title>
        </Col>
        <Col span={8}>
          <Upload className="file-upload">
            <Button type="text" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Upload>
        </Col>
      </Row>
      <Col className="line-break" span={24}>
        {null}
      </Col>
      <Row>
        <Col span={12}>
          <Row>
            <Button className="employee-button add-employee-button" onClick={(value) => prev(value)}>{t('Back')}</Button>
          </Row>
        </Col>
        <Col span={12}>
          <Row style={{ float: 'right' }}>
            <Button htmlType="submit" className="employee-button add-employee-button">{t('Next')}</Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default QualificationsForm;
