/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow,import/prefer-default-export
export enum JOB_STATUS {
  START = 'Start',
  PAUSED = 'Paused',
  DONE = 'Done',
  REJECTED = 'Rejected',
  QC = 'Quality Control',
  PENDING_PAYMENT = 'Pending Payment',
  QC_PASS = 'Qc Pass',
  QC_FAIL = 'QC Fail'
}
