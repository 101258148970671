/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button, Col, Input, message, Popconfirm, Popover, Row, Table, Typography, Upload,
} from 'antd';
import {
  DownloadOutlined, FileExcelOutlined, PlusOutlined, SearchOutlined, UploadOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  deleteInventory, getAllInventory, getSignedUrl, searchInventory,
} from './actions';
import AddEditItem from './components/AddEditItem';
import './inventory.scss';
import Loading from '../../../Loading';
import ViewItem from './components/ViewItem';
import csvValidationHandle from '../../../utils/csvValidation';
import { ACTION_TYPE, CSV_TYPES, S3_METHODS } from '../../../enum/ConfigurationData';
import { hasRole } from '../../../utils/commonUtil';
import { USER_ROLES } from '../../../enum/User';

type PropType = {
  workShopId: string
  userRoles: any[]
}

export const InventoryPage = ({ workShopId, userRoles }: PropType) => {
  const { Title } = Typography;
  const [selectedType, setSelectedType] = useState<ACTION_TYPE>(ACTION_TYPE.VIEW);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [data, setData] = useState<any>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState(true);
  const [fileList, setFileList] = useState<any[]>([]);
  const [signedUrl, setSignedUrl] = useState<any>();
  const [enableUploadButton, setEnableUploadButton] = useState<boolean>(false);
  const [csvValid, setCsvValid] = useState<boolean>(false);
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const { t } = useTranslation();

  const getAllInventoryData = async (isFetch: boolean = false) => {
    let limit = 20;
    let nextToken: any = null;
    if (pageNumber > 1 && !isFetch) {
      limit = 10;
      nextToken = data.nextToken;
    }
    await getAllInventory(workShopId, limit, nextToken).then((res: any) => {
      const newData = (res && res?.data?.getItems) || [];
      const newTableData = newData.inventory;
      nextToken = newData.nextToken || null;
      setTableData((prevState: any) => {
        if (isFetch) {
          return newTableData;
        }
        return [...prevState, ...newTableData];
      });
      setData(newData);
      setLoading(false);
      if (newTableData.length < 10 || !nextToken) {
        setHasMore(false);
      }
      if (nextToken) {
        setPageNumber(pageNumber + 1);
      }
    }).catch((err: any) => {
      message.error('Something went wrong');
      console.error(err);
    });
  };

  const fetchData = () => {
    if (workShopId) {
      setHasMore(true);
      setData(undefined);
      setTableData([]);
      setPageNumber(1);
      setRefresh(true);
    }
  };

  const onSearch = (e) => {
    let keyword = e.target.value;
    keyword = keyword.toLowerCase();
    if (keyword === '') {
      fetchData();
    } else {
      searchInventory(workShopId, keyword).then((res: any) => {
        setTableData(res.data.searchItem);
      }).catch((err: any) => {
        console.error(err);
      });
    }
  };

  const handleChange = (info) => {
    setEnableUploadButton(false);
    let newFileList: any[] = [...info.fileList];
    if (csvValid) {
      newFileList = newFileList.slice(-1);
      if (newFileList.length && newFileList[0].status === 'done') {
        const selectedFile = newFileList[0].originFileObj;
        const key = `${workShopId}/${workShopId}.csv`;
        const contentType = selectedFile.type;
        getSignedUrl(key, S3_METHODS.put, contentType).then((res) => {
          setSignedUrl(res.url);
          setEnableUploadButton(true);
          message.destroy();
        }).catch((err) => {
          message.destroy();
          message.error(err.message);
        });
      }
      newFileList = newFileList.map((file: any) => {
        const obj = file;
        if (obj.response) {
          obj.url = file.response.url;
        }
        return obj;
      });
      setFileList(newFileList);
    }
  };

  const uploadToS3 = async () => {
    if (signedUrl && fileList.length && fileList[0]) {
      setEnableUploadButton(false);
      message.loading('Uploading inventory CSV', 0);
      const selectedFile = fileList[0].originFileObj;
      fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': selectedFile.type,
        },
        body: selectedFile,
      })
        .then((res) => res.text())
        .then(() => {
          setTimeout(() => {
            fetchData();
            setEnableUploadButton(true);
            setFileList([]);
            setSignedUrl(undefined);
            message.destroy();
            message.success(`${selectedFile.name} uploaded successfully`);
          }, 2000);
        }).catch((e: any) => {
          console.error(e);
          message.error('Something went wrong');
        });
    }
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const tablePagination = (e) => {
    if (e + 1 >= pageNumber && hasMore) {
      getAllInventoryData().finally();
    }
  };

  const csvBeforeUpload = async (e) => {
    message.loading('Loading CSV', 0);
    setEnableUploadButton(false);
    const flag: any = await csvValidationHandle(e, CSV_TYPES.INVENTORY);
    setCsvValid(flag);
  };

  const deleteSelectedItem = (item: any) => {
    message.destroy();
    message.loading('Deleting inventory item', 0);
    deleteInventory(item.workShopId, item.sortKey).then((res: any) => {
      if (res) {
        message.destroy();
        message.success('Inventory item deleted successfully');
      } else {
        message.destroy();
        message.error('Something went wrong');
      }
      const newTableData = tableData.filter((row: any) => row.sortKey !== item.sortKey);
      setTableData(newTableData);
    }).catch((err) => {
      console.error(err);
      message.error('Something went wrong');
    });
  };

  const triggerModal = (action: ACTION_TYPE, item: any = undefined) => {
    setSelectedItem(item);
    setSelectedType(action);
    setModalVisible(true);
  };

  const columnsData = [
    {
      title: `${t('Part Number')}`,
      dataIndex: 'partNumber',
      width: '50px',
    },
    {
      title: `${t('Part Name')}`,
      dataIndex: 'partName',
      width: '60px',
    },
    {
      title: `${t('Other Names')}`,
      dataIndex: 'otherNames',
      width: '60px',
    },
    {
      title: `${t('Description / Details')}`,
      dataIndex: 'description',
      width: '100px',
    },
    {
      title: `${t('Warehouse Type')}`,
      dataIndex: 'warehouseType',
      width: '70px',
    },
    {
      title: `${t('Bin Location')}`,
      dataIndex: 'binLocation',
      width: '50px',
    },
    {
      title: `${t('Anticipated Usage')}`,
      dataIndex: 'anticipatedUsage',
      width: '70px',
    },
    {
      title: `${t('Action')}`,
      dataIndex: 'action',
      width: '50px',
      render: (action: any, item: any) => (
        <>
          <Popover placement="topLeft" content="View Item" trigger="hover">
            <Button
              type="text"
              icon={<img src="/assets/view.png" alt="View Item" />}
              onClick={() => triggerModal(ACTION_TYPE.VIEW, item)}
            />
          </Popover>
          {hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN], userRoles) && (
          <>
            <Popover placement="topLeft" content="Edit Item" trigger="hover">
              <Button
                type="text"
                icon={<img src="/assets/edit.png" alt="Edit Item" />}
                onClick={() => triggerModal(ACTION_TYPE.EDIT, item)}
              />
            </Popover>
            <Popover placement="topLeft" content="Delete Item" trigger="hover">
              <Popconfirm
                title="Are you sure you want to delete this item?"
                onConfirm={() => deleteSelectedItem(item)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="text"
                  icon={<img src="/assets/trash.png" alt="trash" />}
                  style={{ width: 10 }}
                />
              </Popconfirm>
            </Popover>
          </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (isRefresh) {
      getAllInventoryData(isRefresh).finally(() => {
        setRefresh(false);
      });
    }
  }, [isRefresh]);

  useEffect(() => {
    fetchData();
  }, [workShopId]);

  return (
    <>
      <Row>
        <Col>
          <Title level={3} style={{ color: '#8C8C8C' }}>{t('Inventory')}</Title>
        </Col>
      </Row>
      <Row style={{ marginBottom: '20px' }} className="button-row">
        <Col xs={6} sm={6} md={3} lg={8} xl={8} style={{ paddingLeft: '20px' }} />
        <Col xs={3} sm={3} md={4} lg={3} xl={3} style={{ paddingLeft: '15px' }}>
          <Button
            block
            onClick={() => triggerModal(ACTION_TYPE.ADD)}
            className="inventory-button add-item-button"
            disabled={!hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN], userRoles)}
            icon={<PlusOutlined />}
          >
            {t('Add Item')}
          </Button>
        </Col>
        <Col xs={3} sm={3} md={5} lg={4} xl={4} style={{ paddingLeft: '15px' }}>
          <Link to="/templates/karstation-data-format-inventory.csv" target="_blank" download>
            <Button
              className="ant-btn-edit"
              style={{ width: 190 }}
              icon={<DownloadOutlined />}
              disabled={!hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN], userRoles)}
            >
              {' '}
              {t('Download Template')}
              {' '}
            </Button>
          </Link>
        </Col>
        <Col xs={3} sm={3} md={4} lg={3} xl={3} style={{ paddingLeft: '10px' }}>
          {!!fileList.length && (
          <Button
            className="ant-btn-edit"
            icon={<UploadOutlined />}
            disabled={!enableUploadButton
                || !hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN], userRoles)}
            onClick={uploadToS3}
            style={{ width: 150 }}
          >
            {t('upload')}
          </Button>
          )}
          <Upload
            beforeUpload={csvBeforeUpload}
            onChange={handleChange}
            multiple
            fileList={fileList}
            disabled={!hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN], userRoles)}
            accept=".csv"
            customRequest={dummyRequest}
          >
            {!fileList.length && (
              <Button
                className="ant-btn-edit"
                icon={<FileExcelOutlined />}
                style={{ width: 150 }}
                disabled={!hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN], userRoles)}
              >
                {t('Select File')}
              </Button>
            )}
          </Upload>
        </Col>
        <Col md={6} span={6} style={{ paddingLeft: '15px' }}>
          <Input onChange={onSearch} addonBefore={<SearchOutlined />} placeholder="Search" />
        </Col>
      </Row>
      {loading ? <Loading />
        : (
          <Row className="table-row">
            <Table
              columns={columnsData}
              dataSource={tableData}
              scroll={{ y: 600 }}
              pagination={{ onChange: tablePagination }}
            />
          </Row>
        )}
      {selectedType === ACTION_TYPE.VIEW ? (
        <ViewItem
          inventoryItem={selectedItem}
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
        />
      ) : (
        <AddEditItem
          workShopId={workShopId}
          fetchData={fetchData}
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          inventoryItem={selectedType === ACTION_TYPE.ADD ? undefined : selectedItem}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    userRoles: state.userData.userRoles,
  };
}

export default connect(mapStateToProps, {})(InventoryPage);
