import { timeConvert } from '../views/booking/viewJobs/viewJob/common';

export const sortJobs = (array: any) => array.sort((a, b) => {
  const nameA = a.repairItem?.toUpperCase(); // ignore upper and lowercase
  const nameB = b.repairItem?.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
});

export const formatNumber = (value, currency, languageCode) => new Intl.NumberFormat(languageCode, {
  style: 'currency',
  currency,
}).format(value);

export const getFormattedJobs = (jobList: any[], currency: string, languageCode: string) => {
  const tempArray: any[] = [];
  let itemType = '';
  const jobs = sortJobs(jobList);

  jobs.forEach((jItem: any) => {
    if (itemType !== jItem.repairItem) {
      itemType = jItem.repairItem;
      const repairItems = jobs.filter((obj: any) => obj.repairItem === itemType);
      const obj: any = { title: itemType };
      if (repairItems.length < 2) {
        obj.status = jItem.status;
      }
      if (itemType) tempArray.push(obj);
    }
    tempArray.push(jItem);
  });
  return tempArray.map((val: any) => {
    const obj = JSON.parse(JSON.stringify(val));
    if (val.rate && !Number.isNaN(val.rate)) {
      obj.rate = formatNumber(val.rate, currency, languageCode);
    }
    if (val.price && !Number.isNaN(val.price)) {
      obj.price = formatNumber(val.price, currency, languageCode);
    }
    if (val.qtyHours && !Number.isNaN(val.qtyHours)) {
      obj.qtyHours = timeConvert(val.qtyHours);
    }
    return obj;
  });
};
