import { USER_ROLES } from '../enum/User';

export const onPreview = async (file) => {
  let src: any = file.url;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow: any = window.open(src);
  imgWindow.document.write(image.outerHTML);
};

export const onPreviewFile = async (file: any) => {
  window.open(file.url);
};

export const dummyRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

export const detectURLs = (message: string) => {
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return message.match(urlRegex);
};

export const getSortedObject = (array: any[], key: string) => array.sort((a, b) => a[key] - b[key]);

export const hasRole = (roles: USER_ROLES[], userRoles: any[]) => roles
  .some((role: USER_ROLES) => userRoles.includes(role));
