export const getAllCredit = `
query getAllCredits($workShopId: String!, $status: String!, $from: String!, $to: String!) {
  getAllCredits(workShopId: $workShopId, status: $status, from: $from, to: $to) {
    batteryReplacementDate
    chassisNumber
    colourCode
    comment
    engineNumber
    fuelType
    insuranceNumber
    insuranceCompany
    insuranceRenevalDate
    jobType
    licenseNumber
    licenseRenevalDate
    make
    manufactureYear
    model
    ownerEmail
    totalPrice
    total
    ownerName
    ownerTel
    plateNumber
    selectedDate
    selectedTime
    sortKey
    status
    tireSize
    userEmail
    userName
    userTel
    images
    bookingStdHrsMin
    workShopId
    updatedTime
    createdTime
    assigneeName
    assigneeId
    registerYear
    mileage
    creditAttachment
  }
}
`;

export default getAllCredit;
