import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { S3_METHODS } from '../../../enum/ConfigurationData';
import { getAllBookingHistory, getAllBookingHistoryUnauth, getJobs } from '../../../graphql-queries/bookingHistory';
import { getApi, APIS } from '../../../utils/apiUtils';

export const getHistoryBookings = async (workShopId: any, userId, date1, date2) => {
  try {
    return await API.graphql({
      query: userId ? getAllBookingHistory : getAllBookingHistoryUnauth,
      variables: {
        workShopId: `${workShopId}`, date1: `${date1}`, date2: `${date2}`,
      },
      authMode: userId ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
    });
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getJobList = async (workShopId, bookingId) => {
  try {
    return await API.graphql(graphqlOperation(getJobs, { workShopId: `${workShopId}`, bookingId: `${bookingId}` }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getBookingContent = async (key) => {
  try {
    return await getApi(APIS.BOOKING_API, `signed-url?key=${key}&method=${S3_METHODS.get}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};
