import React from 'react';
import {
  Button, Form, Row, Col,
  Input, Select, DatePicker,
  Radio, Typography,
} from 'antd';
import { USER_ROLES } from '../../../../enum/User';

type PropType = {
  form: any,
  onSubmitGeneral: any,
  t: any
};

const GeneralForm = ({ form, onSubmitGeneral, t }: PropType) => {
  const { Title } = Typography;

  return (
    <Form
      form={form}
      layout="vertical"
      hideRequiredMark
      size="middle"
      onFinish={onSubmitGeneral}
    >
      <Title level={3} className="step-title">
        {t('General')}
      </Title>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="name"
            label={t('Employee Name')}
            rules={[{ required: true, message: `${t('Please Enter Employee Name')}` },
              { pattern: new RegExp(/^[a-zA-Z ]*$/), message: 'Input should contain only letters' }]}
          >
            <Input placeholder={t('Enter Employee Name')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="userRole"
            label={`${t('Employee Role')}`}
            rules={[{ required: true, message: `${t('Please Select Employee Role')}` }]}
          >
            <Select defaultValue={t('Select Employee Role')}>
              <Select.Option value={USER_ROLES.ADMIN}>Admin</Select.Option>
              <Select.Option value={USER_ROLES.RECEPTIONIST}>Receptionist</Select.Option>
              <Select.Option value={USER_ROLES.TECHNICIAN}>Technician</Select.Option>
              <Select.Option value={USER_ROLES.SERVICE_ADVISOR}>
                Service Advisor
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={`${t('NIC Number')}`}
            name="nic"
            rules={[
              { required: true, message: `${t('Please Enter Employee NIC Number')}` },
            ]}
          >
            <Input placeholder={t('Enter NIC Number')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Row>
            <Col span={24}>
              <Form.Item label={`${t('Date of Birth (Optional)')}`} name="dob">
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder={t('Select Date of Birth')}
                  format="YYYY/MM/DD"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={`${t('Contact Number')}`}
                name="contactNumber"
                rules={[
                  {
                    required: true,
                    message: `${t('Please Enter Employee Contact Number')}`,
                  },
                  {
                    pattern: /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g,
                    message: 'Invalid Contact number',
                  },
                ]}
              >
                <Input placeholder={t('Enter Contact Number')} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Form.Item label={`${t('Address (Optional)')}`} name="address">
            <Input.TextArea
              placeholder={t('Enter Employee Address')}
              showCount
              maxLength={100}
              rows={5}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="userName"
            label={`${t('Username')}`}
            rules={[{ required: true, message: `${t('Please Enter User Name')}` }]}
          >
            <Input placeholder={t('Enter User Name')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="email"
            label={`${t('Email')}`}
            rules={[{ required: true, message: `${t('Please Enter Email')}` },
              { pattern: new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/), message: 'Invalid Email Address' },
            ]}
          >
            <Input placeholder={t('Enter Email')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="status"
            label={`${t('Status')}`}
            rules={[{ required: true, message: `${t('Please Select Status')}` }]}
          >
            <Radio.Group optionType="button">
              <Radio value="Active">{t('Active')}</Radio>
              <Radio value="Inactive">{t('Inactive')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12} />
        <Col span={12}>
          <Row style={{ float: 'right' }}>
            <Button
              htmlType="submit"
              className="employee-button add-employee-button"
            >
              {t('Next')}
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
export default GeneralForm;
