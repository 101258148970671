import React, { useState, useEffect } from 'react';
import {
  Card, Row, Col, Typography,
} from 'antd';
import './globalPartsSearch.scss';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// import getConfigData from './actions';
import Loading from '../../Loading';

export const GlobalPartsSearch = ({ configData }:any) => {
  const { Title } = Typography;
  const [makeData, setMakeData] = useState([]);
  const [siteVisible, setSiteVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (configData) {
      setMakeData(configData?.globalParts);
      setLoading(false);
    }
  }, [configData]);

  const handleClick = (item) => {
    setSiteVisible(true);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setSiteVisible(false);
    setSelectedItem(null);
  };

  const GlobalPartsSearchSite = () => (
    <>
      <Row>
        <Col span={20}>
          <Title level={3} style={{ color: '#8C8C8C' }}>{selectedItem?.value}</Title>
        </Col>
        <Col className="close-class" span={4}>
          <CloseOutlined className="close-btn" onClick={() => handleClose()} />
        </Col>
      </Row>
      <Col span={24}>
        <div style={{ height: 600 }}>
          <iframe title={selectedItem?.value} src={selectedItem?.label} height="100%" width="100%" />
        </div>
      </Col>
    </>
  );

  const GlobalPartsSearchIcons = () => (
    <>
      <Row style={{ paddingBottom: 15 }}>
        <Col span={24}>
          <Title level={3} style={{ color: '#8C8C8C' }}>{t('Global Parts Search')}</Title>
        </Col>
      </Row>
      {loading
        ? <Loading />
        : (
          <Row>
            {makeData && makeData.map((row: any) => (
              <Col
                onClick={() => handleClick(row)}
                key={row?.value}
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={6}
                style={{ paddingBottom: 20, paddingLeft: 20 }}
              >
                <Card>
                  <Row>
                    <Col className="logo-class" span={24}>
                      <img className="make-logo" src={`./globalSearch/${row?.value}.svg`} alt={row?.label} />
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <Title className="make-title" level={4}>{row?.value}</Title>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        )}
    </>
  );

  return (
    <>
      {siteVisible
        ? <GlobalPartsSearchSite />
        : <GlobalPartsSearchIcons />}
    </>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    configData: state.userData.configData,
  };
}

export default connect(mapStateToProps, {})(GlobalPartsSearch);
