/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Drawer,
  Row,
  Tag,
  Typography,
  Tabs,
  Table,
  Upload,
} from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PRIORITY } from './TaskboardTypes';
import { getBookingContent, getJobList } from '../action';
import './taskboard.scss';
import { getImageUrl } from '../../investigation/action';

import { JOB_STATUS } from '../../../../enum/JobStatus';

export const ViewTask = (props: any) => {
  const { Title } = Typography;
  const {
    item,
    workShopId,
    configData,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [colour, setColour] = useState('orange');
  const [jobList, setJobList]: any[] = useState([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [qcFileList, setQCFileList] = useState<any[]>([]);
  const [formattedJobList, setFormattedJobList] = useState([]);
  const [currency, setCurrency] = useState<any>();
  const { t } = useTranslation();

  const bookingDetails = [
    {
      title: `${t('Job Type')}`,
      value: item?.jobType,
    },
    {
      title: `${t('date')}`,
      value: moment(item?.selectedDate).format('MMMM Do YYYY'),
    },
    {
      title: `${t('time')}`,
      value: moment(item?.selectedDate).format('h:mm a'),
    },
    {
      title: `${t('Assignee')}`,
      value: item?.assigneeName,
    },
  ];

  const vehicleDetails = [
    {
      title: `${t('Vehicle Registration Number')}`,
      value: item?.plateNumber,
    },
    {
      title: `${t('Vehicle Make')}`,
      value: item?.make,
    },
    {
      title: `${t('Vehicle Model')}`,
      value: item?.model,
    },
    {
      title: `${t('Mileage')}`,
      value: item?.mileage,
    },
    {
      title: `${t('Manufacture Year')}`,
      value: item?.manufactureYear,
    },
    {
      title: `${t('Year of Register')}`,
      value: item?.registerYear,
    },
    {
      title: `${t('Engine Number')}`,
      value: item?.engineNumber,
    },
    {
      title: `${t('Chassis Number')}`,
      value: item?.chassisNumber,
    },
    {
      title: `${t('Color Code')}`,
      value: item?.colourCode,
    },
    {
      title: `${t('Tyre Size')}`,
      value: item?.tireSize,
    },
    {
      title: `${t('Fuel Type')}`,
      value: item?.fuelType,
    },
    {
      title: `${t('Insurance Company')}`,
      value: item?.insuranceCompany,
    },
    {
      title: `${t('Insurance Expiry Date')}`,
      value: moment(item?.insuranceRenevalDate).format('Do MMMM YYYY'),
    },
    {
      title: `${t('Battery Replacement Date')}`,
      value: moment(item?.batteryReplacementDate).format('Do MMMM YYYY'),
    },
  ];

  const ownerDetails = [
    {
      title: `${t('Name')}`,
      value: item?.ownerName,
    },
    {
      title: `${t('Contact Number')}`,
      value: item?.ownerTel,
    },
    {
      title: `${t('Email')}`,
      value: item?.ownerEmail,
    },
  ];

  const userDetails = [
    {
      title: `${t('Name')}`,
      value: item?.userName,
    },
    {
      title: `${t('Contact Number')}`,
      value: item?.userTel,
    },
    {
      title: `${t('Email')}`,
      value: item?.userEmail,
    },
  ];

  // @ts-ignore

  const columns = [
    {
      title: `${t('Description')}`,
      dataIndex: 'description',
      key: 'description',
      width: '140px',
      render: (title, dataItem) => ({
        props: {
          style: dataItem.title && {
            background: 'rgba(135,130,254,0.25)',
            fontWeight: 'bold',
            borderRadius: '4px',
          },
          colSpan: dataItem.title ? 7 : 1,
          rowSpan: 1,
        },
        children: <div>{ dataItem.description || dataItem.title }</div>,
      }),
    },
    {
      title: `${t('Standard Time')}`,
      dataIndex: 'qtyHours',
      key: 'qtyHours',
      width: '80px',
      align: 'left' as const,
      render: (status: JOB_STATUS, dataItem) => ({
        props: {
          colSpan: dataItem.title ? 0 : 1,
        },
        children: (
          dataItem.status === JOB_STATUS.REJECTED ? null : (
            <div>{ dataItem.qtyHours }</div>
          )
        ),
      }),
    },
    {
      title: `${t('Qty')}`,
      dataIndex: 'qtyNo',
      key: 'qtyNo',
      width: '30px',
      align: 'left' as const,
      render: (status: JOB_STATUS, dataItem) => ({
        props: {
          colSpan: dataItem.title ? 0 : 1,
        },
        children: (
          dataItem.status === JOB_STATUS.REJECTED ? null : dataItem.qtyNo
        ),
      }),
    },
    {
      title: `${t('Rate')}`,
      dataIndex: 'rate',
      key: 'rate',
      width: '80px',
      align: 'left' as const,
      render: (status: JOB_STATUS, dataItem) => ({
        props: {
          colSpan: dataItem.title ? 0 : 1,
        },
        children: (
          dataItem.status === JOB_STATUS.REJECTED ? null : dataItem.rate
        ),
      }),
    },
    {
      title: `${t('Price')}`,
      dataIndex: 'price',
      key: 'price',
      width: '80px',
      align: 'left' as const,
      render: (status: JOB_STATUS, dataItem) => ({
        props: {
          colSpan: dataItem.title ? 0 : 1,
        },
        children: (
          dataItem.status === JOB_STATUS.REJECTED ? (
            <Tag
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                borderRadius: '8px',
                fontSize: 14,
                paddingLeft: 5,
                paddingRight: 5,
                marginLeft: 13,
                marginRight: 13,
              }}
              color="red"
            >
              Job Rejected by Customer
            </Tag>
          ) : dataItem.price
        ),
      }),
    },
  ];

  const getPriority = () => {
    let tempCol = colour;
    if (PRIORITY.REPAIR === item.jobType) {
      tempCol = '#2F80ED';
    } else if (PRIORITY.BREAK_DOWN === item.jobType) {
      tempCol = '#27AE60';
    } else if (PRIORITY.SERVICE === item.jobType) {
      tempCol = '#E2B93B';
    } else {
      tempCol = '#EC0F0F';
    }
    setColour(tempCol);
  };

  useEffect(() => {
    getPriority();
  }, []);

  const sortJobs = (array) => array.sort((a, b) => {
    const nameA = a.repairItem?.toUpperCase(); // ignore upper and lowercase
    const nameB = b.repairItem?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    if (configData) setCurrency(configData?.currency);
  }, [configData]);

  const roundDownToNearest5 = (num) => Math.floor(num / 5) * 5;

  const timeConvert = (num) => {
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = roundDownToNearest5(Math.round(minutes));
    if (rhours > 0) {
      if (rminutes > 0) return `${rhours} hr(s) ${rminutes} min(s)`;
      return `${rhours} hr(s)`;
    }
    return `${rminutes} min(s)`;
  };

  useEffect(() => {
    if (jobList) {
      const formattedtempArray = jobList.map((val) => {
        const obj = JSON.parse(JSON.stringify(val));
        // eslint-disable-next-line no-restricted-globals
        if (val.price && !isNaN(obj.price)) obj.price = new Intl.NumberFormat(currency.languageCode, { style: 'currency', currency: currency.currency }).format(val.price);
        // eslint radix: "error"
        if (val.qtyHours) obj.qtyHours = timeConvert(parseInt(val.qtyHours, 10));
        // eslint-disable-next-line no-restricted-globals
        if (val.rate && !isNaN(obj.rate)) obj.rate = new Intl.NumberFormat(currency.languageCode, { style: 'currency', currency: currency.currency }).format(val.rate);
        // eslint radix: "error"
        if (val.stdHrsMin) obj.stdHrsMin = timeConvert(parseInt(val.stdHrsMin, 10));
        return obj;
      });
      setFormattedJobList(formattedtempArray);
    }
  }, [jobList]);

  const getJobs = () => {
    getJobList(workShopId, item.sortKey).then((res: any) => {
      let jobsList = res.data?.getJobs;
      const tempArray: any[] = [];
      let itemType = '';
      jobsList = sortJobs(jobsList);
      jobsList.map((dataItem) => {
        if (itemType === dataItem.repairItem) {
          // @ts-ignore
          tempArray.push(dataItem);
        } else {
          itemType = dataItem.repairItem;
          if (itemType) tempArray.push({ title: itemType });
          tempArray.push(dataItem);
        }
      });
      setJobList(tempArray);
    });
  };

  const onClose = () => {
    setIsModalVisible(false);
  };

  const loadImages = () => {
    setFileList([]);
    if (item.images) {
      item.images.map((image: any, index: number) => {
        getImageUrl(image, 'image/jpeg', 'getObject').then((imageUrl) => {
          const imageUrlObj = {
            uid: index,
            name: image,
            status: 'done',
            url: imageUrl.url,
          };
          setFileList((prevData: any[]) => [...prevData, imageUrlObj]);
        });
      });
    }
  };

  const loadQCImages = () => {
    setQCFileList([]);
    if (item.qcImages) {
      item.qcImages.map((image: any, index: number) => {
        const key = `${workShopId}/${item.sortKey}/QC/${image}`;
        getBookingContent(key).then((imageUrl) => {
          const imageUrlObj = {
            uid: index,
            name: image,
            status: 'done',
            url: imageUrl.body.url,
          };
          setQCFileList((prevData: any[]) => [...prevData, imageUrlObj]);
        });
      });
    }
  };

  const onOpen = () => {
    setIsModalVisible(true);
    getPriority();
    getJobs();
    loadImages();
    loadQCImages();
  };

  return (
    <>
      <Button
        type="link"
        onClick={onOpen}
        style={{
          backgroundColor: '#131A54', color: 'white', borderRadius: '8px', height: 25, width: 60, paddingTop: 0,
        }}
      >
        View

      </Button>
      <Drawer
        title={(
          <>
            <Row>
              <Col span={11}>
                {`${t('Plate Number')} - ${item.plateNumber}`}
              </Col>
              <Col span={5} style={{ marginTop: -3 }}>
                <Tag
                  style={{
                    width: 100,
                    height: 30,
                    textAlign: 'center',
                    alignSelf: 'center',
                    borderRadius: '8px',
                    paddingTop: 5,
                    fontSize: 15,
                    fontWeight: 'lighter',
                  }}
                  color={colour}
                >
                  {item.jobType}
                </Tag>
              </Col>
            </Row>
          </>
        )}
        placement="right"
        width={window.innerWidth > 1001 ? 1000 : window.innerWidth - 2}
        onClose={onClose}
        visible={isModalVisible}
      >
        <Tabs defaultActiveKey="1" type="card" animated>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-title">{t('Booking Details')}</Title>
              </span>
            )}
            key="1"
          >
            <Row className="tab-row">
              {bookingDetails.map((row) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{row?.value}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
            {(fileList && fileList.length > 0) && (
            <Row className="tab-row">
              <h4>Images</h4>
              <br />
              <Upload
                listType="picture-card"
                fileList={fileList}
                disabled
              />
            </Row>
            )}
            {(qcFileList && qcFileList.length > 0) && (
            <Row className="tab-row">
              <h4>Job finalized images</h4>
              <br />
              <Upload
                listType="picture-card"
                fileList={qcFileList}
                disabled
              />
            </Row>
            )}
            <Row className="tab-row">
              <Col span={24}>
                <Table columns={columns} dataSource={formattedJobList} pagination={false} />
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-title">{t('Vehicle Details')}</Title>
              </span>
            )}
            key="2"
          >
            <Row className="tab-row">
              {vehicleDetails.map((row) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{row?.value}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-title">{t('Owner Details')}</Title>
              </span>
            )}
            key="3"
          >
            <Row className="tab-row">
              {ownerDetails.map((row) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{row?.value}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-title">{t('User Details')}</Title>
              </span>
            )}
            key="4"
          >
            <Row className="tab-row">
              {userDetails.map((row) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{row?.value}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    configData: state.userData.configData,
  };
}

export default connect(mapStateToProps, {})(ViewTask);
