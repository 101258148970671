/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React, { useEffect, useState } from 'react';
import {
  Button, Col, Drawer, Form, Input, InputNumber, message, Modal, Radio, Row, Select, Upload,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ulid } from 'ulid';
import '../inventory.scss';
import ImgCrop from 'antd-img-crop';
import type { RcFile } from 'antd/es/upload';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  addItems,
  deleteImage,
  getSignedUrl,
} from '../actions';
import { S3_METHODS } from '../../../../enum/ConfigurationData';
import { detectURLs } from '../../../../utils/commonUtil';

const GOODS_TYPE = {
  WEIGHT: 'Weight',
  LIQUID: 'Liquid',
  PIECES: 'Pieces',
};

type PropTypes = {
  workShopId: string,
  fetchData: Function,
  isModalVisible: boolean,
  setModalVisible: Function,
  inventoryItem: any
}

const getBase64 = (file: RcFile): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = (error) => reject(error);
});

const AddEditItem = ({
  workShopId, fetchData, isModalVisible, setModalVisible, inventoryItem,
}: PropTypes) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [goodsIn, setGoodsIn] = useState<string>('');
  const [goodsOut, setGoodsOut] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const { Option } = Select;

  const unitOptions = {
    Weight: ['kg', 'g', 'mg'],
    Liquid: ['l', 'ml'],
  };

  const onClose = () => {
    setModalVisible(false);
    setGoodsOut('');
    setFileList([]);
    form.resetFields();
  };

  const uploadImageListToS3 = async (sortKey: string, newItems: any[]) => {
    const images: any[] = [];
    newItems.forEach((image: any) => {
      const contentType = image.type;
      const fileName = `${ulid()}.${image.type.split('/').pop()}`;
      const key = `${workShopId}/${sortKey}/${fileName}`;
      images.push(fileName);

      if (image.originFileObj) {
        getSignedUrl(key, S3_METHODS.put, contentType).then((res) => {
          fetch(res.url, {
            method: 'PUT',
            headers: {
              'Content-Type': image.type,
            },
            body: image.originFileObj,
          })
            .then((ff) => ff.text());
        });
      }
    });
    return images;
  };

  const getGoodsValue = (goods: any) => {
    const obj: any = { item: goods.item };
    if (goods.item === GOODS_TYPE.PIECES) {
      obj.value = goods.pieces;
    } else {
      obj.unit = goods.unit;
      obj.value = goods.value;
    }
    return obj;
  };

  const deleteImages = async () => {
    const deletedImages: string[] = [];
    const dataArray: string[] = [];
    fileList.forEach((item: any) => dataArray.push(item.name));
    inventoryItem.image.forEach((image: string) => {
      if (!dataArray.includes(image)) {
        deletedImages.push(image);
      }
    });
    if (deletedImages.length) {
      message.loading('Removing old images', 0);
    }
    const deletePromises = deletedImages.map(async (image: string) => {
      const key = `${workShopId}/${inventoryItem.sortKey}/${image}`;
      return deleteImage(key);
    });
    await Promise.all(deletePromises);
    return deletedImages;
  };

  const updateImages = async (formData: any) => {
    let imageList: string[] = formData.image || [];
    const newItems: string[] = fileList.filter((item: any) => item.type);
    if (inventoryItem) {
      const deletedImages = await deleteImages();
      deletedImages.forEach((deletedImage: any) => {
        imageList = imageList.filter((imageItem: string) => imageItem !== deletedImage);
      });
    }
    if (newItems.length) {
      message.destroy();
      message.loading('Uploading new images', 0);
      const newImagesList = await uploadImageListToS3(formData.sortKey, newItems);
      imageList = [...imageList, ...newImagesList];
    }
    return imageList;
  };

  const addUpdateItem = async () => {
    const formData = form.getFieldsValue();

    formData.goodsIn = JSON.stringify(getGoodsValue(formData.goodsIn));
    formData.goodsOut = JSON.stringify(getGoodsValue(formData.goodsOut));
    formData.sortKey = inventoryItem ? inventoryItem.sortKey : ulid();
    formData.workShopId = inventoryItem ? inventoryItem.workShopId : workShopId;
    formData.image = await updateImages(formData);

    message.destroy();
    message.loading(`${inventoryItem ? 'Updating' : 'Saving'} inventory item`, 0);
    await addItems(formData)
      .then((res: any) => {
        if (res) {
          message.destroy();
          message.success(`Inventory item ${inventoryItem ? 'updated' : 'saved'} successfully`);
          fetchData();
          onClose();
        }
      }).catch((e: any) => {
        console.error(e);
        message.destroy();
        message.error('Something went wrong');
        onClose();
      });
  };

  const onImageChange = (info) => {
    setLoading(true);
    const { fileList: newFileList } = info;
    setFileList(newFileList);
    setLoading(false);
  };

  const handlePreview = async (file: any) => {
    const data = file;
    if (!file.url && !file.preview) {
      data.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleCancel = () => setPreviewVisible(false);

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const getFieldData = (name: string, data: any) => {
    const { item, value, unit = undefined } = data;
    const obj: any = { };
    if (item) obj.item = item;
    if (item === GOODS_TYPE.PIECES) {
      if (value) obj.pieces = value;
    } else {
      if (unit) obj.unit = unit;
      if (value) obj.value = value;
    }
    return obj;
  };

  const loadImages = () => {
    setFileList([]);
    setLoading(true);
    if (inventoryItem.image) {
      inventoryItem.image.forEach(async (image: any, index: number) => {
        const imageUrlObj = {
          uid: index,
          name: `image_${index}`,
          status: 'done',
          url: image,
        };
        if (!detectURLs(image)) {
          const key = `${workShopId}/${inventoryItem.sortKey}/${image}`;
          await getSignedUrl(key, S3_METHODS.get).then((imageUrl: any) => {
            imageUrlObj.name = image;
            imageUrlObj.url = imageUrl.url;
          }).catch((e:any) => console.error(e));
        }
        setFileList((prevData: any[]) => [...prevData, imageUrlObj]);
      });
    }
    setLoading(false);
  };

  const loadFormData = () => {
    const goodsInObj = inventoryItem.goodsIn ? JSON.parse(inventoryItem.goodsIn) : {};
    const goodsOutObj = inventoryItem.goodsOut ? JSON.parse(inventoryItem.goodsOut) : {};
    loadImages();
    const formData = { ...inventoryItem };
    formData.goodsIn = getFieldData('goodsIn', goodsInObj);
    formData.goodsOut = getFieldData('goodsOut', goodsOutObj);
    form.setFieldsValue(formData);
    setGoodsIn(goodsInObj?.item);
    setGoodsOut(goodsOutObj?.item);
  };

  useEffect(() => {
    if (inventoryItem) {
      loadFormData();
    }
  }, [inventoryItem]);

  const onFieldsChange = (e) => {
    if (e[0].name[0] === 'goodsIn' && e[0].name[1] === 'item') {
      setGoodsIn(e[0].value);
    }
    if (e[0].name[0] === 'goodsOut' && e[0].name[1] === 'item') {
      setGoodsOut(e[0].value);
    }
  };

  const getOptions = (unitType) => {
    const options = unitOptions[unitType];
    return options.map((option: string) => <Option value={option}>{option}</Option>);
  };

  return (
    <Drawer
      title={inventoryItem ? `${t('Edit Item')}` : `${t('Add Item')}`}
      placement="right"
      width={800}
      onClose={onClose}
      visible={isModalVisible}
    >
      <Form
        form={form}
        onFinish={addUpdateItem}
        onFieldsChange={onFieldsChange}
        layout="vertical"
        hideRequiredMark
      >
        {/* Row 1 */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="itemType"
              label={t('Item Type')}
              rules={[{ required: true, message: 'Please Enter Item Type' }]}
            >
              <Select placeholder={t('Select Item Type')}>
                <Select.Option value="Item A">Item A</Select.Option>
                <Select.Option value="Item B">Item B</Select.Option>
                <Select.Option value="Item C">Item C</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="partNumber"
              label={t('Part Number')}
              rules={[{ required: true, message: 'Please Enter Part Number' }]}
            >
              <Input placeholder={t('Enter Part Number')} />
            </Form.Item>
          </Col>
        </Row>
        {/* Row 2 */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="partName"
              label={t('Part Name')}
              rules={[{ required: true, message: 'Please Enter Part Name' }]}
            >
              <Input placeholder={t('Enter Part Name')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="supplierInformation"
              label={t('Supplier Information')}
              rules={[{ required: true, message: 'Please Enter Supplier Information' }]}
            >
              <Input placeholder={t('Enter Supplier Information')} />
            </Form.Item>
          </Col>
        </Row>
        {/* Row 3 */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="description"
              label={t('Description / Details')}
              rules={[{ required: true, message: 'Please Enter Description' }]}
            >
              <Input placeholder={t('Enter Description')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="otherNames"
              label={t('Other Names')}
              rules={[{ required: true, message: 'Please Enter Other Names' }]}
            >
              <Input placeholder={t('Enter Other Names')} />
            </Form.Item>
          </Col>
        </Row>
        {/* Row 2 */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="warehouseType"
              label={t('Warehouse Type')}
              rules={[{ required: true, message: 'Please Enter Warehouse Type' }]}
            >
              <Input placeholder={t('Enter Warehouse Type')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="binLocation"
              label={t('Bin Location')}
              rules={[{ required: true, message: 'Please Enter Bin Location' }]}
            >
              <Input placeholder={t('Enter Bin Location')} />
            </Form.Item>
          </Col>
        </Row>
        {/* Row 5 */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="goodsIn"
              label={t('Goods In')}
            >
              <Input.Group compact>
                <Form.Item
                  name={['goodsIn', 'item']}
                  noStyle
                  rules={[{ required: true, message: 'Please select Goods In' }]}
                >
                  <Radio.Group optionType="button">
                    <Radio.Button value={GOODS_TYPE.PIECES}>{GOODS_TYPE.PIECES}</Radio.Button>
                    <Radio.Button value={GOODS_TYPE.LIQUID}>{GOODS_TYPE.LIQUID}</Radio.Button>
                    <Radio.Button value={GOODS_TYPE.WEIGHT}>{GOODS_TYPE.WEIGHT}</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {goodsIn === GOODS_TYPE.PIECES
                  && (
                    <Form.Item
                      name={['goodsIn', 'pieces']}
                      noStyle
                      rules={[{ required: true, message: 'Please Enter # pieces' }]}
                    >
                      <InputNumber placeholder="#pieces" min={1} style={{ width: 80, marginLeft: 10 }} />
                    </Form.Item>
                  )}
                {(goodsIn === GOODS_TYPE.LIQUID || goodsIn === GOODS_TYPE.WEIGHT)
                  && (
                  <>
                    <Form.Item
                      name={['goodsIn', 'value']}
                      noStyle
                      rules={[{ required: true, message: 'Please select value' }]}
                    >
                      <InputNumber min={1} style={{ width: 60, marginLeft: 10 }} />
                    </Form.Item>
                    <Form.Item
                      name={['goodsIn', 'unit']}
                      noStyle
                      rules={[{ required: true, message: 'Please select unit' }]}
                    >
                      <Select style={{ width: 60 }} placeholder="Unit">
                        {getOptions(goodsIn)}
                      </Select>
                    </Form.Item>
                  </>
                  )}
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="goodsOut"
              label={t('Goods Out')}
            >
              <Input.Group compact>
                <Form.Item
                  name={['goodsOut', 'item']}
                  noStyle
                  rules={[{ required: true, message: 'Please select Goods out' }]}
                >
                  <Radio.Group optionType="button">
                    <Radio.Button value={GOODS_TYPE.PIECES}>{GOODS_TYPE.PIECES}</Radio.Button>
                    <Radio.Button value={GOODS_TYPE.LIQUID}>{GOODS_TYPE.LIQUID}</Radio.Button>
                    <Radio.Button value={GOODS_TYPE.WEIGHT}>{GOODS_TYPE.WEIGHT}</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {goodsOut === GOODS_TYPE.PIECES
                  && (
                  <Form.Item
                    name={['goodsOut', 'pieces']}
                    noStyle
                    rules={[{ required: true, message: 'Please Enter # pieces' }]}
                  >
                    <InputNumber placeholder="#pieces" min={1} style={{ width: 80, marginLeft: 10 }} />
                  </Form.Item>
                  )}
                {(goodsOut === GOODS_TYPE.LIQUID || goodsOut === GOODS_TYPE.WEIGHT)
                  && (
                  <>
                    <Form.Item
                      name={['goodsOut', 'value']}
                      noStyle
                      rules={[{ required: true, message: 'Please select value' }]}
                    >
                      <InputNumber min={1} style={{ width: 60, marginLeft: 10 }} />
                    </Form.Item>
                    <Form.Item
                      name={['goodsOut', 'unit']}
                      noStyle
                      rules={[{ required: true, message: 'Please select unit' }]}
                    >
                      <Select style={{ width: 60 }} placeholder="Unit">
                        {getOptions(goodsIn)}
                      </Select>
                    </Form.Item>
                  </>
                  )}
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
        {/* Row 2 */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="anticipatedUsage"
              label={t('Anticipated Usage')}
              rules={[{ required: true, message: 'Please Enter Anticipated Usage' }]}
            >
              <Input placeholder={t('Enter Anticipated Usage')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="warrantyDetails"
              label={t('Warranty Details')}
              rules={[{ required: true, message: 'Please Enter Warranty Details' }]}
            >
              <Input placeholder={t('Enter Warranty Details')} />
            </Form.Item>
          </Col>
        </Row>
        {/* Row 5 */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="minOrderQuantityToSupplier"
              label={t('Min Order Quantity To Supplier')}
              rules={[{ required: true, message: 'Please Enter Min Order Quantity-To-Supplier' }]}
            >
              <Input
                placeholder={t('Enter Min Order Quantity-To-Supplier')}
                type="number"
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="image"
              label={t('Browse Image (Optional)')}
            >
              <Upload disabled />
              <ImgCrop grid aspect={1280 / 720} quality={0.4}>
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onImageChange}
                  onPreview={handlePreview}
                  customRequest={dummyRequest}
                  disabled={isLoading}
                  accept=".png, .jpg, .jpeg"
                >
                  <div>
                    {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>

              </ImgCrop>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
                width={800}
              >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ float: 'right' }}>
          <Button className="sav-btn" htmlType="submit">
            {t('submit')}
          </Button>
        </Row>
      </Form>
    </Drawer>
  );
};

export default AddEditItem;
