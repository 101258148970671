import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button, Col, Input, message, Row, Table, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { ACTION_TYPE } from '../../../enum/ConfigurationData';
import { getAllGRN, searchGRN } from './actions';
import Loading from '../../../Loading';
import invoiceColumns from './columns/invoiceColumns';
import ViewItem from './components/ViewItem';
import AddEditItem from './components/AddEditItem';
import { hasRole } from '../../../utils/commonUtil';
import { USER_ROLES } from '../../../enum/User';

type propType = {
  userRoles: string[];
  workShopId: string;
}

function GrnInvoice({ userRoles, workShopId }: propType) {
  const { Title } = Typography;
  const [selectedType, setSelectedType] = useState<ACTION_TYPE>(ACTION_TYPE.VIEW);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [data, setData] = useState<any>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState(true);
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const { t } = useTranslation();

  const getAllGrnData = async (isFetch: boolean = false) => {
    let limit = 20;
    let nextToken: any = null;
    if (pageNumber > 1 && !isFetch) {
      limit = 10;
      nextToken = data.nextToken;
    }

    await getAllGRN(workShopId, limit, nextToken).then((res: any) => {
      const newData = (res && res?.data?.getGRNItems) || [];
      const newTableData = newData.grnInvoice;
      nextToken = newData.nextToken || null;
      setTableData((prevState: any) => {
        if (isFetch) {
          return newTableData;
        }
        return [...prevState, ...newTableData];
      });
      setData(newData);
      setLoading(false);
      if (newTableData.length < 10 || !nextToken) {
        setHasMore(false);
      }
      if (nextToken) {
        setPageNumber(pageNumber + 1);
      }
    }).catch((err: any) => {
      message.error('Something went wrong');
      console.error(err);
    });
  };

  const fetchData = () => {
    if (workShopId) {
      setHasMore(true);
      setData(undefined);
      setTableData([]);
      setPageNumber(1);
      setRefresh(true);
    }
  };

  const onSearch = (e) => {
    let keyword = e.target.value;
    keyword = keyword.toLowerCase();
    if (keyword === '') {
      fetchData();
    } else {
      searchGRN(workShopId, keyword).then((res: any) => {
        setTableData(res.data.searchItem);
      }).catch((err: any) => {
        console.error(err);
      });
    }
  };

  const tablePagination = (e) => {
    if (e + 1 >= pageNumber && hasMore) {
      getAllGrnData().finally();
    }
  };

  const triggerModal = (action: ACTION_TYPE, item: any = undefined) => {
    setSelectedItem(item);
    setSelectedType(action);
    setModalVisible(true);
  };

  useEffect(() => {
    if (isRefresh) {
      getAllGrnData(isRefresh).finally(() => {
        setRefresh(false);
      });
    }
  }, [isRefresh]);

  useEffect(() => {
    fetchData();
  }, [workShopId]);

  return (
    <>
      <Row>
        <Col>
          <Title level={3} style={{ color: '#8C8C8C' }}>{t('Invoicing')}</Title>
        </Col>
      </Row>
      <Row style={{ marginBottom: '20px' }}>
        <Col xs={14} sm={7} md={8} lg={14} xl={14} />
        <Col xs={3} sm={6} md={8} lg={3} xl={3} style={{ paddingLeft: '20px' }}>
          <Button
            block
            onClick={() => triggerModal(ACTION_TYPE.ADD)}
            className="inventory-button add-item-button"
            icon={<PlusOutlined />}
            disabled={!hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN], userRoles)}
          >
            {t('Add Item')}
          </Button>
        </Col>
        <Col xs={6} sm={6} md={7} lg={6} xl={6} offset={1}>
          <Input onChange={onSearch} addonBefore={<SearchOutlined />} placeholder="Search" />
        </Col>
      </Row>
      {loading ? <Loading />
        : (
          <Row className="table-row">
            <Table
              columns={invoiceColumns(t, triggerModal, userRoles)}
              dataSource={tableData}
              scroll={{ y: 600 }}
              pagination={{ onChange: tablePagination }}
            />
          </Row>
        )}
      {selectedType === ACTION_TYPE.VIEW ? (
        <ViewItem
          grnItems={selectedItem}
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
        />
      ) : (
        <AddEditItem
          workShopId={workShopId}
          fetchData={fetchData}
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          grnItem={selectedType === ACTION_TYPE.ADD ? undefined : selectedItem}
          grnItems={selectedItem}
        />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    userRoles: state.userData.userRoles,
  };
}
export default connect(mapStateToProps, {})(GrnInvoice);
