import React from 'react';
import { Button, Card } from 'antd';
import { logout } from '../../login/actions';
import { useAppDispatch } from '../../../redux/hooks';

type propType = {
    userId: string | String,
    user: any
};

function UserDetails({ userId, user }: propType) {
  const dispatch = useAppDispatch();

  return (
    <Card className="ky_prof_info">
      <div>
        {userId && (
          <div>
            <h4>
              <strong>{user?.username}</strong>
            </h4>
            <p>
              {' '}
              {user?.email}
              {' '}
            </p>
          </div>
        )}
        <div>
          {userId && <Button className="ky_mn_btn-log" block htmlType="submit" onClick={() => logout(dispatch)}>Sign out</Button>}
          {!userId && (
          <div>
            <h4 className="welcome">Welcome to KARSTATION</h4>
            <Button
              className="ky_mn_btn-log"
              block
              htmlType="submit"
              onClick={() => {
                window.location.href = '/login';
              }}
            >
              Sign in
            </Button>
          </div>
          )}
        </div>
      </div>
    </Card>
  );
}

export default UserDetails;
