import moment from 'moment';
import {
  AutoComplete, Col, Row, Upload,
} from 'antd';
import React from 'react';
import { USER_ROLES } from '../../../../../enum/User';
import { capitalizeFirstLetter } from '../common';
import { hasRole } from '../../../../../utils/commonUtil';

type BookingDetailsType = {
  t: Function,
  item: any,
  supervisorList: any[],
  supervisorNotSelected: Function,
  supervisorSelected: Function,
  userRoles: any[],
  fileList: any[],
  qcFileList: any[]
};

const VehicleBookingDetails = ({
  t,
  item,
  supervisorList,
  supervisorNotSelected,
  supervisorSelected,
  userRoles,
  fileList,
  qcFileList,
}: BookingDetailsType) => {
  const columns = [
    {
      title: 'Jobtype',
      value: t(capitalizeFirstLetter(item?.jobType)),
    },
    {
      title: 'date',
      value: moment(item?.selectedDate).format('MMMM Do YYYY'),
    },
    {
      title: 'time',
      value: moment(item?.selectedDate).format('h:mm a'),
    },
    {
      title: 'Assignee',
      value: <AutoComplete
        size="small"
        className="auto-compete"
        options={supervisorList}
        onSelect={(e: any) => supervisorSelected(e)}
        onChange={(e: any) => supervisorNotSelected(e)}
        placeholder={t('Assign to..')}
        defaultValue={item.assigneeName || null}
        disabled={hasRole([USER_ROLES.TECHNICIAN, USER_ROLES.RECEPTIONIST], userRoles)}
      />,
    },
  ];

  return (
    <>
      {columns.map((row: any, index: number) => {
        const generatedKey = `${row}-${index}`;
        return (
          <Col xs={24} sm={24} md={12} lg={12} xl={12} key={generatedKey}>
            <Row>
              <Col className="col-title" span={12}>{t(row?.title || '')}</Col>
              <Col className="col-value" span={12}>{row?.value}</Col>
            </Row>
          </Col>
        );
      })}
      {item.comment && (
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Row>
          <Col className="col-title" span={6}>Comment</Col>
          <Col className="col-value" span={18}>{item.comment}</Col>
        </Row>
      </Col>
      )}
      {(fileList && fileList.length > 0) && (
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Row className="tab-row">
          <h4>{t('Images')}</h4>
          <br />
          <Upload
            listType="picture-card"
            fileList={fileList}
            disabled
          />
        </Row>
      </Col>
      )}
      {(qcFileList && qcFileList.length > 0) && (
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Row className="tab-row">
          <h4>{t('Job finalized images')}</h4>
          <br />
          <Upload
            listType="picture-card"
            fileList={qcFileList}
            disabled
          />
        </Row>
      </Col>
      )}
    </>
  );
};

export default VehicleBookingDetails;
