import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/plots';
import moment from 'moment';
import { Card, DatePicker } from 'antd';
import { getIncomeByPaymentMethod } from '../action';
import { generateFromDate, generateToDate } from '../common';
import { PAYMENT_TYPE } from '../../../../enum/BookingStatus';

const { RangePicker } = DatePicker;

type ChartDataType = {
  type: string,
  value: number
}

const mockData: ChartDataType[] = [];

const MethodOfPayments = () => {
  const [chartData, setChartData] = useState<any[]>(mockData);
  const [date, setDate] = useState<any>({ from: generateFromDate(), to: generateToDate() });
  const dateFormat = 'YYYY-MM-DD';

  const onChange = (value, dateString) => {
    setDate({ from: dateString[0], to: dateString[1] });
  };

  const getColor = ({ type }: any) => {
    if (type === PAYMENT_TYPE.CASH) {
      return '#F4664A';
    }
    return '#5B8FF9';
  };

  const fetchData = async () => {
    const from: string = moment(date.from).format('YYYY-MM-DD');
    const to: string = moment(date.to).format('YYYY-MM-DD');
    const { data } : any = await getIncomeByPaymentMethod(from, to);
    let dataArray;
    if (data) {
      dataArray = Object.values(data)
        .map(({ label, value }: any) => ({ type: label, value }));
    }
    setChartData(dataArray);
  };

  useEffect(() => {
    if (date) {
      fetchData();
    }
  }, [date]);

  return (
    <div>
      <Card
        title="Income based on payment method used by customer"
        extra={(
          <RangePicker
            onChange={onChange}
            defaultValue={[
              generateFromDate(),
              generateToDate(),
            ]}
            format={dateFormat}
          />
)}
        style={{ width: 1170 }}
      >
        <Column
          data={chartData}
          xField="type"
          yField="value"
          seriesField=""
          color={getColor}
          xAxis={{
            label: {
              autoHide: true,
              autoRotate: false,
            },
          }}
        />
      </Card>
    </div>
  );
};

export default MethodOfPayments;
