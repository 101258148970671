import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  Button, Col, Drawer, Row, Table, Tabs, Typography,
} from 'antd';
import { getFormattedJobs } from '../../../../utils/tableUtil';
import { getBookingContent, getJobList } from '../actions';
import drawerTableColumn from '../columns/drawerTableColumn';
import {
  bookingDetails, ownerDetails, userDetails, vehicleDetails,
} from '../columns/bookingDetailsColumn';
import { BOOKING_TYPES } from '../../../../enum/BookingStatus';
import { updateBooking } from '../../viewJobs/actions';
import { USER_ROLES } from '../../../../enum/User';
import { hasRole } from '../../../../utils/commonUtil';

type CreditDetailsType = {
  configData: any,
  item: any
  isModalVisible: boolean,
  onClose: Function,
  getAllCredits: Function,
  tabStatus: any,
  userRoles: any,
}

function CreditDetails({
  item, configData, isModalVisible, onClose, getAllCredits, tabStatus, userRoles,
}: CreditDetailsType) {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [formattedJobList, setFormattedJobList] = useState<any[]>([]);

  const dataColumn = drawerTableColumn(t);
  const { currency = '', languageCode = '' }: any = configData?.currency;

  const getJobs = () => {
    setFormattedJobList([]);
    getJobList(item.workShopId, item.sortKey).then((res: any) => {
      const jobsList: any[] = res.data?.getJobs;
      setFormattedJobList(getFormattedJobs(jobsList, currency, languageCode));
    });
  };

  const getCreditAttachment = async () => {
    const key = `${item.workShopId}/${item.sortKey}/Credit/${item.creditAttachment}`;
    const res = await getBookingContent(key);
    window.open(res.body.url);
  };
  const onApprove = async () => {
    const updatedBooking: any = {
      ...item,
      updatedTime: new Date(),
      paymentMethod: 'Credit',
    };
    updatedBooking.status = BOOKING_TYPES.CHECKOUT;
    if (tabStatus === BOOKING_TYPES.CHECKOUT) {
      updatedBooking.status = BOOKING_TYPES.DONE;
    }
    await updateBooking(updatedBooking);
    await getAllCredits();
    onClose();
  };

  const footer = (
    <>
      <Button
        type="primary"
        style={{
          float: 'right', marginBottom: '20px', marginTop: '10px', marginRight: '10px',
        }}
        disabled={
           tabStatus === BOOKING_TYPES.AWAITING_APPROVAL
            && hasRole([USER_ROLES.ADMIN, USER_ROLES.RECEPTIONIST], userRoles)
}
        onClick={onApprove}
      >
        {tabStatus === BOOKING_TYPES.AWAITING_APPROVAL
          ? t('Approve') : t('Checkout') }
      </Button>
      {tabStatus === BOOKING_TYPES.AWAITING_APPROVAL
      && (
      <Button
        style={{ marginRight: '10px', float: 'right', marginTop: '10px' }}
        disabled={!hasRole([USER_ROLES.WORKSHOP_ADMIN], userRoles)}
        onClick={onClose}
      >
        {t('Reject')}
      </Button>
      )}
    </>
  );

  useEffect(() => {
    if (item) {
      getJobs();
    }
  }, [item]);

  return (

    <Drawer
      onClose={onClose}
      footer={footer}
      width={window.innerWidth > 1001 ? 1000 : window.innerWidth - 2}
      title={t('Job Details')}
      placement="right"
      visible={isModalVisible}
    >

      <div>
        <Tabs defaultActiveKey="1" type="card" animated>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-titl">{t('Booking Details')}</Title>
              </span>
            )}
            key="1"
          >
            <Row className="tab-row">
              {bookingDetails(t, item).map((row) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{(row?.value)}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
            <Row>
              <header className="tableTopic">{t('Invoice')}</header>
            </Row>
            <Col>
              <Table
                columns={dataColumn}
                dataSource={formattedJobList}
                pagination={false}
              />
            </Col>
            {item?.creditAttachment && (
            <Row className="tab-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row>
                  <Col className="col-title" span={6}>{t('Attachments')}</Col>
                  <Col className="col-value" span={18}>
                    <Button type="link" onClick={getCreditAttachment} style={{ marginTop: '-5px', marginBottom: '-5px' }}>{item.creditAttachment}</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-titl">{t('Vehicle Details')}</Title>
              </span>
            )}
            key="2"
          >
            <Row className="tab-row">
              {vehicleDetails(t, item).map((row) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{(row?.value)}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-titl">{t('Owner Details')}</Title>
              </span>
            )}
            key="3"
          >
            <Row className="tab-row">
              {ownerDetails(t, item).map((row) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{(row?.value)}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-titl">{t('User Details')}</Title>
              </span>
            )}
            key="4"
          >
            <Row className="tab-row">
              {userDetails(t, item).map((row) => (

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{(row?.value)}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Drawer>
  );
}
function mapStateToProps(state) {
  return {
    userRoles: state.userData.userRoles,
    configData: state.userData.configData,
  };
}

export default connect(mapStateToProps, {})(CreditDetails);
