import React from 'react';
import Lottie from 'react-lottie';
import * as loading from './animations/carr.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function Loading() {
  return (
    <div style={{ marginTop: '10rem' }}>
      <Lottie options={defaultOptions} height={250} width={250} />
    </div>
  );
}
