import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Drawer, Button, Form,
  Steps, message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { saveEmployee } from '../action';
import '../staff.scss';
import GeneralForm from './GeneralForm';
import QualificationsForm from './QualificationsForm';
import SalaryForm from './SalaryForm';

function Validation(email, contactNumber) {
  let isEmailValidate = false;
  let isContactNumberValidate = false;
  const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  if (email) {
    if (pattern.test(email)) {
      isEmailValidate = true;
    } else {
      message.warning('You have entered an invalid email address');
      isEmailValidate = false;
    }
  }
  if (contactNumber) {
    if (contactNumber[0] === '0' && contactNumber.length === 10) {
      isContactNumberValidate = true;
    } else {
      message.warning('You have entered an invalid contact number');
      isContactNumberValidate = false;
    }
  }
  return isEmailValidate && isContactNumberValidate;
}

type propType ={
  workShopId: string,
  userRoles: any,
  fetchData: any,
}

export const AddEmployee = ({ workShopId, userRoles, fetchData }: propType) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [activeStep, setActiveStep] = useState(0);
  const [generalFormsData, setGeneralFormData] = useState({});
  const [qualificationsFormsData, setQualificationsFormData] = useState<Object>({});
  const [fileList, setFileList] = useState([]);
  const { t } = useTranslation();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
    setActiveStep(0);
  };

  const onSave = (salaryFormData:Object) => {
    saveEmployee(generalFormsData, qualificationsFormsData, salaryFormData)
      .then((res) => {
        if (res) {
          message.destroy();
          message.success('Employee Added');
          onClose();
          fetchData();
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  function next() {
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);
  }

  function prev() {
    const prevStep = activeStep - 1;
    setActiveStep(prevStep);
  }

  const onSubmitGeneral = () => {
    const {
      name,
      userRole,
      nic,
      dob,
      contactNumber,
      address,
      userName,
      status,
      email,
    } = form.getFieldsValue();

    const generalFormData = {
      name,
      userRole,
      nic,
      dob,
      contactNumber,
      address,
      userName,
      status,
      workShopId,
      email,
    };

    if (Validation(email, contactNumber)) {
      setGeneralFormData(generalFormData);
      next();
    }
  };

  const onSubmitQualifications = () => {
    setQualificationsFormData({});
    next();
  };

  const onSubmitSalary = () => {
    const {
      accountHolderName,
      bank,
      branch,
      accountNumber,
      basicSalary,
      epfEmployeeContribution,
      epfCompanyContribution,
      etf,
    } = form.getFieldsValue();

    const salaryFormData = {
      accountHolderName,
      bank,
      branch,
      accountNumber,
      basicSalary,
      epfEmployeeContribution,
      epfCompanyContribution,
      etf,
    };
    onSave(salaryFormData);
  };

  const onPdfChange = (info) => {
    const { fileList: newFileList } = info;
    setFileList(newFileList);
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  return (
    <>
      <Button onClick={showDrawer} disabled={userRoles && (userRoles.includes('Service-Advisor') || userRoles.includes('Technician'))} className="employee-button add-employee-button">{t('Add an Employee')}</Button>
      <Drawer title={t('Add an Employee')} placement="right" width={700} visible={visible} onClose={onClose}>
        <Steps className="steps" current={activeStep} type="navigation">
          <Steps.Step className="steps-step" title={`${t('General')}`} />
          <Steps.Step className="steps-step" title={`${t('Qualifications')}`} />
          <Steps.Step className="steps-step" title={`${t('Salary')}`} />
        </Steps>
        {activeStep === 0
          ? (
            <GeneralForm form={form} onSubmitGeneral={onSubmitGeneral} t={t} />
          ) : null}
        {activeStep === 1
          ? (
            <QualificationsForm
              form={form}
              onSubmitQualifications={onSubmitQualifications}
              fileList={fileList}
              onPdfChange={onPdfChange}
              dummyRequest={dummyRequest}
              prev={() => prev()}
            />
          ) : null}
        {activeStep === 2
          ? (
            <SalaryForm form={form} prev={() => prev()} onSubmitSalary={onSubmitSalary} t={t} />
          ) : null}
      </Drawer>
    </>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    userRoles: state.userData.userRoles,
  };
}

export default connect(mapStateToProps, {})(AddEmployee);
