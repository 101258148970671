import React, { useEffect, useState } from 'react';
import {
  Col,
  Drawer,
  Row,
  Tag,
  Typography,
  Tabs,
  message,
  Table,
} from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getAllJobAssignee,
  getEmployeeDetails,
  getJobList,
  updateBooking,
} from '../actions';
import '../jobTaskBoard.scss';
import { getImageUrl } from '../../investigation/action';
import { USER_ROLES } from '../../../../enum/User';
import {
  ownerDetails, userDetails, vehicleDetails,
} from './columns/VehicleDetails';
import VehicleBookingDetails from './componentes/VehicleBookingDetails';
import {
  capitalizeFirstLetter, getJobCount, getJobStatusColor, getPriority,
} from './common';
import PendingPayment from './componentes/PendingPayment';
import QC from './componentes/QC';
import ToDoInProgress from './componentes/ToDoInProgress';
import { BOOKING_TYPES } from '../../../../enum/BookingStatus';
import { getBookingContent } from '../../bookingHistory/action';

type ViewJobType = {
  item: any,
  currentStatus: any,
  getAllBookings: Function,
  workShopId: string,
  status: BOOKING_TYPES,
  userRoles: any[],
  generatedKey: string,
  isModalVisible: boolean,
  setIsModalVisible: Function,
}

export const ViewJob = ({
  item,
  currentStatus,
  getAllBookings,
  workShopId,
  status,
  userRoles,
  generatedKey,
  isModalVisible,
  setIsModalVisible,
}: ViewJobType) => {
  const { Title } = Typography;
  const [colour, setColour] = useState<string>('orange');
  const [statusColor, setStatusColor] = useState<string>('orange');
  const [jobList, setJobList] = useState<any[]>([]);
  const [technicianList, setTechnicianList] = useState<any[]>([]);
  const [supervisorList, setSupervisorList] = useState<any[]>([]);
  const [supervisorNames, setSupervisorNames] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [qcFileList, setQCFileList] = useState<any[]>([]);
  const { t } = useTranslation();

  const getJobs = () => {
    getJobList(workShopId, item.sortKey).then((res: any) => {
      const jobsList: any[] = res.data?.getJobs;
      setJobList(jobsList);
    });
  };

  const getAllJobAssigneeList = () => {
    getAllJobAssignee(workShopId).then((res: any) => {
      let allJobAssignee = res.data.getAllJobAssignee;
      allJobAssignee = allJobAssignee.filter((assigner: any) => {
        let flag;
        switch (assigner.role) {
          case USER_ROLES.TECHNICIAN:
            flag = true;
            break;
          case USER_ROLES.SERVICE_ADVISOR:
            flag = true;
            break;
          case USER_ROLES.ADMIN:
            flag = true;
            break;
          default:
            flag = false;
            break;
        }
        return flag;
      });
      setTechnicianList(allJobAssignee);
    }).catch(() => {
      message.destroy();
      message.error('Something went wrong when getting all job assignees').then();
    });
  };

  const supervisorNotSelected = (keyword: String) => {
    if (keyword !== '') {
      getEmployeeDetails(workShopId, keyword.toLowerCase()).then((res: any) => {
        const array: any[] = [];
        res.data.searchEmployee.forEach((result: any) => {
          if (result.role === USER_ROLES.WORKSHOP_ADMIN
              || result.role === USER_ROLES.ADMIN
              || result.role === USER_ROLES.SERVICE_ADVISOR) {
            array.push({ value: result.name });
          }
        });
        setSupervisorNames(res.data.searchEmployee);
        setSupervisorList(array);
      });
    } else {
      setSupervisorList([]);
    }
  };

  const supervisorSelected = (e: any) => {
    const selectedSupervisor = supervisorNames.filter((sItem: any) => sItem.name === e);
    setSupervisorNames(selectedSupervisor[0]);
    const updatedBooking = {
      ...item,
      workShopId,
      assigneeName: e,
      assigneeId: selectedSupervisor[0].employeeId,
      updatedTime: new Date(),
    };
    updateBooking(updatedBooking).then((res: any) => {
      getAllBookings();
      if (res) {
        message.destroy();
        message.success(`Successfully assigned booking to ${e}`).then();
      }
    }).catch(() => {
      message.destroy();
      message.success(`Error while assigning booking to ${e}`).then();
    });
  };

  const onClose = async (isBookingUpdated: boolean) => {
    if (!isBookingUpdated) {
      const {
        qcReadyCount,
        qcPassCount,
        rejectedCount,
      } = getJobCount(jobList);
      const totalJobCount = jobList.length - rejectedCount;
      const qc = qcReadyCount + qcPassCount;
      const updatedBooking = {
        ...item,
        progress: Math.trunc((qc / totalJobCount) * 100),
      };
      if (updatedBooking.progress !== item.progress) {
        message.loading('Updating Booking progress');
        await updateBooking(updatedBooking);
        message.destroy();
        message.success('Progress updated successfully');
      }
    }
    await getAllBookings();
    setIsModalVisible(false);
  };

  const loadImages = () => {
    setFileList([]);
    if (item.images) {
      item.images.forEach((image: any, index: number) => {
        getImageUrl(image, 'image/jpeg', 'getObject').then((imageUrl) => {
          const imageUrlObj = {
            uid: index,
            name: image,
            status: 'done',
            url: imageUrl.url,
          };
          setFileList((prevData: any[]) => [...prevData, imageUrlObj]);
        });
      });
    }
  };

  const loadQCImages = () => {
    setQCFileList([]);
    if (item.qcImages) {
      item.qcImages.map((image: any, index: number) => {
        const key = `${workShopId}/${item.sortKey}/QC/${image}`;
        getBookingContent(key).then((imageUrl) => {
          const imageUrlObj = {
            uid: index,
            name: image,
            status: 'done',
            url: imageUrl.body.url,
          };
          setQCFileList((prevData: any[]) => [...prevData, imageUrlObj]);
        });
        return null;
      });
    }
  };

  const getJobsDetails = () => {
    switch (status) {
      case BOOKING_TYPES.QC:
        return (
          <QC
            jobList={jobList}
            getAllBookings={getAllBookings}
            item={item}
            getJobs={getJobs}
            t={t}
            setJobList={setJobList}
          />
        );
      case BOOKING_TYPES.PENDING_PAYMENT:
        return (
          <PendingPayment
            item={item}
            jobList={jobList}
            getAllBookings={getAllBookings}
          />
        );
      default:
        return (
          <ToDoInProgress
            getJobs={getJobs}
            technicianList={technicianList}
            item={item}
            jobList={jobList}
            setJobList={setJobList}
            getAllBookings={getAllBookings}
            t={t}
            status={status}
            onClose={onClose}
          />
        );
    }
  };

  const QCResultColumn = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '500px',
      align: 'left' as const,
      render: (title: string, job: any) => ({
        children:
  <>
    {job.description}
  </>,
      }),
    },
    {
      title: 'Reason',
      dataIndex: 'qcComment',
      key: 'qcComment',
      width: '500px',
      align: 'left' as const,
      render: (title: string, job: any) => ({
        children:
  <>
    {job.qcComment}
  </>,
      }),
    },
  ];

  useEffect(() => {
    if (item) {
      setColour(getPriority(item.jobType));
      setStatusColor(getJobStatusColor(item.status));
      getJobs();
      loadImages();
      getAllJobAssigneeList();
      loadQCImages();
    }
  }, [item]);

  const qcResultData = jobList.filter((job: any) => job.qcComment);

  return (
    <div key={generatedKey}>
      <Drawer
        title={(
          <>
            <Row>
              <Col span={11}>
                {`${t('Plate Number')} - ${item.plateNumber}`}
              </Col>
              <Col span={4} className="tag" style={{ marginTop: -3 }}>
                <Tag
                  style={{
                    color: 'black',
                    width: 140,
                    height: 30,
                    textAlign: 'center',
                    alignSelf: 'center',
                    borderRadius: '8px',
                    paddingTop: 5,
                    fontSize: 15,
                    fontWeight: 'lighter',
                  }}
                  className="tag"
                  color={statusColor}
                >
                  {t(currentStatus)}
                </Tag>
              </Col>
              <Col span={4} style={{ marginTop: -3 }}>
                <Tag
                  style={{
                    width: 140,
                    height: 30,
                    textAlign: 'center',
                    alignSelf: 'center',
                    borderRadius: '8px',
                    paddingTop: 5,
                    fontSize: 15,
                    fontWeight: 'lighter',
                  }}
                  color={colour}
                >
                  {t(capitalizeFirstLetter(item.jobType))}
                </Tag>
              </Col>
              {status === BOOKING_TYPES.PENDING_PAYMENT && (
                <Col span={4} style={{ marginTop: -3 }}>
                  <Tag
                    style={{
                      width: 140,
                      height: 30,
                      textAlign: 'center',
                      alignSelf: 'center',
                      borderRadius: '8px',
                      paddingTop: 5,
                      fontSize: 15,
                      fontWeight: 'lighter',
                    }}
                    color="#FF2024"
                  >
                    {t('Not paid')}
                  </Tag>
                </Col>
              )}
            </Row>
          </>
        )}
        placement="right"
        width={window.innerWidth > 1001 ? 1000 : window.innerWidth - 2}
        onClose={() => onClose(false)}
        visible={isModalVisible}
      >
        <Tabs defaultActiveKey="1" type="card" animated className="jobs-tab">
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-title">{t('Booking Details')}</Title>
              </span>
            )}
            key="1"
          >
            <Row className="tab-row">
              <VehicleBookingDetails
                t={t}
                item={item}
                supervisorList={supervisorList}
                supervisorNotSelected={supervisorNotSelected}
                supervisorSelected={supervisorSelected}
                userRoles={userRoles}
                fileList={fileList}
                qcFileList={qcFileList}
              />
            </Row>
            {getJobsDetails()}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-title">{t('QC Results')}</Title>
              </span>
            )}
            key="2"
          >
            {' '}
            <Row><Title className="drawr-title"> Quality Control Failed Tasks </Title></Row>
            <br />
            <Row>
              <Table dataSource={qcResultData} columns={QCResultColumn} pagination={false} />
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-title">{t('Vehicle Details')}</Title>
              </span>
            )}
            key="3"
          >
            <Row className="tab-row">
              {vehicleDetails(t, item).map((row: any) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12} key={row}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{row?.value}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-title">{t('Owner Details')}</Title>
              </span>
            )}
            key="4"
          >
            <Row className="tab-row">
              {ownerDetails(t, item).map((row: any) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12} key={row}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{row?.value}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-title">{t('User Details')}</Title>
              </span>
            )}
            key="5"
          >
            <Row className="tab-row">
              {userDetails(t, item).map((row: any) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12} key={row}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{row?.value}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    userRoles: state.userData.userRoles,
    workshop: state.userData.workshop,
  };
}

export default connect(mapStateToProps, {})(ViewJob);
