/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
export enum JOB_TYPES {
  Repair,
  Accident,
  Breakdown,
  Service,
  QuickJobs,
}

export enum CSV_TYPES {
  JOB_CREATION,
  INVENTORY,
}

export const S3_METHODS = {
  get: 'getObject',
  put: 'putObject',
};

export const globalPartArr = [
  {
    value: 'Audi',
    label: 'https://nemigaparts.com/cat_spares/etka/audi/',
  },
  {
    value: 'BMW',
    label: 'https://www.realoem.com/',
  },
  {
    value: 'Mercedes',
    label: 'https://nemigaparts.com/cat_spares/epc/mercedes/',
  },
  {
    value: 'Nissan',
    label: 'https://nemigaparts.com/cat_spares/cross/nissan/',
  },
  {
    value: 'Toyota',
    label: 'https://www.toyodiy.com/parts/',
  },
];

export enum CURRENCY_TYPE {
  LKR = 'LKR',
  AUD = 'AUD',
}

export enum CURRENCY_LANG_TYPE {
  LKR = 'si-LK',
  AUD = 'AU',
}

export enum ACTION_TYPE {
  VIEW,
  ADD,
  EDIT,
  DELETE
}

export const EMAIL_TYPE = {
  BOOKING_SUBMITTED: 0,
  INVESTIGATION: 1,
  ONLINE_PAYMENT: 2,
  CASH_PAYMENT: 3,
};
