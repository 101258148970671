import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';

const NewPasswordForm = ({
  form, staff, requestNewPassword, onLogin, t,
}: any) => (
  <Form form={form} layout="vertical" requiredMark={false} onFinish={onLogin}>
    <Form.Item label={`${t('Username')}`} className="ka_lbl_lgnfrm" name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
      <Input className="ks_int ks_int_lgnfrm" />
    </Form.Item>
    <Form.Item name="password" hidden />
    {(!staff && !requestNewPassword) && <Link to="/forget-password">Forgotten password?</Link>}
    <Form.Item
      label="New Password"
      className="ka_lbl_lgnfrm"
      name="newPassword"
      rules={[{
        required: true,
        message: 'Please input your new password',
      },
      {
        pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
        message: 'Password must be at least 8 characters and contain at least one number, one uppercase and one lowercase letter',
      }]}
    >
      <Input.Password className="ks_int ks_int_lgnfrm" />
    </Form.Item>
    <Form.Item
      label="Confirm New Password"
      className="ka_lbl_lgnfrm"
      name="confirmNewPassword"
      dependencies={['newPassword']}
      rules={[
        {
          required: true,
          message: 'Please confirm your password',
        },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value || getFieldValue('newPassword') === value) {
              return Promise.resolve();
            }

            return Promise.reject(new Error('The two passwords that you entered do not match'));
          },
        }),
      ]}
    >
      <Input.Password className="ks_int ks_int_lgnfrm" />
    </Form.Item>
    <Form.Item>
      <Button type="primary" className="ka_btn_lgn" htmlType="submit">Sign in</Button>
    </Form.Item>
  </Form>
);

export default NewPasswordForm;
