import React from 'react';
import {
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Taskboard from './taskboard/Taskboard';
import InvestiGationTour from './InvestigationTour';

export const Investigation = () => {
  const { Title } = Typography;
  const { t } = useTranslation();

  return (
    <div>
      <InvestiGationTour />
      <Title level={3} className="ks_lbl_titl">{t('Investigation')}</Title>
      <div className="taskBoard">
        <Taskboard />
      </div>
    </div>
  );
};

export default Investigation;
