import React from 'react';
import { Collapse } from 'antd';

export default function Collapse3({
  // eslint-disable-next-line react/prop-types
  content1, header1,
}) {
  const { Panel } = Collapse;

  function callback(key) {
    console.log(key);
  }

  return (
    <>
      <Collapse
        className="collapse clps"
        style={{ width: '-webkit-fill-available' }}
        onChange={(value) => callback(value)}
        ghost
      >
        <Panel className="ant-collapse-headerr" header={header1} key="1">
          {content1}
        </Panel>
        <hr />
      </Collapse>
    </>
  );
}
