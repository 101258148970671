export const getGRNItems = `
query getGRNItems($workShopId: String!, $limit: Int!, $nextToken: String) {
  getGRNItems(workShopId: $workShopId, limit: $limit, nextToken: $nextToken) {
    grnInvoice {
      description
      grnInvoiceId
      image
      invoiceNumber
      minIssueQuantity
      minOrderQty
      quantity
      units
      workShopId
      searching
    }
    nextToken
  }
}
`;

export const updateGRNInvoiceItemMutation = `
mutation updateGRNInvoiceItem($grnInvoiceItem: GRNInvoiceItem) {
  updateGRNInvoiceItem(newItem: $grnInvoiceItem) {
      description
      grnInvoiceId
      image
      invoiceNumber
      minIssueQuantity
      minOrderQty
      quantity
      units
      workShopId
  }
}
`;

export const searchGRNItem = `
  query searchGRNItem($workShopId: String!, $searching: String!) {
    searchGRNItem(workShopId: $workShopId, searching: $searching) {
      description
      grnInvoiceId
      image
      invoiceNumber
      minIssueQuantity
      minOrderQty
      quantity
      units
      workShopId
    }
  }
`;
