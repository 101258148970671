import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  Col,
  DatePicker,
  Input,
  message,
  Row, Typography,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TaskBoardItemStatus } from '../../../enum/TaskboardTypes';
import TaskBoardCol from './TaskBoardCol';
import {
  getAllBooking,
} from './actions';
import { useAppSelector } from '../../../redux/hooks';
import Loading from '../../../Loading';
import NoData from '../../../components/NoData';
import {
  generateFromDate, generateToDate, getFromDate, getToDate,
} from './viewJob/common';

const defaultItems = {
  [TaskBoardItemStatus.TO_DO]: [],
  [TaskBoardItemStatus.IN_PROGRESS]: [],
  [TaskBoardItemStatus.QC]: [],
  [TaskBoardItemStatus.PENDING_PAYMENT]: [],
};

const { Title } = Typography;

type PropType = {
  workShopId: string,
}

function JobTaskBoard({ workShopId }: PropType) {
  const [itemsByStatus, setItemsByStatus] = useState<any>(defaultItems);
  const [searchData, setSearchData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<any>(true);
  const userId = useAppSelector((state) => state.userData.userId);
  const [fromDate, setFromDate] = useState<any>(generateFromDate);
  const [toDate, setToDate] = useState<any>(generateToDate);
  const { t } = useTranslation();
  const [isDataAvailable, setDataAvailable] = useState<boolean>(false);

  const getAllBookings = async () => {
    if (fromDate && toDate) {
      if (fromDate < toDate) {
        getAllBooking(workShopId, userId, fromDate, toDate).then((res: any) => {
          const data: any = {};
          const bookings: any[] = [];
          if (res && res.data) {
            res.data.getBookings.map((item: any) => {
              const temp = { ...item, id: item.sortKey };
              bookings.push(temp);
              return null;
            });
            setSearchData(bookings);
            Object.values(TaskBoardItemStatus).forEach((item: TaskBoardItemStatus) => {
              data[item] = bookings.filter((booking) => booking.status === item);
            });
            setItemsByStatus(data);
            setLoading(false);
          }
          setDataAvailable(!!res.data.getBookings.length);
        });
      } else {
        message.error('Please select a valid date range');
      }
    }
  };
  const onSearch = (e: any) => {
    const keyword: string = e.target.value;
    let res: any[] = searchData;
    if (keyword !== '') {
      res = searchData.filter((item: any) => {
        let flag = false;
        if (item.plateNumber
            && item.plateNumber.toUpperCase().includes(keyword.toUpperCase())) {
          flag = true;
        }
        if (item.ownerName
            && item.ownerName.toUpperCase().includes(keyword.toUpperCase())) {
          flag = true;
        }
        return flag;
      });
    }
    const data: any = {};
    Object.values(TaskBoardItemStatus)
      .forEach((item: TaskBoardItemStatus) => {
        data[item] = res.filter((booking: any) => booking.status === item);
      });
    setItemsByStatus(data);
  };

  const taskBoardContent = () => {
    if (isLoading) {
      return (<Loading />);
    }
    if (!isDataAvailable) {
      return (<NoData />);
    }
    return Object.values(TaskBoardItemStatus)
      .map((status: TaskBoardItemStatus, index: number) => {
        const key = `${status}-${index}`;
        return (
          <TaskBoardCol
            generatedKey={key}
            key={key}
            status={status}
            items={itemsByStatus[status]}
            getAllBookings={getAllBookings}
          />
        );
      });
  };
  useEffect(() => {
    if (workShopId) {
      getAllBookings().finally();
    }
  }, [workShopId, fromDate, toDate]);

  return (
    <div>
      <div>
        <Title level={3} className="ks_lbl_titl">{t('Job in progress')}</Title>
      </div>
      <Row className="top-row">
        <Col xs={3} sm={3} md={3} lg={10} xl={10} style={{ marginLeft: '2vw' }}>
          <div style={{ float: 'right', paddingRight: '4px' }}>
            <h3 style={{ color: '#8c8c8c' }}>{`${t('From')}:`}</h3>
          </div>
        </Col>
        <Col xs={6} sm={6} md={6} lg={3} xl={3}>
          <DatePicker
            onChange={(e: any) => setFromDate(getFromDate(e))}
            className="ks_drp_vcl-ty selection_syl"
            placeholder={moment().add(-7, 'days').format('YYYY-MM-DD')}
          />
        </Col>
        <Col xs={2} sm={2} md={2} lg={1} xl={1}>
          <div style={{ float: 'right', paddingRight: '4px' }}>
            <h3
              style={{ color: '#8c8c8c' }}
            >
              {`${t('To')}:`}
            </h3>
          </div>
        </Col>
        <Col xs={6} sm={6} md={6} lg={3} xl={3}>
          <DatePicker
            onChange={(e: any) => setToDate(getToDate(e))}
            className="ks_drp_vcl-ty selection_syl"
            placeholder={moment().format('YYYY-MM-DD')}
          />
        </Col>
        <Col xs={5} sm={5} md={5} lg={5} xl={5} offset={1}>
          <Input
            style={{ width: '18rem', marginLeft: '-18px' }}
            onChange={onSearch}
            addonBefore={<SearchOutlined />}
            placeholder={t('Search')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <DragDropContext>
            <div className="task-board-root">
              <div className="task-board-content">
                {taskBoardContent()}
              </div>
            </div>
          </DragDropContext>
        </Col>
      </Row>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
  };
}

export default connect(mapStateToProps, {})(JobTaskBoard);
