import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { addUpdateInventoryItemMutation, getItems, searchItem } from '../../../graphql-queries/inventory';
import {
  APIS, deleteApi, getApi,
} from '../../../utils/apiUtils';

export const addItems = async (inventoryItem) => {
  try {
    return await API.graphql(graphqlOperation(addUpdateInventoryItemMutation, { inventoryItem }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getSignedUrl = async (key, method, contentType = undefined) => {
  try {
    const contentTypeP: string = contentType ? `&contentType=${contentType}` : '';
    return await getApi(APIS.INVENTORY_API, `signed-url?key=${key}&method=${method}${contentTypeP}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const deleteImage = async (key: string) => {
  try {
    return await deleteApi(APIS.INVENTORY_API, `delete-image?key=${key}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getAllInventory = async (workShopId, limit, nextToken) => {
  try {
    return await API.graphql(graphqlOperation(getItems, { workShopId: `${workShopId}`, limit: `${limit}`, nextToken }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const searchInventory = async (workShopId, keyword) => {
  try {
    return await API.graphql(graphqlOperation(searchItem, { workShopId: `${workShopId}`, searching: `${keyword}` }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const deleteInventory = async (workShopId: string, sortKey: string) => {
  try {
    return await deleteApi(APIS.INVENTORY_API, `delete-inventory?workShopId=${workShopId}&sortKey=${sortKey}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};
