import React, { useEffect, useState } from 'react';
import {
  Card, Typography, Tag, Row, Col, Progress, Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import { TaskBoardItemStatus } from '../../../enum/TaskboardTypes';
import ViewTask from './viewJob/ViewJob';
import BaseTooltip from '../../../components/BaseTooltip';
import { useAppSelector } from '../../../redux/hooks';
import './jobTaskBoard.scss';
import { getColourByBookingType } from '../../../utils/commonUtils';
import { capitalizeFirstLetter, timeConvert } from './viewJob/common';

const { Title } = Typography;

export function TaskBoardItemCard(props: any) {
  const {
    item, status, getAllBookings, generatedKey,
  } = props;
  const { t } = useTranslation();
  const [colour, setColour] = useState('orange');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [progressColour, setProgressColour] = useState('#27AE60');
  const userId = useAppSelector((state) => state.userData.userId);
  const bookingStdHrsMin = item ? item.bookingStdHrsMin : 0;

  const getProgressColour = () => {
    if (item.progress < 33) {
      setProgressColour('#EC0F0F');
    } else if (item.progress < 66) {
      setProgressColour('#E2B93B');
    } else {
      setProgressColour('#27AE60');
    }
  };

  const onOpen = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    setColour(getColourByBookingType(item.jobType.toLowerCase()));
  }, [item]);

  useEffect(() => {
    getProgressColour();
  }, [item.progress]);

  return (
    <Card
      className="styled-card"
      style={{ height: '177px' }}
      size="small"
      key={generatedKey}
      title={(
        <BaseTooltip overlay={item.title}>
          <span>
            <Title className="task-board-item-card-title" level={5} ellipsis={{ rows: 2 }}>
              {item.plateNumber}
            </Title>
          </span>
        </BaseTooltip>
      )}
      extra={(
        <Title
          className="task-board-item-card-title-time"
          level={3}
          ellipsis={{ rows: 2 }}
        >
          {(status === TaskBoardItemStatus.TO_DO
          || status === TaskBoardItemStatus.IN_PROGRESS)
              && (
              <Tag
                className="jobStsTag-itm-crd-time"
                style={{ fontSize: '12px' }}
              >
                {`${timeConvert(bookingStdHrsMin)}`}
              </Tag>
              )}
        </Title>
      )}
    >
      <BaseTooltip overlay={item.description}>
        <Row>
          <Col span={15} style={{ textAlign: 'left', marginLeft: 4 }}>{item.ownerName}</Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            {moment(item.selectedDate).format('DD/MM/YYYY')}
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <Typography.Paragraph type="secondary" ellipsis={{ rows: 2 }}>
              {item.description}
            </Typography.Paragraph>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Tag className="jobStsTag-itm-crd" color={colour}>
              {t(capitalizeFirstLetter(item.jobType))}
            </Tag>
          </Col>
          <Col span={6}>
            <Button
              style={{
                backgroundColor: '#131A54', color: 'white', borderRadius: '8px', height: 25, width: 60, paddingTop: 0,
              }}
              onClick={onOpen}
            >
              {t('View')}
            </Button>
          </Col>

        </Row>
        {item.status === TaskBoardItemStatus.IN_PROGRESS
        && <Progress percent={item.progress || 0} showInfo={false} strokeColor={progressColour} />}
      </BaseTooltip>
      <Row style={{ float: 'right' }}>
        {userId && isModalVisible && (
        <ViewTask
          status={status}
          item={item}
          key={generatedKey}
          generatedKey={generatedKey}
          currentStatus={status}
          getAllBookings={getAllBookings}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
        )}
      </Row>
    </Card>
  );
}

function mapStateToProps(state) {
  return {
    userRoles: state.userData.userRoles,
  };
}

export default connect(mapStateToProps, {})(TaskBoardItemCard);
