import React from 'react';
import Joyride from 'react-joyride';
import { useTranslation } from 'react-i18next';
import TOUR from '../../configs/tour-consfig';

const MainTour = () => {
  const { t } = useTranslation();
  const tourConfig = [
    {
      title: t('heyThere'),
      content:
        (
          <>
            <p>{t('welcomeMessage')}</p>
            <p>{t('areYouReady')}</p>
          </>
        ),
      placement: 'center',
      locale: { skip: t('skipButton') },
      target: '.site-layout',
    },
    {
      content: t('selectLanguageHere'),
      target: '.language-button',
    },
    {
      title: t('viewNotification'),
      content: t('viewNotificationHere'),
      target: '.notification-button',
    },
    {
      content: t('youAreonDashboard'),
      target: '.dashboard-container',
    },
  ];

  const isOpen = TOUR;

  return (
    <div>
      <Joyride
        run={isOpen}
        continuous
        callback={() => null}
        steps={tourConfig}
        showSkipButton
        styles={{
          options: {
            primaryColor: '#EB5757',
            zIndex: 10000,
          },
        }}
      />
    </div>
  );
};

export default MainTour;
