import moment from 'moment';

export const vehicleDetails = (t: Function, item: any) => [
  {
    title: `${t('Vehicle Registration Number')}`,
    value: item?.plateNumber,
  },
  {
    title: `${t('Vehicle Make')}`,
    value: item?.make,
  },
  {
    title: `${t('Vehicle Model')}`,
    value: item?.model,
  },
  {
    title: `${t('Mileage')}`,
    value: item?.mileage,
  },
  {
    title: `${t('Manufacture Year')}`,
    value: item?.manufactureYear,
  },
  {
    title: `${t('Year of Register')}`,
    value: item?.registerYear,
  },
  {
    title: `${t('Engine Number')}`,
    value: item?.engineNumber,
  },
  {
    title: `${t('Chassis Number')}`,
    value: item?.chassisNumber,
  },
  {
    title: `${t('Color Code')}`,
    value: item?.colourCode,
  },
  {
    title: `${t('Tyre Size')}`,
    value: item?.tireSize,
  },
  {
    title: `${t('Fuel Type')}`,
    value: item?.fuelType,
  },
  {
    title: `${t('Insurance Company')}`,
    value: item?.insuranceCompany,
  },
  {
    title: `${t('Insurance Expiry Date')}`,
    value: moment(item?.insuranceRenevalDate).format('Do MMMM YYYY'),
  },
  {
    title: `${t('Battery Replacement Date')}`,
    value: moment(item?.batteryReplacementDate).format('Do MMMM YYYY'),
  },
];

export const ownerDetails = (t: Function, item: any) => [
  {
    title: `${t('Name')}`,
    value: item?.ownerName,
  },
  {
    title: `${t('Contactnumber')}`,
    value: item?.ownerTel,
  },
  {
    title: `${t('Email')}`,
    value: item?.ownerEmail,
  },
];

export const userDetails = (t: Function, item: any) => [
  {
    title: `${t('Name')}`,
    value: item?.userName,
  },
  {
    title: `${t('Contactnumber')}`,
    value: item?.userTel,
  },
  {
    title: `${t('Email')}`,
    value: item?.userEmail,
  },
];

export const QualityControlDetails = (t: Function, job: any) => [
  {
    title: `${t('Description')}`,
    value: job?.description,
  },
  {
    title: `${t('Reason')}`,
    value: job?.qcComment,
  },
];
