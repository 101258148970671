/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import {
  Row, Form, Input, Button, Card, message,
} from 'antd';
import { Auth } from 'aws-amplify';

export const ForgetPasswordPage = () => {
  const [form] = Form.useForm();
  const [requestNewPassword, setRequestNewPassword] = useState(false);

  const onLogin = async () => {
    const {
      username, resetCode, newPassword, newConfirmPassword,
    } = form.getFieldsValue();
    if (newPassword !== newConfirmPassword) {
      message.error('new password and confirm password not match');
    } else {
      Auth.forgotPasswordSubmit(username, resetCode, newPassword).then(() => {
        message.success('Account rested successfully');
        window.location.href = '/login';
      }).catch((error) => {
        message.error(error.message || 'something went wrong please try again later');
      });
    }
  };

  const sendResetCode = () => {
    const { username } = form.getFieldsValue();
    Auth.forgotPassword(username).then(() => {
      message.success('Reset code send!');
      setRequestNewPassword(true);
    }).catch((error) => {
      message.error(error.message || 'something went wrong please try again later');
    });
  };

  return (
    <div className="ks_frm ks_frm_lgn">
      <div>
        <Card bordered className="ks_crd ks_crd_lgn" style={{ height: '600px' }}>
          <Row style={{ paddingLeft: '5rem' }}>
            <img src="/assets/logo.png" className="ks_logo ks_logo_img_header_3" alt="logo" />
          </Row>
          <Row>
            <Form form={form} layout="vertical" requiredMark={false} onFinish={onLogin}>
              <Form.Item label="Username" className="ka_lbl_lgnfrm" name="username" rules={[{ required: true, message: 'Please input your username' }]}>
                <Input className="ks_int ks_int_lgnfrm" />
              </Form.Item>
              {requestNewPassword && (
              <>
                <Form.Item label="Reset Code" name="resetCode" className="ka_lbl_lgnfrm" rules={[{ required: true, message: 'Please input your reset cpde' }]}>
                  <Input className="ks_int ks_int_lgnfrm" />
                </Form.Item>
                <Form.Item
                  label="New Password"
                  className="ka_lbl_lgnfrm"
                  name="newPassword"
                  rules={[{
                    required: true,
                    message: 'Please input your password',
                  }, {
                    pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                    message: 'Password must be at least 8 characters and contain at least one number, one uppercase and one lowercase letter',
                  }]}
                >
                  <Input.Password className="ks_int ks_int_lgnfrm" />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  className="ka_lbl_lgnfrm"
                  name="newConfirmPassword"
                  rules={[{
                    required: true,
                    message: 'Please input confirm password',
                  }, ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('The two passwords that you entered do not match'));
                    },
                  })]}
                >
                  <Input.Password className="ks_int ks_int_lgnfrm" />
                </Form.Item>
              </>
              )}
              <Form.Item>
                {!requestNewPassword && <Button type="primary" className="ka_btn_lgn" onClick={sendResetCode}>Get Reset Code</Button>}
                {requestNewPassword && <Button type="primary" className="ka_btn_lgn" htmlType="submit">Reset Account</Button>}
              </Form.Item>
            </Form>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default ForgetPasswordPage;
