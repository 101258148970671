import Amplify from 'aws-amplify';
import { CONFIG } from '../configs/app-config';
import { APIS } from './apiUtils';

// eslint-disable-next-line import/prefer-default-export
export function awsAmplifyApiInit() {
  const { baseApiUrl } = CONFIG;

  Amplify.configure({
    API: {
      endpoints: [
        {
          name: APIS.USER_API,
          endpoint: `${baseApiUrl}/user-api/`,
        },
        {
          name: APIS.BOOKING_API,
          endpoint: `${baseApiUrl}/booking-api/`,
        },
        {
          name: APIS.CONFIG_API,
          endpoint: `${baseApiUrl}/config-api/`,
        },
        {
          name: APIS.INVENTORY_API,
          endpoint: `${baseApiUrl}/inventory-api/`,
        },
        {
          name: APIS.GRN_API,
          endpoint: `${baseApiUrl}/grn-api/`,
        },
        {
          name: APIS.JOB_CREATION_API,
          endpoint: `${baseApiUrl}/job-creation-api/`,
        },
        {
          name: APIS.CHECK_IN_API,
          endpoint: `${baseApiUrl}/check-in-api/`,
        },
        {
          name: APIS.WORKSHOP_API,
          endpoint: `${baseApiUrl}/workshop-api/`,
        },
        {
          name: APIS.EMAIL_API,
          endpoint: `${baseApiUrl}/email-api/`,
        },
        {
          name: APIS.FINANCE_API,
          endpoint: `${baseApiUrl}/finance-api/`,
        },
        {
          name: APIS.VEHICLE_API,
          endpoint: `${baseApiUrl}/vehicle-api/`,
        },
        {
          name: APIS.GRN_API,
          endpoint: `${baseApiUrl}/grn-api/`,
        },
      ],
    },
  });
}
