/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  Col,
  Drawer,
  Row,
  Tag,
  Typography,
  Tabs,
  Select,
  message,
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PRIORITY } from './TaskboardTypes';
import './taskboard.scss';
import MakeCheckIn from '../components/MakeCheckIn';
import { updateBooking } from '../action';

export const ViewTask = (props: any) => {
  const { Title } = Typography;
  const {
    item, status, getAllCheckin, configData, isModalVisible, setIsModalVisible,
  } = props;
  const { t } = useTranslation();
  const [colour, setColour] = useState('orange');
  const [jobTypeOptions, setJobTypeOptions] = useState<any[]>([]);

  const bookingDetails = [
    {
      title: `${t('date')}`,
      value: moment(item?.selectedDate).format('MMMM Do YYYY'),
    },
    {
      title: `${t('time')}`,
      value: moment(item?.selectedTime).format('h:mm a'),
    },
    {
      title: `${t('Plate Number')}`,
      value: item?.plateNumber,
    },
  ];

  const vehicleDetails = [
    {
      title: `${t('Vehicle Registration Number')}`,
      value: item?.plateNumber,
    },
    {
      title: `${t('Vehicle Make')}`,
      value: item?.make,
    },
    {
      title: `${t('Vehicle Model')}`,
      value: item?.model,
    },
    {
      title: `${t('Mileage')}`,
      value: item?.mileage,
    },
    {
      title: `${t('Manufacture Year')}`,
      value: item?.manufactureYear,
    },
    {
      title: `${t('Year of Register')}`,
      value: item?.registerYear,
    },
    {
      title: `${t('Engine Number')}`,
      value: item?.engineNumber,
    },
    {
      title: `${t('Chassis Number')}`,
      value: item?.chassisNumber,
    },
    {
      title: `${t('Color Code')}`,
      value: item?.colourCode,
    },
    {
      title: `${t('Tyre Size')}`,
      value: item?.tireSize,
    },
    {
      title: `${t('Fuel Type')}`,
      value: item?.fuelType,
    },
    {
      title: `${t('Insurance Company')}`,
      value: item?.insuranceCompany,
    },
    {
      title: `${t('Insurance Expiry Date')}`,
      value: moment(item?.insuranceRenevalDate).format('Do MMMM YYYY'),
    },
    {
      title: `${t('Battery Replacement Date')}`,
      value: moment(item?.batteryReplacementDate).format('Do MMMM YYYY'),
    },
  ];

  const ownerDetails = [
    {
      title: `${t('Name')}`,
      value: item?.ownerName,
    },
    {
      title: `${t('Contact Number')}`,
      value: item?.ownerTel,
    },
    {
      title: `${t('Email')}`,
      value: item?.ownerEmail,
    },
  ];

  const userDetails = [
    {
      title: `${t('Name')}`,
      value: item?.userName,
    },
    {
      title: `${t('Contact Number')}`,
      value: item?.userTel,
    },
    {
      title: `${t('Email')}`,
      value: item?.userEmail,
    },
  ];

  const getPriority = () => {
    let tempCol = colour;
    if (PRIORITY.REPAIR === item.jobType) {
      tempCol = '#0F8B8D';
    } else if (PRIORITY.BREAK_DOWN === item.jobType) {
      tempCol = '#595CFF';
    } else if (PRIORITY.SERVICE === item.jobType) {
      tempCol = '#4EDCFF';
    } else if (PRIORITY.QUICK_JOBS === item.jobType) {
      tempCol = '#EC2556';
    } else if (PRIORITY.ACCIDENT === item.jobType) {
      tempCol = '#FF7A00';
    } else {
      tempCol = '#EC0F0F';
    }
    setColour(tempCol);
  };

  const onSelectJob = async (value: any) => {
    const obj = {
      ...item,
      jobType: value,
    };

    await updateBooking(obj).then(() => {
      message.destroy();
      message.success(`Successfully updated Job Type to ${value}`);
      getAllCheckin();
    }).catch(() => {
      message.destroy();
      message.error('Failed to update Job Type');
    });
  };

  useEffect(() => {
    if (configData) {
      setJobTypeOptions(configData?.jobTypes);
    }
  }, [configData]);

  useEffect(() => {
    getPriority();
  }, [item]);

  const onClose = () => {
    setIsModalVisible(false);
  };

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <>
      <Drawer
        title={(
          <Row>
            <Col span={9}>
              {`${t('Vehicle Number')} - ${item.plateNumber}`}
            </Col>
            <Col span={3} className="tag" style={{ marginTop: -3 }}>
              <Tag
                style={{
                  width: 100,
                  height: 30,
                  textAlign: 'center',
                  alignSelf: 'center',
                  borderRadius: '8px',
                  paddingTop: 3,
                  fontSize: 15,
                  fontWeight: 'lighter',
                }}
                color={colour}
              >
                {t(item.jobType)}
              </Tag>
            </Col>
          </Row>
        )}
        placement="right"
        width={window.innerWidth > 1001 ? 1000 : window.innerWidth - 2}
        onClose={onClose}
        visible={isModalVisible}
      >
        <Tabs defaultActiveKey="1" type="card" animated>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-title">{t('Make a Check-In')}</Title>
              </span>
            )}
            key="1"
          >
            <Row className="tab-row">
              {item.jobType ? (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title" span={12}>{t('Job Type')}</Col>
                    <Col className="col-value job-selection" span={12}>
                      <Select
                        className="ks_drp_vcl-ty selection_syl"
                        defaultValue={item.jobType}
                        options={jobTypeOptions}
                        onSelect={onSelectJob}
                      />
                    </Col>
                  </Row>
                </Col>
              ) : null}
              {bookingDetails.map((row) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{capitalizeFirstLetter(row?.value)}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
            <Row className="tab-row">
              <Col span={24}>
                <MakeCheckIn
                  item={item}
                  status={status}
                  onClose={onClose}
                  getAllCheckin={getAllCheckin}
                />
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-title">{t('Vehicle Details')}</Title>
              </span>
            )}
            key="2"
          >
            <Row className="tab-row">
              {vehicleDetails.map((row) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{row?.value}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-title">{t('Owner Details')}</Title>
              </span>
            )}
            key="3"
          >
            <Row className="tab-row">
              {ownerDetails.map((row) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{row?.value}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <span>
                <Title level={5} className="drawr-title">{t('User Details')}</Title>
              </span>
            )}
            key="4"
          >
            <Row className="tab-row">
              {userDetails.map((row) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col className="col-title" span={12}>{row?.title}</Col>
                    <Col className="col-value" span={12}>{row?.value}</Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

function mapStateToProps(state) {
  return {
    configData: state.userData.configData,
  };
}

export default connect(mapStateToProps, {})(ViewTask);
