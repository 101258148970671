/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import TaskBoardItemCard from './TaskBoardItemCard';
import './taskboard.scss';
import { TypeOfHistory } from './TaskboardTypes';

function TaskBoardCol({
  items,
  historyType,
  getAllHistoryBookings,
}: any) {
  const [historyTitle, setHistoryTitle] = useState<string>('');
  const { t } = useTranslation();
  const selectTitle = () => {
    let title = '';
    if (historyType === TypeOfHistory.CASH) {
      title = `${t('Cash Released')}`;
    } else if (historyType === TypeOfHistory.CREDIT) {
      title = `${t('Credit Released')}`;
    } else if (historyType === TypeOfHistory.REJECTED) {
      title = `${t('Rejected Bookings')}`;
    }
    setHistoryTitle(`${title} (${items.length})`);
  };

  useEffect(() => {
    if (historyType) {
      selectTitle();
    }
  }, [historyType, items]);
  return (
    <Card
      className="task-board-col-root"
      title={historyTitle}
    >
      <Droppable droppableId={historyType}>
        {(provided) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div className="droppable-root" ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {/* eslint-disable-next-line no-shadow */}
                {(provided, snapshot) => (
                  <div
                    key={item.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <TaskBoardItemCard
                      item={item}
                      historyType={historyType}
                      isDragging={snapshot.isDragging}
                      getAllHistoryBookings={getAllHistoryBookings}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Card>
  );
}

export default TaskBoardCol;
