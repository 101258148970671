/* eslint-disable max-len */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { connect } from 'react-redux';
import PaymentStatus from './components/PaymentStatus';
import MethodOfPayments from './components/MethodOfPayments';
import BookingIncome from './components/BookingIncome';
import IncomeRepair from './components/IncomeRepair';
import EstimateAndReceived from './components/EstimateAndReceived';
import IncomeStatement from './components/IncomeStatement';

export const Finance = () => (
  <div>

    <div style={{
      width: 'auto', height: 'auto', marginLeft: '50px', marginRight: '50px',
    }}
    >
      <IncomeRepair />
    </div>
    <div style={{
      width: 'auto', height: 'auto', marginLeft: '50px', marginRight: '50px',
    }}
    >
      <PaymentStatus />
    </div>
    <div style={{
      width: 'auto', height: 'auto', marginLeft: '50px', marginRight: '50px',
    }}
    >
      <MethodOfPayments />
    </div>
    <div style={{
      width: 'auto', height: 'auto', marginLeft: '50px', marginRight: '50px',
    }}
    >
      <EstimateAndReceived />
    </div>
    <div style={{
      width: 'auto', height: 'auto', marginLeft: '50px', marginRight: '50px',
    }}
    >
      <BookingIncome />
    </div>
    <div style={{
      width: 'auto', height: 'auto', marginLeft: '50px', marginRight: '50px',
    }}
    >
      <IncomeStatement />
    </div>
  </div>
);

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
  };
}

export default connect(mapStateToProps, {})(Finance);
