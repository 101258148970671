import React, { useEffect, useState } from 'react';
import {
  Button, Col, message, Popover, Row, Table,
} from 'antd';
import { connect } from 'react-redux';
import ToDoInProgressColumn from '../columns/ToDoInProgressColumn';
import { updateAssigneeJobs, updateBooking } from '../../actions';
import { getFormattedJobs } from '../../../../../utils/tableUtil';
import { BOOKING_TYPES } from '../../../../../enum/BookingStatus';
import { getJobCount } from '../common';
import { JOB_TYPES } from '../../../../../enum/ConfigurationData';
import { USER_ROLES } from '../../../../../enum/User';
import { hasRole } from '../../../../../utils/commonUtil';

type ToDoInProgressType = {
  userRoles: any[],
  getJobs: Function,
  technicianList: any[],
  item: any,
  jobList: any[],
  setJobList: Function,
  getAllBookings: Function,
  configData: any,
  t: Function,
  status: BOOKING_TYPES,
  workShopId: string,
  onClose: Function
}

const ToDoInProgress = ({
  userRoles,
  getJobs,
  technicianList,
  item,
  jobList,
  setJobList,
  getAllBookings,
  configData,
  t,
  status,
  workShopId,
  onClose,
}: ToDoInProgressType) => {
  const [formattedJobList, setFormattedJobList] = useState<any[]>([]);
  const [isSubmitButtonEnable, setSubmitButtonEnable] = useState<boolean>(false);
  const [submitType, setSubmitType] = useState<BOOKING_TYPES>(BOOKING_TYPES.TO_DO);
  const [popoverContent, setPopoverContent] = useState<string>('');
  const { currency = '', languageCode = '' }: any = configData?.currency;

  const selectTechnician = (assigneeId: any, job: any) => {
    const [technician] = technicianList.filter((tItem: any) => tItem.employeeId === assigneeId);
    const { bookingId, sortKey } = job;
    const assignerId = technician.employeeId;
    const assignerName = technician.name;
    updateAssigneeJobs(bookingId, sortKey, assignerId, assignerName).then((res: any) => {
      getJobs();
      if (res) {
        message.destroy();
        message.success(`Job assigned to ${assignerName} successfully`).then();
      }
    }).catch(() => {
      message.error('Error when getting technician list').then();
    });
  };

  const dataColumn = ToDoInProgressColumn(
    t,
    userRoles,
    getJobs,
    selectTechnician,
    technicianList,
    item,
    jobList,
    setJobList,
    getAllBookings,
  );
  const isQuickJob = JOB_TYPES[JOB_TYPES.QuickJobs].toString().toLowerCase();
  let isQuickBooking;
  if (item.jobType === isQuickJob) {
    isQuickBooking = BOOKING_TYPES.PENDING_PAYMENT;
  } else {
    isQuickBooking = BOOKING_TYPES.QC;
  }

  const setSubmitButtonVisibility = (sType: BOOKING_TYPES) => {
    let flag: boolean = false;
    const isToDo: boolean = sType === BOOKING_TYPES.TO_DO;

    let popMessage: string = isToDo ? 'Submit to In-Progress' : `Submit to ${isQuickBooking}`;

    const {
      qcReadyCount,
      inProgressCount,
      qcPassCount,
      rejectedCount,
    } = getJobCount(jobList);

    const totalJobCount = jobList.length - rejectedCount;
    const inProgress = inProgressCount + qcPassCount + qcReadyCount;
    const qc = qcReadyCount + qcPassCount;

    if (isToDo) {
      if (inProgress > 0) {
        flag = true;
      } else {
        popMessage = t('To Submit there should be at least one job started or done');
      }
    } else if (qc === totalJobCount) {
      flag = true;
    } else {
      popMessage = t('All jobs need to be completed to proceed');
    }

    setSubmitButtonEnable(flag);
    setPopoverContent(popMessage);
  };
  const submit = async () => {
    const isToDo: boolean = submitType === BOOKING_TYPES.TO_DO;
    const updatedBooking = {
      ...item,
      workShopId,
      progress: isToDo ? 0 : 100,
      status: isToDo ? BOOKING_TYPES.IN_PROGRESS : isQuickBooking,
      updatedTime: new Date(),
    };
    const {
      qcReadyCount,
      qcPassCount,
      rejectedCount,
    } = getJobCount(jobList);

    if (!isToDo || qcReadyCount > 0) {
      const totalJobCount = jobList.length - rejectedCount;
      const qc = qcReadyCount + qcPassCount;
      updatedBooking.progress = Math.trunc((qc / totalJobCount) * 100);
    }
    message.loading(`Moving booking to ${updatedBooking.status}`);
    await updateBooking(updatedBooking);
    message.destroy();
    message.success('Booking moved successfully');
    onClose(true);
  };

  const getBookingType = () => {
    let sType: BOOKING_TYPES = status;
    if (status === BOOKING_TYPES.TO_DO) {
      const { qcReadyCount, rejectedCount, qcPassCount } = getJobCount(jobList);
      const totalJobCount = jobList.length - rejectedCount;
      if (qcReadyCount + qcPassCount === totalJobCount) {
        sType = BOOKING_TYPES.IN_PROGRESS;
      }
    }
    setSubmitType(sType);
    setSubmitButtonVisibility(sType);
  };

  useEffect(() => {
    if (jobList.length) {
      setFormattedJobList(getFormattedJobs(jobList, currency, languageCode));
      getBookingType();
    }
  }, [jobList]);

  return (
    <>
      <Row>
        <header className="tableTopic">{t('Total Estimation')}</header>
      </Row>
      <Row className="tab-row">
        <Col span={24}>
          <Table
            columns={dataColumn}
            dataSource={formattedJobList}
            pagination={false}
          />
        </Col>
      </Row>
      <Row className="tab-row">
        <Col offset={submitType === BOOKING_TYPES.TO_DO ? 18 : 17}>
          <Popover placement="topLeft" content={popoverContent} trigger="hover">
            <Button
              htmlType="submit"
              style={{
                borderRadius: '8px',
                marginRight: 5,
                marginTop: 55,
                background: '#131a54',
                color: 'white',
              }}
              onClick={submit}
              disabled={!isSubmitButtonEnable
                  || hasRole([USER_ROLES.TECHNICIAN, USER_ROLES.RECEPTIONIST], userRoles)}
            >
              {t(submitType === BOOKING_TYPES.TO_DO
                ? 'Submit to In-Progress' : `Submit to ${isQuickBooking}`)}
            </Button>
          </Popover>
        </Col>
      </Row>
    </>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    userRoles: state.userData.userRoles,
    workshop: state.userData.workshop,
    configData: state.userData.configData,
  };
}

export default connect(mapStateToProps, {})(ToDoInProgress);
