import React from 'react';
import {
  Button, Form, Row, Col,
  Typography, Input,
} from 'antd';

type propType = {
  form: any,
  onSubmitSalary: any,
  t: any,
  prev: any,
}

const SalaryForm = ({
  form, onSubmitSalary, t, prev,
}: propType) => {
  const { Title } = Typography;
  return (
    <Form
      form={form}
      layout="vertical"
      hideRequiredMark
      size="middle"
      onFinish={onSubmitSalary}
    >
      <Title level={3} className="step-title">{t('Salary')}</Title>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="accountHolderName"
            label={`${t('Account Holder Name')}`}
            rules={[{ required: true, message: `${t('Please Enter Account Holder Name')}` },
              { pattern: new RegExp(/^[a-zA-Z ]*$/), message: 'Input should contain only letters' }]}
          >
            <Input placeholder={t('Enter Account Holder Name')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="bank"
            label={`${t('Employee Bank')}`}
            rules={[{ required: true, message: `${t('Please Enter Employee Bank')}` }]}
          >
            <Input placeholder={t('Enter Employee Bank')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="branch"
            label={`${t('Branch')}`}
            rules={[{ required: true, message: `${t('Please Enter Branch')}` }]}
          >
            <Input placeholder={t('Enter The Branch')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="accountNumber"
            label={`${t('Account Number')}`}
            rules={[{ required: true, message: `${t('Please Enter Account Number')}` },
              { pattern: new RegExp(/^[0-9]*$/), message: 'Input should contain only numbers' }]}
          >
            <Input placeholder={t('Enter Account Number')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="basicSalary"
            label={`${t('Basic Salary')}`}
            rules={[{ required: true, message: `${t('Please Enter Basic Salary')}` },
              { pattern: new RegExp(/^[0-9]*$/), message: 'Input should be only numbers' }]}
          >
            <Input placeholder={t('Enter Basic Salary')} />
          </Form.Item>
        </Col>
      </Row>
      <Title level={5}>{t('Salary Details')}</Title>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="epfEmployeeContribution"
            label={`${t('EPF Employee Contribution')}`}
            rules={[{
              required: true,
              message: `${t('Please Enter EPF')}`,
            },
            {
              pattern: new RegExp(/^[0-9]*$/),
              message: 'Input should be only numbers',
            }]}
          >
            <Input placeholder={t('Enter EPF')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="etf"
            label={`${t('ETF Company Contribution')}`}
            rules={[{
              required: true,
              message: `${t('Please Enter ETF')}`,
            },
            {
              pattern: new RegExp(/^[0-9]*$/),
              message: 'Input should be only numbers',
            }]}
          >
            <Input placeholder={t('Enter ETF')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="epfCompanyContribution"
            label={`${t('EPF Company Contribution')}`}
            rules={[{
              required: true,
              message: `${t('Please Enter EPF')}`,
            }, {
              pattern: new RegExp(/^[0-9]*$/),
              message: 'Input should be only numbers',
            }]}
          >
            <Input placeholder={t('Enter EPF')} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Row>
            <Button className="employee-button add-employee-button" onClick={(value) => prev(value)}>{t('Back')}</Button>
          </Row>
        </Col>
        <Col span={12}>
          <Row style={{ float: 'right' }}>
            <Button htmlType="submit" className="employee-button add-employee-button">{t('Save')}</Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default SalaryForm;
