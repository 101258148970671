/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import {
  addWorkshop, getAllWorkshops, searchWorkshop, updateWorkshop,
} from '../../graphql-queries/workshop';
import {
  APIS, deleteApi, getApi, postApi,
} from '../../utils/apiUtils';

export const addWorkshopDetails = async (newWorkshop) => {
  message.loading('Saving, PLease Wait', 1);
  try {
    return await API.graphql(graphqlOperation(addWorkshop, newWorkshop));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const registerWorkshopAdmin = async (res) => {
  try {
    await postApi(APIS.USER_API, 'signup', {
      userName: res.userName,
      email: res.email,
      userRole: 'Workshop-Admin',
      workShopId: res.workShopId,
    });
  } catch (err) {
    message.destroy();
    message.error(err.message);
  }
};

export const getWorkshops = async (limit, nextToken) => {
  try {
    return await API.graphql(graphqlOperation(getAllWorkshops, { limit: `${limit}`, nextToken }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getSignedUrl = async (key, contentType) => {
  try {
    return await getApi(APIS.WORKSHOP_API, `workshop/signedurl?key=${key}&contentType=${contentType}`);
  } catch (err) {
    message.destroy();
    message.error(err.message);
  }
};

export const getImageUrl = async (key, contentType, method) => {
  try {
    return await getApi(APIS.WORKSHOP_API, `workshop/get-image-url?key=${key}&contentType=${contentType}&method=${method}`);
  } catch (err) {
    message.destroy();
    message.error(err.message);
  }
};

export const deleteImage = async (key: String) => {
  try {
    return await deleteApi(APIS.WORKSHOP_API, `workshop/delete-image?key=${key}`);
  } catch (err) {
    message.destroy();
    message.error(err.message);
  }
};

export const searchWorkshopList = async (keyword) => {
  try {
    return await API.graphql(graphqlOperation(searchWorkshop, { searching: `${keyword}` }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const updateWorkshopDetails = async (obj) => {
  try {
    // eslint-disable-next-line max-len
    return await API.graphql(graphqlOperation(updateWorkshop, { updateWorkshop: obj }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const addConfig = async (configData: Object) => {
  try {
    const res = await postApi(APIS.CONFIG_API, '/config', configData);
    message.destroy();
    message.success('Configurations successfully added');
    return res;
  } catch (err: any) {
    message.destroy();
    message.error("Something went wrong, couldn't add configurations");
    console.log(err);
    return null;
  }
};
