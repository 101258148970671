import { Tag } from 'antd';
import React from 'react';
import { JOB_STATUS } from '../../../../enum/JobStatus';

const drawerTableColumn = (t: Function) => [
  {
    title: `${t('Description')}`,
    dataIndex: 'description',
    key: 'description',
    width: '500px',
    render: (title: string, job: any) => ({
      props: {
        style: job.title && {
          background: 'rgba(135,130,254,0.25)',
          fontWeight: 'bold',
          borderRadius: '4px',
        },
        colSpan: job.title ? 7 : 1,
        rowSpan: 1,
      },
      children: <div>{job.description || job.title}</div>,
    }),
  },
  {
    title: `${t('price')}`,
    dataIndex: 'price',
    key: 'price',
    render: (text, objItem) => ({
      props: {
        style: objItem.title && {
          background: 'rgba(135,130,254,0.25)',
          fontWeight: 'bold',
          borderRadius: '4px',
        },
        colSpan: objItem.title ? 0 : 1,
        rowSpan: 1,
      },
      children: objItem.status !== JOB_STATUS.REJECTED
        ? <div>{text}</div>
        : (
          <div>
            <Tag
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                borderRadius: '8px',
                fontSize: 14,
                paddingLeft: 5,
                paddingRight: 5,
                marginLeft: 13,
                marginRight: 13,
              }}
              color="red"
            >
              Rejected
            </Tag>
          </div>
        ),
    }),
  },
];

export default drawerTableColumn;
