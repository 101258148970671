/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React, { useEffect, useState } from 'react';
import '../inventory.scss';
import {
  Drawer, Row, Col, Upload,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { getSignedUrl } from '../actions';
import { S3_METHODS } from '../../../../enum/ConfigurationData';
import { detectURLs } from '../../../../utils/commonUtil';

type PropType = {
  inventoryItem: any,
  isModalVisible: boolean,
  setModalVisible: Function
}

const ViewItem = ({
  inventoryItem,
  isModalVisible,
  setModalVisible,
}: PropType) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const { t } = useTranslation();

  const loadImages = () => {
    setFileList([]);
    if (inventoryItem.image) {
      inventoryItem.image.forEach(async (image: any, index: number) => {
        const imageUrlObj = {
          uid: index,
          name: `image_${index}`,
          status: 'done',
          url: image,
        };
        if (!detectURLs(image)) {
          const key = `${inventoryItem.workShopId}/${inventoryItem.sortKey}/${image}`;
          await getSignedUrl(key, S3_METHODS.get).then((imageUrl: any) => {
            imageUrlObj.name = image;
            imageUrlObj.url = imageUrl.url;
          }).catch((e:any) => console.error(e));
        }
        setFileList((prevData: any[]) => [...prevData, imageUrlObj]);
      });
    }
  };

  const getGoodsValue = (type: string) => {
    let generatedValue: string = '';
    if (inventoryItem) {
      const { item, value, unit = '' } = JSON.parse(inventoryItem[type]);
      generatedValue = `${item} - ${value}${unit}`;
    }
    return generatedValue;
  };

  const itemData = [
    {
      title: `${t('Part Number')}`,
      value: inventoryItem?.partNumber,
    },
    {
      title: `${t('Part Name')}`,
      value: inventoryItem?.partName,
    },
    {
      title: `${t('Supplier Information')}`,
      value: inventoryItem?.supplierInformation,
    },
    {
      title: `${t('Description / Details')}`,
      value: inventoryItem?.description,
    },
    {
      title: `${t('Item Type')}`,
      value: inventoryItem?.itemType,
    },
    {
      title: `${t('Other Names')}`,
      value: inventoryItem?.otherNames,
    },
    {
      title: `${t('Warehouse Type')}`,
      value: inventoryItem?.warehouseType,
    },
    {
      title: `${t('Bin Location')}`,
      value: inventoryItem?.binLocation,
    },
    {
      title: `${t('Goods In')}`,
      value: getGoodsValue('goodsIn'),
    },
    {
      title: `${t('Goods Out')}`,
      value: getGoodsValue('goodsOut'),
    },
    {
      title: `${t('Anticipated Usage')}`,
      value: inventoryItem?.anticipatedUsage,
    },
    {
      title: `${t('Warranty Details')}`,
      value: inventoryItem?.warrantyDetails,
    },
    {
      title: `${t('Min Order Quantity To Supplier')}`,
      value: inventoryItem?.minOrderQuantityToSupplier,
    },
    {
      title: `${t('Browse Image')}`,
      value: <Upload listType="picture-card" fileList={fileList} multiple disabled />,
    },
  ];

  const onClose = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    if (inventoryItem) {
      loadImages();
    }
  }, [inventoryItem]);

  return (
    <Drawer
      title={(
        <Row>
          <Col span={16}>
            {`${inventoryItem?.partName}`}
          </Col>
          <Col
            span={7}
            style={{
              textAlign: 'right',
              fontSize: 15,
              paddingRight: 20,
            }}
          />
        </Row>
        )}
      width={700}
      placement="right"
      onClose={onClose}
      visible={isModalVisible}
    >
      <Row className="tab-row">
        {itemData.map((row: any) => (
          <Col key={row.title} xs={24} sm={24} md={12} lg={12} xl={12}>
            <Row>
              <Col className="col-title" span={12}>{row?.title}</Col>
              <Col className="col-value" span={12}>{row?.value}</Col>
            </Row>
          </Col>
        ))}
      </Row>
    </Drawer>
  );
};

export default ViewItem;
