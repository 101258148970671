import { message } from 'antd';
import { CSV_TYPES } from '../enum/ConfigurationData';

function csvToArray(str, delimiter = ',') {
  const columns = str.slice(0, str.indexOf('\n')).split(delimiter);

  const data = str.slice(str.indexOf('\n') + 1).split('\n');

  return { columns, data };
}

const JobCreationColumns = [
  'Applicable Vehicle Make',
  'Applicable Vehicle Model',
  'Vehicle Category',
  'Check List Have (1) / Don?t Have (0)',
  'Description',
  'LH',
  'RH',
  'FRT',
  'RER',
  'Price',
  'Labour Rate Type',
  'Labour rate',
  'STD Hrs (min)',
  'Assistance Allowed (1) / Not Allowed (0)',
  'Main Tech Incentive',
  'Assistant Tech Incentive',
  'Job Category',
  'Main Tech Group 1',
  'Main Tech Group 2',
  'AssistantTech Group 2',
  'Active Status',
  'Tax 1 (VAT 8%)',
  'Tax 2 (NBT 2.5%)',
  'Tax 3 (Other)',
  'Income Account Name',
  'P & L',
  'Instructions / Notes',
  'Images (Hyper Link)',
  'Video (Hyper Link)',
];

const InventoryColumns = [
  'Item Type',
  'Part Number',
  'Part Name',
  'Supplier Information',
  'Description/Details',
  'Other Names',
  'Warehouse Type',
  'Bin Location',
  'Quantity type',
  'Goods In Type',
  'Goods In Value',
  'Goods In Value Unit',
  'Goods Out Type',
  'Goods Out Value',
  'Goods Out Value Unit',
  'Anticipated Usage',
  'Warranty Details',
  'Min Order Quantity To Supplier',
  'Browse Image',
];

const getColumn = (type: CSV_TYPES) => {
  let columns;
  switch (type) {
    case CSV_TYPES.JOB_CREATION:
      columns = JobCreationColumns;
      break;
    default:
      columns = InventoryColumns;
      break;
  }
  return columns;
};

const checkColumns = async (csvData: any, type: CSV_TYPES) => {
  let expectedColumns = getColumn(type);
  let csvColumns = csvData.columns;
  let missingColumns: any[] = [];
  let extraColumns: any[] = [];

  await csvData.columns.map((column) => {
    const col = column.replace(/(\r\n|\n|\r)/gm, '');
    if (expectedColumns.includes(col)) {
      expectedColumns = expectedColumns.filter((item) => item !== col);
      csvColumns = csvColumns.filter((item) => item.replace(/(\r\n|\n|\r)/gm, '') !== col);
    }
    missingColumns = expectedColumns;
    extraColumns = csvColumns;
    return col;
  });
  return { missingColumns, extraColumns };
};

const setResponseMessages = async (error) => {
  const { missingColumns, extraColumns } = error;
  if (missingColumns.length === 0 && extraColumns.length === 0) {
    return true;
  }
  if (missingColumns.length !== 0) {
    let err = '';
    await missingColumns.map((c) => {
      err += `${c}, `;
      return err;
    });
    message.destroy();
    message.warn(`
      ${err} Columns are missing.
    `);
    return false;
  }
  if (extraColumns.length !== 0) {
    let err = '';
    await extraColumns.map((c) => {
      err += `${c}, `;
      return err;
    });
    message.destroy();
    message.warn(`
      There are extra columns ${err}
    `);
    return false;
  }
  return false;
};

const csvValidationHandle = async (file: any, type: CSV_TYPES) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = async (e: any) => {
    try {
      const csvData = csvToArray(e.target?.result);
      const data = await checkColumns(csvData, type);
      const response = await setResponseMessages(data);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  };
  reader.onerror = (error) => {
    reject(error);
  };
  reader.readAsText(file);
});

export default csvValidationHandle;
