export const addEmployee = `
mutation addEmployee($contactNumber: String, $dob: AWSDateTime, $name: String, $nic: String, $role: String, $status: String, $address: String, $userName: String, $workShopId: String!, $employeeId: String!, $accountHolderName: String, $bank: String, $branch: String, $accountNumber: String, $basicSalary: String, $epfEmployeeContribution: Float, $epfCompanyContribution: Float, $etf: Float) {
  addEmployee(newEmployee: {contactNumber: $contactNumber, dob: $dob, name: $name, nic: $nic, role: $role, status: $status, address: $address, userName: $userName, workShopId: $workShopId, employeeId: $employeeId, accountHolderName: $accountHolderName, bank: $bank, branch: $branch, accountNumber: $accountNumber, basicSalary: $basicSalary, epfEmployeeContribution: $epfEmployeeContribution, epfCompanyContribution: $epfCompanyContribution, etf: $etf}) {

    contactNumber
    dob
    employeeId
    name
    nic
    role
    status
    userName
    workShopId
    address
    accountHolderName
    bank
    branch
    accountNumber
    basicSalary
    epfEmployeeContribution
    epfCompanyContribution
    etf
  }
}
`;

export const getAllJobAssigneeQuery = `
query getAllJobAssignee($workShopId: String!) {
  getAllJobAssignee(workShopId: $workShopId) {
      name
      role
      nic
      employeeId
      userName
      status
      dob
      contactNumber
      workShopId
      address
      accountHolderName
      bank
      branch
      accountNumber
      basicSalary
      epfEmployeeContribution
      epfCompanyContribution
      etf
    }
}
`;

export const getEmployees = `
query getEmployees($workShopId: String!, $limit: Int!, $nextToken: String) {
  getEmployees(workShopId: $workShopId, limit: $limit, nextToken: $nextToken) {
    employees {
      name
      role
      nic
      employeeId
      userName
      status
      dob
      contactNumber
      workShopId
      address
      accountHolderName
      bank
      branch
      accountNumber
      basicSalary
      epfEmployeeContribution
      epfCompanyContribution
      etf
    }
    nextToken
  }
}
`;

export const searchEmployee = `
  query searchEmployee($workShopId: String!, $searching: String!) {
    searchEmployee(workShopId: $workShopId, searching: $searching) {
      name
      role
      nic
      employeeId
      userName
      status
      dob
      contactNumber
      workShopId
      address
      accountHolderName
      bank
      branch
      accountNumber
      basicSalary
      epfEmployeeContribution
      epfCompanyContribution
      etf
    }
  }
`;

export const updateEmployeeMutation = `
  mutation updateEmployee($newEmployee: EmployeeInput) {
    updateEmployee(newEmployee: $newEmployee) {
      workShopId
      userName
      status
      contactNumber
      dob
      employeeId
      name
      nic
      role
      searching
      address
      accountHolderName
      bank
      branch
      accountNumber
      basicSalary
      epfEmployeeContribution
      epfCompanyContribution
      etf
    }
}
`;
