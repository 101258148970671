/* eslint-disable import/prefer-default-export */
export const getAllBookingHistory = `
query getAllBookingHistory($workShopId: String!, $date1: String!, $date2: String!) {
  getAllBookingHistory(workShopId: $workShopId, date1: $date1, date2: $date2,) {
    batteryType
    batteryReplacementDate
    chassisNumber
    colourCode
    engineNumber
    fuelType
    insuranceNumber
    insuranceRenevalDate
    insuranceCompany
    jobType
    licenseNumber
    licenseRenevalDate
    make
    manufactureYear
    model
    ownerEmail
    ownerName
    ownerTel
    plateNumber
    selectedDate
    selectedTime
    sortKey
    status
    tireSize
    userEmail
    userName
    userTel
    images
    qcImages
    bookingStdHrsMin
    assigneeName
    registerYear
    mileage
    paymentMethod
    bookingCompletedTime
  }
}
`;

export const getAllBookingHistoryUnauth = `
query getAllBookingHistory($workShopId: String!) {
  getAllBookingHistory(workShopId: $workShopId) {
    selectedDate
    selectedTime
    sortKey
    workShopId
    plateNumber
    ownerName
    status
    progress
    searching
  }
}
`;

export const getJobs = `
query getJobs($workShopId: String!, $bookingId: String!) {
  getJobs(workShopId: $workShopId, bookingId: $bookingId) {
    bookingId
    description
    plateNumber
    price
    qtyHours
    rate
    repairItem
    sortKey
    status
    qtyNo
  }
}
`;
