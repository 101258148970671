import moment from 'moment';

export const bookingDetails = (t: Function, item: any) => [
  {
    title: `${t('Job Type')}`,
    value: item?.jobType,
    key: 'jobType',
  },
  {
    title: `${t('Date')}`,
    value: moment(item?.selectedDate).format('MMMM Do YYYY'),
    key: 'selectedDate',
  },
  {
    title: `${t('Time')}`,
    value: moment(item?.selectedDate).format('h:mm a'),
    key: 'selectedDate',
  },
  {
    title: `${t('Assignee')}`,
    value: item?.assigneeName,
    key: 'assigneeName',
  },
];

export const vehicleDetails = (t: Function, item: any) => [
  {
    title: `${t('Vehicle Registration Number')}`,
    value: item?.plateNumber,
    key: 'plateNumber',
  },
  {
    title: `${t('Vehicle Make')}`,
    value: item?.make,
    key: 'make',
  },
  {
    title: `${t('Vehicle Model')}`,
    value: item?.model,
    key: 'model',
  },
  {
    title: `${t('Manufacture Year')}`,
    value: item?.manufactureYear,
    key: 'manufactureYear',
  },
  {
    title: `${t('Year of Register')}`,
    value: item?.registerYear,
    key: 'registerYear',
  },
  {
    title: `${t('Mileage')}`,
    value: item?.mileage,
    key: 'mileage',
  },
  {
    title: `${t('Color Code')}`,
    value: item?.colourCode,
    key: 'colourCode',
  },
  {
    title: `${t('Tyre Size')}`,
    value: item?.tireSize,
    key: 'tireSize',
  },
  {
    title: `${t('Battery Replacement Date')}`,
    value: moment(item?.batteryReplacementDate).format('MMMM Do YYYY'),
    key: 'batteryReplacementDate',
  },
  {
    title: `${t('Fuel Type')}`,
    value: item?.fuelType,
    key: 'fuelType',
  },
  {
    title: `${t('Engine Number')}`,
    value: item?.engineNumber,
    key: 'engineNumber',
  },
  {
    title: `${t('Chassis Number')}`,
    value: item?.chassisNumber,
    key: 'chassisNumber',
  },
  {
    title: `${t('Insurance Company')}`,
    value: item?.insuranceCompany,
    key: 'insuranceCompany',
  },
  {
    title: `${t('Insurance Expiry Date')}`,
    value: moment(item?.insuranceRenevalDate).format('MMMM Do YYYY'),
    key: 'insuranceRenevalDate',
  },
];

export const ownerDetails = (t: Function, item: any) => [
  {
    title: `${t('Owner Name')}`,
    value: item?.ownerName,
    key: 'ownerName',
  },
  {
    title: `${t('Contact Number')}`,
    value: item?.ownerTel,
    key: 'ownerTel',
  },
  {
    title: `${t('Email')}`,
    value: item?.ownerEmail,
    key: 'ownerEmail',
  },
];

export const userDetails = (t: Function, item: any) => [
  {
    title: `${t('Vehicle User Name')}`,
    value: item?.userName,
    key: 'userName',
  },
  {
    title: `${t('Contact Number')}`,
    value: item?.userTel,
    key: 'userTel',
  },
  {
    title: `${t('Email')}`,
    value: item?.userEmail,
    key: 'userEmail',
  },
];
