import React from 'react';
import moment from 'moment';
import { PRIORITY } from '../../../../enum/TaskboardTypes';
import { BOOKING_TYPES } from '../../../../enum/BookingStatus';
import { JOB_STATUS } from '../../../../enum/JobStatus';

export const sharedOnCell = (txt, item) => ({
  props: {
    colSpan: item.title ? 0 : 1,
  },
  children: <div>{txt}</div>,
});

export const getTotalPrice = (jobList: any[]) => {
  let amount = 0;
  jobList.forEach((job: any) => {
    if (job.status !== JOB_STATUS.REJECTED) {
      amount += parseFloat(job.price || '0');
    }
  });
  return parseFloat(amount.toFixed(2));
};

export const getPriority = (jobType: any) => {
  let tempCol;
  switch (jobType) {
    case PRIORITY.REPAIR:
      tempCol = '#0F8B8D';
      break;
    case PRIORITY.BREAK_DOWN:
      tempCol = '#595CFF';
      break;
    case PRIORITY.SERVICE:
      tempCol = '#4EDCFF';
      break;
    case PRIORITY.QUICK_JOBS:
      tempCol = '#EC2556';
      break;
    case PRIORITY.ACCIDENT:
      tempCol = '#FF7A00';
      break;
    default:
      tempCol = '#EC0F0F';
      break;
  }
  return tempCol;
};

export const getJobStatusColor = (status) => {
  let tempClr;
  switch (status) {
    case BOOKING_TYPES.TO_DO:
      tempClr = '#FFE600';
      break;
    case BOOKING_TYPES.IN_PROGRESS:
      tempClr = '#9E6AFF';
      break;
    case BOOKING_TYPES.QC:
      tempClr = '#FF90B3';
      break;
    case BOOKING_TYPES.PENDING_PAYMENT:
      tempClr = '#6FFAEA';
      break;
    default:
      tempClr = '#27AE60';
      break;
  }
  return tempClr;
};

const roundDownToNearest5 = (num: number) => Math.floor(num / 5) * 5;

export const timeConvert = (num: any) => {
  const hours = (num / 60);
  const rHours = Math.floor(hours);
  const minutes = (hours - rHours) * 60;
  const rMinutes = roundDownToNearest5(Math.round(minutes));
  if (rHours > 0) {
    if (rMinutes > 0) return `${rHours} hr(s) ${rMinutes} min(s)`;
    return `${rHours} hr(s)`;
  }
  return `${rMinutes} min(s)`;
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const generateFromDate = () => moment().add(-7, 'days').format('YYYY-MM-DD');
export const generateToDate = () => moment().add(+1, 'days').format('YYYY-MM-DD');
export const getToDate = (e: any) => {
  if (e) {
    return moment(e).add(+1, 'days').format('YYYY-MM-DD');
  }
  return generateToDate();
};

export const getFromDate = (e: any) => {
  if (e) {
    return moment(e).format('YYYY-MM-DD');
  }
  return generateFromDate();
};

export const getJobCount = (jobArray: any[]) => {
  let qcReadyCount: number = 0;
  let inProgressCount: number = 0;
  let rejectedCount: number = 0;
  let qcPassCount: number = 0;

  jobArray.forEach((job: any) => {
    switch (job.status) {
      case JOB_STATUS.START:
        inProgressCount += 1;
        break;
      case JOB_STATUS.PAUSED:
        inProgressCount += 1;
        break;
      case JOB_STATUS.QC:
        qcReadyCount += 1;
        break;
      case JOB_STATUS.REJECTED:
        rejectedCount += 1;
        break;
      case JOB_STATUS.PENDING_PAYMENT:
        qcPassCount += 1;
        break;
      default:
        break;
    }
  });

  return {
    qcReadyCount,
    inProgressCount,
    rejectedCount,
    qcPassCount,
  };
};
