import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';
import { Card, DatePicker } from 'antd';
import moment from 'moment';
import { getIncomeStatementByJobType } from '../action';
import { generateFromDateYear, generateToDateYear } from '../common';

const { RangePicker } = DatePicker;

const IncomeStatement = () => {
  const [chartData, setChartData] = useState<any[]>([]);
  const [date, setDate] = useState<any>({ from: generateFromDateYear(), to: generateToDateYear() });

  const asyncFetch = async () => {
    const from: string = moment(date.from).format('YYYY-MM-DD');
    const to: string = moment(date.to).format('YYYY-MM-DD');
    const { data } = await getIncomeStatementByJobType(from, to);
    let formattedData: any[] = [];
    data.forEach(({ year, monthInText, data: newData }: any) => {
      const dataSet: any[] = newData
        .map(({ label, value }) => ({
          city: `${year} ${monthInText}`,
          type: label,
          value,
        }));
      formattedData = [...formattedData, ...dataSet];
    });
    setChartData(formattedData);
  };

  const onChange = (value, dateString) => {
    setDate({ from: dateString[0], to: dateString[1] });
  };

  useEffect(() => {
    if (date) {
      asyncFetch();
    }
  }, [date]);

  return (
    <div>
      <Card
        title="Booking income based on the job type"
        extra={(
          <RangePicker
            defaultValue={[
              generateFromDateYear(),
              generateToDateYear(),
            ]}
            onChange={onChange}
          />
)}
        style={{ width: 1170 }}
      >
        <Column
          data={chartData}
          xField="city"
          yField="value"
          seriesField="type"
          isGroup
          columnStyle={{
            radius: [20, 20, 0, 0],
          }}
        />
      </Card>
    </div>
  );
};

export default IncomeStatement;
