import {
  Checkbox, Col, Form, Input, Radio, Select,
} from 'antd';
import React from 'react';
import { INPUT_TYPES } from '../enum/JobCreation';
import { FORMS } from '../enum/repairItemform';

const { Option } = Select;

export const camelize = (str: string) => {
  const newStr = str.replace(/[^a-zA-Z ]/g, '');
  return newStr.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase())).replace(/\s+/g, '');
};

const getSelectFields = (fields: any) => fields.map((field: any) => {
  const {
    inputType,
    label,
    name,
    rules = [],
  } = field;
  const key: string = `${camelize(label)}`;
  switch (inputType) {
    case INPUT_TYPES.TEXT:
      return (
        <Col span={12} key={key}>
          <Form.Item
            name={name}
            label={label}
            rules={rules}
          >
            <Input placeholder={`Enter ${label}`} type="number" />
          </Form.Item>
        </Col>
      );
    case INPUT_TYPES.RADIO:
      return (
        <Col span={12} key={key}>
          <Form.Item
            name={name}
            label={label}
          >
            <Radio.Group optionType="button">
              <Radio value="0">0</Radio>
              <Radio value="1">1</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      );
    case INPUT_TYPES.SELECT:
      return (
        <Col span={12} key={key}>
          <Form.Item
            name={name}
            label={label}
          >
            <Select>
              <Option value="0">0</Option>
              <Option value="1">1</Option>
            </Select>
          </Form.Item>
        </Col>
      );
    case INPUT_TYPES.CHECKBOX:
      return (
        <Col span={3} key={key}>
          <Form.Item
            name={name}
          >
            <Checkbox.Group options={[label]} />
          </Form.Item>
        </Col>
      );
    default:
      return <></>;
  }
});

export const generateFields = (
  repairItem: any,
  repairItems: any[] = [],
  repairItemForms: any[] = [],
) => {
  const { formId }: any = repairItems
    .find(({ value }: any) => value === repairItem) || {};
  if (!formId) {
    return <></>;
  }

  console.log({ repairItemForms });

  const selectedFields: any[] = FORMS
    .find(({ id }: any) => id === formId)?.form || [];
  return getSelectFields(selectedFields);
};
