import React from 'react';
import '../staff.scss';
import {
  Button, Row, Col, Tag, Tabs, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function ViewEmployee(props: any) {
  const { t } = useTranslation();
  const { employee } = props;
  const { Title } = Typography;

  const employeeData = [
    {
      title: `${t('Name')}`,
      value: employee?.name,
    },
    {
      title: `${t('Role')}`,
      value: employee?.role,
    },
    {
      title: `${t('NIC Number')}`,
      value: employee?.nic,
    },
    {
      title: `${t('Date of Birth')}`,
      value: moment(employee?.dob).format('LL'),
    },
    {
      title: `${t('Address')}`,
      value: employee?.address,
    },
    {
      title: `${t('Contact Number')}`,
      value: employee?.contactNumber,
    },
    {
      title: `${t('Username')}`,
      value: employee?.userName,
    },
    {
      title: `${t('Status')}`,
      value:
  <Tag
    style={{
      textAlign: 'center',
      alignSelf: 'center',
      borderRadius: '8px',
      fontSize: 14,
      padding: 2,
      paddingLeft: 8,
      paddingRight: 8,
    }}
    color={employee?.status === 'Active' ? '#27AE60' : '#EC0F0F'}
  >
    {employee?.status === 'Active' ? 'Active' : 'Inactive'}
  </Tag>,
    },
  ];

  const salaryData = [
    {
      title: `${t('Account Holder Name')}`,
      value: employee?.accountHolderName,
    },
    {
      title: `${t('Account Number')}`,
      value: employee?.accountNumber,
    },
    {
      title: `${t('Bank')}`,
      value: employee?.bank,
    },
    {
      title: `${t('Branch')}`,
      value: employee?.branch,
    },
    {
      title: `${t('Basic Salary')}`,
      value: employee?.basicSalary,
    },
    {
      title: `${t('EPF Employee Contribution')}`,
      value: employee?.epfEmployeeContribution,
    },
    {
      title: `${t('ETF Company Contribution')}`,
      value: employee?.etf,
    },
    {
      title: `${t('EPF Company Contribution')}`,
      value: employee?.epfCompanyContribution,
    },
  ];

  return (
    <Tabs defaultActiveKey="1" type="card" animated>
      <Tabs.TabPane
        tab={(
          <span>
            <Title level={5} className="drawr-titl">{t('General')}</Title>
          </span>
            )}
        key="1"
      >
        <Row className="tab-row">
          {employeeData.map((row) => (
            <Col key={row.title} xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <Col className="col-title" span={8}>{row?.title}</Col>
                <Col className="col-value" span={16}>{row?.value}</Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={(
          <span>
            <Title level={5} className="drawr-titl">{t('Qualifications')}</Title>
          </span>
            )}
        key="2"
      >
        <Row className="col-odd">
          <Col span={21}>
            <Title level={5} className="step-col-title">{t('G.C.E. O/L Certificate  [NVQ4]')}</Title>
          </Col>
          <Col span={3}>
            <Button type="link" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Col>
        </Row>
        <Row className="col-even">
          <Col span={21}>
            <Title level={5} className="step-col-title">{t('G.C.E A/L Certificate  [NVQ5]')}</Title>
          </Col>
          <Col span={3}>
            <Button type="link" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Col>
        </Row>
        <Row className="col-odd">
          <Col span={21}>
            <Title level={5} className="step-col-title">{t('NDT/HNDE/Diploma in Automobile Engineering  [NVQ 6]')}</Title>
          </Col>
          <Col span={3}>
            <Button type="link" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Col>
        </Row>
        <Row className="col-even">
          <Col span={21}>
            <Title level={5} className="step-col-title">{t('BSc (Engineering)/BEng Automobile [NVQ 7]')}</Title>
          </Col>
          <Col span={3}>
            <Button type="link" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Col>
        </Row>
        <Row className="col-odd">
          <Col span={21}>
            <Title level={5} className="step-col-title">{t('Msc Engineering/ MEng Automobile')}</Title>
          </Col>
          <Col span={3}>
            <Button type="link" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Col>
        </Row>
        <Row className="col-even">
          <Col span={21}>
            <Title level={5} className="step-col-title">{t('Other Professional  Registrations')}</Title>
          </Col>
          <Col span={3}>
            <Button type="link" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Col>
        </Row>
        <Row className="col-odd">
          <Col span={21}>
            <Title level={5} className="step-col-title">{t('Incorporated Engineer Registration')}</Title>
          </Col>
          <Col span={3}>
            <Button type="link" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Col>
        </Row>
        <Row className="col-even">
          <Col span={21}>
            <Title level={5} className="step-col-title">{t('Charted Engineer Registration')}</Title>
          </Col>
          <Col span={3}>
            <Button type="link" disabled className="add-employee-button file-upload-btn">{t('Browse')}</Button>
          </Col>
        </Row>
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={(
          <span>
            <Title level={5} className="drawr-titl">{t('Salary')}</Title>
          </span>
            )}
        key="3"
      >
        <Row className="tab-row">
          {salaryData.map((row) => (
            <Col key={row.title} xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <Col className="col-title" span={8}>{row?.title}</Col>
                <Col className="col-value" span={16}>{row?.value}</Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Tabs.TabPane>
    </Tabs>
  );
}
