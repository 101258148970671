import { message } from 'antd';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { AppDispatch } from '../../redux/store';
import {
  setUser, setUserId, setUserRoles, setWorkshop, setWorkShopId, setConfigData,
} from '../../redux/userSlice';
import { awsAmplifyApiInit } from '../../utils/aws-configure';
import { getWorkshop } from '../../graphql-queries/workshop';
import { APIS, getApi } from '../../utils/apiUtils';

export const getWorkshopById = async (workShopId: string) => {
  try {
    const res = await API.graphql(graphqlOperation(getWorkshop, { workShopId }));
    return res?.data?.getWorkshop;
  } catch (err: any) {
    console.log(err);
    return null;
  }
};

export const getConfigData = async (workShopId) => {
  try {
    return await getApi(APIS.CONFIG_API, `config?workshopId=${workShopId}`);
  } catch (err: any) {
    console.log(err);
    return null;
  }
};

export const logout = async (dispatch: AppDispatch) => {
  message.loading('Signing out. Please wait', 0);
  try {
    await Auth.signOut();
    dispatch(setUserId(null));
    localStorage.clear();
    Auth.currentCredentials();
    window.location.href = '/';
    message.destroy();
  } catch (error) {
    message.destroy();
  }
};

export const signIn = async (username: string, password: string, dispatch: AppDispatch,
  onRequestNewPassword: Function, newPassword?: string) => {
  message.loading('Please wait', 0);
  try {
    let user = await Auth.signIn(username.trim(), password.trim());
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      if (!newPassword) {
        onRequestNewPassword(true);
        message.destroy();
        message.info('Please set new password');
        return false;
      }
      user = await Auth.completeNewPassword(user, newPassword);
    }

    const selectedUserdata = {
      username: user.username,
      email: (user.attributes && user.attributes.email) || user.challengeParam.userAttributes.email,
    };

    const workshopId = user.signInUserSession.idToken.payload['custom:workshop_id'];
    await awsAmplifyApiInit();
    const workshop = await getWorkshopById(workshopId);
    const configData = await getConfigData(workshopId);
    dispatch(setUserId(user.signInUserSession.idToken.payload.sub));
    dispatch(setUser(selectedUserdata));
    dispatch(setWorkShopId(workshopId));
    dispatch(setWorkshop(workshop));
    dispatch(setConfigData(configData));
    dispatch(setUserRoles((user.signInUserSession.accessToken.payload['cognito:groups'])));
    message.destroy();
    return true;
  } catch (err: any) {
    if (err.code === 'UserNotFoundException') {
      message.destroy();
      message.error('Incorrect username or password');
    } else {
      message.destroy();
      message.error(err.message);
    }
  }
  return false;
};

export const checkIsLoggedIn = async (dispatch: AppDispatch) => {
  try {
    const session = await Auth.currentSession();
    const user = await Auth.currentAuthenticatedUser();
    await awsAmplifyApiInit();

    const selectedUserdata = {
      username: user.username,
      email: user.attributes.email,
    };

    const workshopId = session.getIdToken().payload['custom:workshop_id'];
    const workshop = await getWorkshopById(workshopId);
    const configData = await getConfigData(workshopId);
    dispatch(setUserId(session.getIdToken().payload.sub));
    dispatch(setUser(selectedUserdata));
    dispatch(setWorkShopId(workshopId));
    dispatch(setWorkshop(workshop));
    dispatch(setConfigData(configData));
    dispatch(setUserRoles((user.signInUserSession.accessToken.payload['cognito:groups'])));
  } catch (err: any) {
    dispatch(setUserId(null));
  }
};
