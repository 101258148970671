import { message } from 'antd';
import { APIS, getApi } from '../../../utils/apiUtils';

// 1. Get Income by repair item group
export const getIncomeByRepairItemGroup = async (from: string, to: string) => {
  try {
    return await getApi(APIS.FINANCE_API, `booking-income-for-repair-item-groups?to=${to}&from=${from}`);
  } catch (err: any) {
    console.log(err);
    message.destroy();
    message.error('Error when getting Income by repair item group data');
    return {};
  }
};

// 2. Get payment status of bookings
export const getPaymentStatusOfBookings = async (from: string, to: string) => {
  try {
    return await getApi(APIS.FINANCE_API, `get-payment-status-of-bookings?to=${to}&from=${from}`);
  } catch (err: any) {
    console.log(err);
    message.destroy();
    message.error('Error when payment status of bookings');
    return {};
  }
};

// 3. Get income by payment method
export const getIncomeByPaymentMethod = async (from: string, to: string) => {
  try {
    return await getApi(APIS.FINANCE_API, `get-income-by-payment-method?to=${to}&from=${from}`);
  } catch (err: any) {
    console.log(err);
    message.destroy();
    message.error('Error when getting income by payment method');
    return {};
  }
};

// 4. Get by estimated received payments
export const getByEstimatedReceivedPayments = async (from: string, to: string) => {
  try {
    return await getApi(APIS.FINANCE_API, `get-by-estimated-received-payments?from=${from}&to=${to}`);
  } catch (err: any) {
    console.log(err);
    message.destroy();
    message.error('Error when getting by estimated received payments');
    return {};
  }
};

// 5. Get booking income
export const getBookingIncome = async (from: string, to: string) => {
  try {
    return await getApi(APIS.FINANCE_API, `booking-income?from=${from}&to=${to}`);
  } catch (err: any) {
    console.log(err);
    message.destroy();
    message.error('Error when getting booking income');
    return {};
  }
};

// 6. Get income statement by job type
export const getIncomeStatementByJobType = async (from: string, to: string) => {
  try {
    return await getApi(APIS.FINANCE_API, `get-income-statement-by-job-type?to=${to}&from=${from}`);
  } catch (err: any) {
    console.log(err);
    message.destroy();
    message.error('Error when getting income statement by job type');
    return {};
  }
};
