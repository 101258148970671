import React, { useEffect, useState } from 'react';
import {
  Button, Col, Drawer, Form, Input, message, Row, Select, Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ulid } from 'ulid';
import '../Grn.scss';
import { UploadFile } from 'antd/es/upload/interface';
import {
  deleteAttachment,
  getGrnContent,
  getSignedUrl,
  updateGRN,
} from '../actions';
import { S3_METHODS } from '../../../../enum/ConfigurationData';
import { dummyRequest, onPreviewFile } from '../../../../utils/commonUtil';

type PropTypes = {
  fetchData: Function,
  isModalVisible: boolean,
  setModalVisible: Function,
  grnItem: any,
  workShopId: string,
  grnItems: any,
}

const AddEditItem = ({
  fetchData, isModalVisible, setModalVisible, grnItem, workShopId, grnItems,
}: PropTypes) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onClose = () => {
    setModalVisible(false);
    setFileList([]);
    form.resetFields();
  };

  const uploadAttachments = async () => {
    const attachments: any[] = grnItems?.image || [];
    const newAttachments: UploadFile[] = fileList
      .filter((file: UploadFile) => !attachments.includes(file.name));
    const deleteImages: any[] = attachments
      .filter((attach: any) => fileList
        .map((f: UploadFile) => f.name !== attach));
    if (deleteImages.length) {
      message.loading('Deleting attachment', 0);
      const deletePromises = deleteImages
        .map(async (attach: string) => deleteAttachment(`${workShopId}/${attach}`));
      await Promise.all(deletePromises);
      message.destroy();
    }

    if (newAttachments.length) {
      message.loading('Uploading invoice attachment...', 0);
      const uploadPromises = newAttachments.map(async (attach: any) => {
        const extension = attach.name.split('.').pop();
        const fileName = `${ulid()}.${extension}`;
        const key = `${workShopId}/${fileName}`;
        const contentType = attach.type;
        const response = await getSignedUrl(key, S3_METHODS.put, contentType);
        if (response?.body.url) {
          await fetch(response.body.url, {
            method: 'PUT',
            headers: {
              'Content-Type': contentType,
            },
            body: attach.originFileObj,
          });
          return fileName;
        }
        throw new Error('Something went wrong');
      });
      const [image] = await Promise.all(uploadPromises);
      message.success('Credit attachment uploaded successfully!').then();
      return image;
    }
    return null;
  };

  const addUpdateItem = async () => {
    const formData = form.getFieldsValue();
    const pdf = await uploadAttachments();
    message.destroy();
    message.loading(`${grnItem ? 'Updating' : 'Saving'} GRN item`, 0);

    formData.quantity = parseFloat(formData.quantity);
    formData.minOrderQty = parseFloat(formData.minOrderQty);
    formData.minIssueQuantity = parseFloat(formData.minIssueQuantity);
    const grnData = {
      ...formData,
      workShopId,
      image: pdf,
    };
    await updateGRN(grnData)
      .then((res: any) => {
        if (res) {
          message.destroy();
          message.success('Added successfully');
          fetchData();
          onClose();
        }
      }).catch(() => {
        message.destroy();
        message.error('Something went wrong');
        onClose();
      });
  };

  const onImageChange = (info) => {
    const { fileList: newFileList } = info;
    setFileList(newFileList);
    if (newFileList.length) {
      message.destroy();
      message.success('Updated successfully');
    } else {
      message.destroy();
      message.success('File deleted successfully');
    }
  };

  const loadAttachments = async () => {
    const promises = await grnItems.image.map(async (attachment: string) => {
      const { body: { url } } = await getGrnContent(`${workShopId}/${attachment}`);
      return {
        uid: attachment?.split('.')[0],
        name: attachment,
        status: 'done',
        url,
      };
    });
    const files = await Promise.all(promises);
    setFileList(files);
  };

  useEffect(() => {
    try {
      if (grnItem) {
        form.setFieldsValue(grnItem);
      }
      if (grnItems?.image?.length) {
        loadAttachments().finally();
      }
    } catch (e: any) {
      message.error(e.message);
    }
  }, [grnItem]);

  return (
    <Drawer
      title={grnItem ? `${t('Edit an Invoice')}` : `${t('Add an Invoice')}`}
      placement="right"
      width={800}
      onClose={onClose}
      visible={isModalVisible}
    >
      <Form
        form={form}
        onFinish={addUpdateItem}
        layout="vertical"
        hideRequiredMark
        initialValues={{ grnInvoiceId: ulid() }}
      >
        {/* Row 1 */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="grnInvoiceId"
              label={t('Item ID')}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="invoiceNumber"
              label={t('Invoice number')}
              rules={[{ required: true, message: `${t('Please Enter Invoice number')}` }]}
            >
              <Input placeholder={t('Enter Invoice number')} />
            </Form.Item>
          </Col>
        </Row>
        {/* Row 2 */}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="description"
              label={t('Description')}
              rules={[{ required: true, message: `${t('Please Enter Description')}` }]}
            >
              <Input placeholder={t('Enter Description')} />
            </Form.Item>
          </Col>
        </Row>
        {/* Row 3 */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="quantity"
              label={t('Quantity')}
              rules={[
                { required: true, message: `${t('Please Enter Quantity')}` },
                { pattern: new RegExp(/^[1-9]+[0-9]*$/), message: 'Input should be only positive numbers' },
              ]}
            >
              <Input placeholder={t('Enter Quantity')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="units"
              label={t('Units')}
              rules={[{ required: true, message: `${t('Please Enter Units')}` }]}
            >
              <Select placeholder={t('Select Unit')}>
                <Select.Option value="kg">kg</Select.Option>
                <Select.Option value="l">l</Select.Option>
                <Select.Option value="other">other</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/* Row 2 */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="minOrderQty"
              label={t('Min Order Quantity')}
              rules={[
                { required: true, message: `${t('Please Enter Min Order Quantity')}` },
                { pattern: new RegExp(/^[1-9]+[0-9]*$/), message: 'Input should be only positive numbers' },
              ]}
            >
              <Input placeholder={t('Enter Min Order Quantity')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="minIssueQuantity"
              label={t('Min Issue Quantity')}
              rules={[
                { required: true, message: `${t('Please Enter Min Issue Quantity')}` },
                { pattern: new RegExp(/^[1-9]+[0-9]*$/), message: 'Input should be only positive numbers' },
              ]}
            >
              <Input placeholder={t('Enter Min Issue Quantity')} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="tab-row">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="image"
            >
              <Row>
                <Col className="col-title" span={6} style={{ width: '125px' }}>{t('Attachments')}</Col>
                <Col className="col-value" span={18} style={{ width: '375px' }}>
                  <Upload
                    fileList={fileList}
                    onChange={onImageChange}
                    onPreview={onPreviewFile}
                    customRequest={dummyRequest}
                    accept=".pdf"
                    maxCount={1}
                  >
                    {!fileList.length
                        && <Button type="link" style={{ marginTop: '-5px', marginBottom: '-5px' }} icon={<UploadOutlined />}>{t('Browse')}</Button>}
                  </Upload>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ float: 'right' }}>
          <Button className="sav-btn" htmlType="submit">
            {t('submit')}
          </Button>
        </Row>
      </Form>
    </Drawer>
  );
};

export default AddEditItem;
