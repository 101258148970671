/* eslint-disable react/jsx-props-no-spreading */
import { EditOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button, Col, Drawer, Form, Input, message, Popover, Radio, Row, Typography, Upload,
} from 'antd';
import React, { useEffect, useState } from 'react';
import '../workshop.scss';
import { ulid } from 'ulid';
import {
  deleteImage, getImageUrl, getSignedUrl, updateWorkshopDetails,
} from '../actions';

export default function EditProfile({ workshop, fetchData }: any) {
  const [visible, setVisible] = useState(false);
  const [workshopDetails, setWorkshopDetails] = useState<any>([]);
  const [image, setImage] = useState<string>('');
  const [signedUrl, setSignedUrl] = useState<string>();
  const [fileList, setFileList] = useState<any[]>([]);
  const [logoKey, setLogoKey] = useState<string>('');
  const [form] = Form.useForm();

  const { Title } = Typography;

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleUploadLogo = (info) => {
    let logoFileList = [...info.fileList];
    const fileType = info.file.name.split('.')[1];
    if (fileType !== 'png') {
      message.error(`${info.file.name} is not a png file`);
    } else {
      logoFileList = logoFileList.slice(-1);

      if (logoFileList.length && logoFileList[0].status === 'done') {
        const selectedFile = logoFileList[0].originFileObj;
        const key = `${ulid()}.png`;
        setLogoKey(key);
        const contentType = selectedFile.type;
        getSignedUrl(key, contentType).then((res) => {
          setSignedUrl(res.url);
        }).catch((err) => {
          message.destroy();
          message.error(err.message);
        });
      }

      logoFileList = logoFileList.map((f: any) => {
        const file = f;
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      setFileList(logoFileList);
    }
  };

  const uploadToS3 = async () => {
    if (signedUrl && fileList.length && fileList[0]) {
      const selectedFile = fileList[0].originFileObj;
      fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': selectedFile.type,
        },
        body: selectedFile,
      })
        .then((res) => res.text())
        .then(() => {
          setFileList([]);
          message.success(`${selectedFile.name} uploaded successfully`);
        });
    }
  };

  const editWorkshop = async () => {
    const formData = form.getFieldsValue();

    const data = { ...workshopDetails, ...formData };

    await uploadToS3();
    if (fileList[0] && workshop.logo) {
      await deleteImage(workshop.logo);
      message.success('Deleting the old Logo');
    }

    if (logoKey && logoKey !== '') {
      data.logo = logoKey;
    }

    updateWorkshopDetails(data).then((res: any) => {
      if (res) {
        message.destroy();
        message.success('Workshop details updated successfully');
        onClose();
        fetchData();
      } else {
        message.destroy();
        message.error('Something went wrong');
      }
    });
  };

  const loadImages = () => {
    if (workshop.logo) {
      getImageUrl(workshop.logo, 'image/png', 'getObject').then((imageUrl) => {
        const imageUrlObj = {
          status: 'done',
          url: imageUrl.url,
        };
        setImage(imageUrl.url);
        return imageUrlObj;
      });
    }
  };

  useEffect(() => {
    if (workshop) {
      setWorkshopDetails(workshop);
    }
    loadImages();
  }, [workshop]);

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const props = {
    onChange: handleUploadLogo,
    multiple: true,
    fileList,
    customRequest: dummyRequest,
  };

  return (
    <>
      <Popover placement="topLeft" content="Edit Workshop Details" trigger="hover">
        <Button onClick={showDrawer} type="link" icon={<EditOutlined />} />
      </Popover>
      <Drawer title="Edit Workshop Details" visible={visible} onClose={onClose} width={700}>
        <Form
          form={form}
          hideRequiredMark
          initialValues={workshopDetails}
          onFinish={editWorkshop}
        >
          <Title level={5} className="step-title">General Details</Title>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="workshopName"
                label="Workshop Name"
                style={{ marginBottom: '8px' }}
                rules={[{
                  required: true,
                  message: 'Please Enter Workshop Name',
                }]}
              >
                <Input placeholder="Enter Workshop Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="regNo"
                label="Registration Number"
                className="wrk-input"
                rules={[{ required: true, message: 'Please Enter Registration Number' }]}
              >
                <Input placeholder="Enter Registration Number" />
              </Form.Item>
              <Form.Item
                name="contactNumber"
                label="Workshop Contact Details"
                className="wrk-input"
                rules={[{
                  required: true, message: 'Please Enter Contact Number',
                }, {
                  pattern: /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g,
                  message: 'Please Enter Valid Contact Number',
                }]}
              >
                <Input placeholder="Enter Contact Details" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="address"
                label="Address"
                className="wrk-input"
                rules={[{ required: true, message: 'Please Enter Address' }]}
              >
                <Input placeholder="Enter Address" style={{ height: '120px', textAlign: 'center' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Upload Logo"
                className="wrk-input"
              />
              <Upload {...props}>
                <div><img src={image} style={{ width: '150px' }} alt="Logo" /></div>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Col>
          </Row>
          <Title level={5} className="step-title" style={{ marginBottom: '1px', marginTop: '15px', fontWeight: 'bold' }}>Admin Details</Title>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                style={{ marginBottom: '8px' }}
                rules={[{
                  required: true,
                  message: 'Please Enter Name',
                },
                {
                  pattern: new RegExp(/^[a-zA-Z ]*$/),
                  message: 'Name should include only letters',
                }]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="adminContactNumber"
                label="Contact Details"
                style={{ marginBottom: '8px' }}
                rules={[{
                  required: true, message: 'Please Enter Contact Number',
                }, {
                  pattern: /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g,
                  message: 'Please Enter Valid Contact Number',
                }]}
              >
                <Input placeholder="Enter Contact Details" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="userName"
                label="Username"
                className="wrk-input"
                rules={[{ required: true, message: 'Please Enter Username' }]}
              >
                <Input placeholder="Enter Username" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                className="wrk-input"
                rules={[{ required: true, message: 'Please Enter Email' }, {
                  // eslint-disable-next-line no-useless-escape
                  pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: 'Please Enter Valid Email',
                }]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="status"
                label="Status"
                className="wrk-input"
                rules={[{ message: 'Please Slect Status' }]}
              >
                <Radio.Group optionType="button">
                  <Radio value="Active">Active</Radio>
                  <Radio value="Inactive">Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>{' '}</Col>
            <Col span={12}>
              <Form.Item>
                <Button
                  className="sav-btn"
                  htmlType="submit"
                  style={{
                    float: 'right', marginTop: '10px', marginRight: '10px',
                  }}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

      </Drawer>
    </>
  );
}
