/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
export enum BOOKING_TYPES {
  CHECKIN_TO_DO= 'To Do CheckIn',
  CHECKIN_IN_PROGRESS= 'Checkin In Progress',
  TO_DO= 'To Do',
  IN_PROGRESS= 'In Progress',
  QC= 'Quality Control',
  REJECTED= 'Rejected',
  DONE= 'Done',
  PENDING_PAYMENT= 'Pending Payment',
  AWAITING_APPROVAL = 'Awaiting Approval',
  CHECKOUT = 'Checkout',
}

export const PAYMENT_TYPE = {
  CASH: 'Cash',
  ONLINE: 'Online',
  CREDIT: 'Credit',
};
