import { t } from 'i18next';
import React from 'react';
import Joyride from 'react-joyride';
import { TOUR } from '../../../configs/tour-consfig';

export const AddBookingTour = () => {
  const tourDashboard = [
    {
      content: t('Are you ready to create a Booking ?'),
      placement: 'center',
      locale: { skip: t('Skip Button') },
      target: '.site-layout',
    },
    {
      title: t('Make an appointment'),
      content: t('Select a date from the calendar view'),
      target: '.calender',
    },
  ];

  const isOpen = TOUR;

  return (
    <div>
      <Joyride
        run={isOpen}
        continuous
        callback={() => null}
        steps={tourDashboard}
        showSkipButton
        styles={{
          options: {
            primaryColor: '#EB5757',
            zIndex: 10000,
          },
        }}
      />
    </div>
  );
};

export const MakeAnAppoinmentTour = () => {
  const tourMakeAnAppoinmnet = [
    {
      title: t('Make an appointment'),
      content: t('Fill the form to make an appointment'),
      placement: 'center',
      locale: { skip: t('Skip Button') },
      target: '.booking-appoinment',
    },
    {
      title: t('Add new Vehicle'),
      content: t('Click here to add a new vehicle, if the vehicle is not registerd in Karstation'),
      target: '.add-new-vehicle-button',
    },
    {
      title: t('Search vehicle'),
      content: t('If the vehicle is already registered, search vehicle with plate number'),
      target: '.plateNumber-input',
    },
  ];

  const isOpen = TOUR;

  return (
    <div>
      <Joyride
        run={isOpen}
        continuous
        callback={() => null}
        steps={tourMakeAnAppoinmnet}
        showSkipButton
        styles={{
          options: {
            primaryColor: '#EB5757',
            zIndex: 10000,
          },
        }}
      />
    </div>
  );
};
