/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import {
  Col, Input, Row, Table, Tag, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import './workshop.scss';
import AddWorkshop from './components/AddWorkshop';
import ViewProfile from './components/ViewProfile';
import EditProfile from './components/EditProfile';
import { getWorkshops, searchWorkshopList } from './actions';

export const WorkshopProfile = () => {
  const { Title } = Typography;
  const { t } = useTranslation();

  const [pageNumber, setPageNumber] = useState<any>(1);
  const [data, setData] = useState<any>();
  const [workshop, setWorkshop] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isRefresh, setRefresh] = useState<boolean>(false);

  const getAllWorkshopData = async () => {
    let limit = 20;
    let nextToken = null;
    if (pageNumber > 1) {
      limit = 10;
      nextToken = data.nextToken;
    }
    await getWorkshops(limit, nextToken).then((res: any) => {
      const newData = (res && res.data.getAllWorkshops) || [];
      const newTableData = newData.workshop;
      nextToken = newData.nextToken || null;
      // @ts-ignore
      setWorkshop((prevState: any) => [...prevState, ...newTableData]);
      setData(newData);
      if (newTableData.length < 10 || !nextToken) {
        setHasMore(false);
      }

      if (nextToken) {
        setPageNumber(pageNumber + 1);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchData = () => {
    setWorkshop([]);
    setPageNumber(1);
    setData(undefined);
    setRefresh(true);
  };

  useEffect(() => {
    if (isRefresh) {
      getAllWorkshopData();
      setRefresh(false);
    }
  }, [isRefresh]);

  useEffect(() => {
    fetchData();
  }, []);

  const onSearch = (e) => {
    let keyword = e.target.value;
    keyword = keyword.toLowerCase();
    if (keyword === '' || keyword === null) {
      getAllWorkshopData();
    } else {
      searchWorkshopList(keyword).then((res: any) => {
        setWorkshop(res.data.searchWorkshop);
      });
    }
  };

  const tablePagination = (e) => {
    if (e + 1 >= pageNumber && hasMore) {
      getAllWorkshopData();
    }
  };

  const columnsData = [
    {
      title: `${t('WorkshopName')}`,
      dataIndex: 'workshopName',
      width: '50px',
    },
    {
      title: `${t('WorkshopRegistrationNumber')}`,
      dataIndex: 'regNo',
      width: '50px',
    },
    {
      title: `${t('WorkshopContactDetails')}`,
      dataIndex: 'contactNumber',
      width: '50px',
    },
    {
      title: `${t('Status')}`,
      dataIndex: 'status',
      width: '30px',
      render: (status, item) => (
        <Tag
          style={{
            textAlign: 'center',
            alignSelf: 'center',
            borderRadius: '8px',
            width: 60,
            fontSize: 14,
            padding: 2,
            paddingLeft: 4,
            paddingRight: 4,
          }}
          color={item?.status === 'Active' ? '#27AE60' : '#EC0F0F'}
        >
          {item?.status === 'Active' ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: `${t('Action')}`,
      dataIndex: 'action',
      width: '30px',
      align: 'center' as const,
      render: (action, item) => (
        <>
          <ViewProfile workshop={item} />
          <EditProfile workshop={item} fetchData={fetchData} />
        </>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Title level={3} style={{ color: '#8C8C8C' }}>{t('Workshops')}</Title>
      </Row>
      <Row style={{ paddingBottom: 15 }}>
        <Col span={4}>
          <div />
        </Col>
        <Col span={13} style={{ marginTop: '3px' }}>
          <Row style={{ float: 'right' }}>
            <AddWorkshop fetchData={fetchData} />
          </Row>
        </Col>
        <Col span={6} offset={1}>
          <Input
            addonBefore={<SearchOutlined />}
            placeholder="Search"
            style={{ marginTop: 5 }}
            onChange={onSearch}
          />
        </Col>
      </Row>
      <Table
        columns={columnsData}
        scroll={{ y: 500 }}
        dataSource={workshop}
        pagination={{ onChange: tablePagination }}
      />
    </>
  );
};

export default WorkshopProfile;
