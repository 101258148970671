import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { S3_METHODS } from '../../../enum/ConfigurationData';
import { getJobs } from '../../../graphql-queries/booking';
import { getAllCredit } from '../../../graphql-queries/credit';
import { APIS, getApi } from '../../../utils/apiUtils';

export const getCredit = async (workShopId: String, status: String, from: String, to: String) => {
  try {
    return await API.graphql({
      query: getAllCredit,
      variables: {
        workShopId, status, from, to,
      },
    });
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getJobList = async (workShopId, bookingId) => {
  try {
    return await API.graphql(graphqlOperation(getJobs, { workShopId: `${workShopId}`, bookingId: `${bookingId}` }));
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getBookingContent = async (key) => {
  try {
    return await getApi(APIS.BOOKING_API, `signed-url?key=${key}&method=${S3_METHODS.get}`);
  } catch (err: any) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};
