import React from 'react';
import Joyride from 'react-joyride';
import TOUR from '../../../configs/tour-consfig';

export const InvestiGationTour = () => {
  const tourInvestiGation = [
    {
      content: 'You are now on vehicle investigation..',
      placement: 'center',
      locale: { skip: 'Skip' },
      target: '.site-layout',
    },
    {
      title: 'Title',
      content:
        'content.....',
      target: '.taskBoard',
    },
    {
      title: 'Title',
      content:
        'content.....',
      target: '.taskBoard',
    },
    {
      title: 'Title',
      content:
        'content.....',
      target: '.taskBoard',
    },
    {
      title: 'Title',
      content:
        'content.....',
      target: '.taskBoard',
    },
  ];

  const isOpen = TOUR;

  return (
    <div>
      <Joyride
        run={isOpen}
        continuous
        callback={() => null}
        steps={tourInvestiGation}
        showSkipButton
        styles={{
          options: {
            primaryColor: '#EB5757',
            zIndex: 10000,
          },
        }}
      />
    </div>
  );
};

export default InvestiGationTour;
