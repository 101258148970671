import { PRIORITY } from '../enum/TaskboardTypes';

export const getColourByBookingType = (job: string) => {
  switch (job) {
    case PRIORITY.REPAIR:
      return '#0F8B8D';
    case PRIORITY.BREAK_DOWN:
      return '#595CFF';
    case PRIORITY.SERVICE:
      return '#4EDCFF';
    case PRIORITY.QUICK_JOBS:
      return '#EC2556';
    case PRIORITY.ACCIDENT:
      return '#FF7A00';
    default:
      return '#EC0F0F';
  }
};

export const getStatusByBookingType = (job: string) => {
  let status;
  if (PRIORITY.REPAIR === job) {
    status = 'processing';
  } else if (PRIORITY.BREAK_DOWN === job) {
    status = 'success';
  } else if (PRIORITY.SERVICE === job) {
    status = 'warning';
  } else {
    status = 'error';
  }
  return status;
};
