/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import { DragDropContext, DragDropContextProps } from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import produce from 'immer';
import {
  Col, DatePicker, Input, message, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { connect } from 'react-redux';
import { TaskBoardItem, TaskBoardItemStatus } from './TaskboardTypes';
import TaskBoardCol from './TaskBoardCol';
import {
  getCheckIn, getJobList, subscribeToLiveBooking, updateBooking,
} from '../action';
import { useAppSelector } from '../../../../redux/hooks';
import Loading from '../../../../Loading';
import { BOOKING_TYPES } from '../../../../enum/BookingStatus';
import NoData from '../../../../components/NoData';
import { USER_ROLES } from '../../../../enum/User';

const defaultItems = {
  [BOOKING_TYPES.CHECKIN_TO_DO]: [],
  [BOOKING_TYPES.CHECKIN_IN_PROGRESS]: [],
  [BOOKING_TYPES.TO_DO]: [],
};

type TaskBoardData = Record<TaskBoardItemStatus, TaskBoardItem[]>;

// eslint-disable-next-line react/prop-types
function Taskboard({ workShopId, userRoles }) {
  // @ts-ignore
  const [itemsByStatus, setItemsByStatus] = useState<TaskBoardData>(defaultItems);
  const [searchData, setSearchData] = useState([]);
  const generateFromDate = () => moment().add(-7, 'days').format('YYYY-MM-DD');
  const generateToDate = () => moment().add(+1, 'days').format('YYYY-MM-DD');
  const userId = useAppSelector((state) => state.userData.userId);
  const [fromDate, setFromDate] = useState(generateFromDate);
  const [toDate, setToDate] = useState(generateToDate);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [isDataAvailable, setDataAvailable] = useState<boolean>(false);
  const [newBooking, setNewBooking] = useState<any>();

  const handleDragEnd: DragDropContextProps['onDragEnd'] = async ({
    source,
    destination,
    draggableId,
  }) => {
    // eslint-disable-next-line react/prop-types
    if (userRoles && !userRoles.includes(USER_ROLES.RECEPTIONIST)) {
      if (source.droppableId !== destination.droppableId) {
        const [selectedBooking] = itemsByStatus[source.droppableId].filter((booking) => booking.sortKey === draggableId);
        const { data: { getJobs = [] } }: any = await getJobList(workShopId, selectedBooking.sortKey);
        if (!getJobs.length) {
          return message.error(t("Estimation form shouldn't be empty!"));
        }
        const updatedBooking = { ...selectedBooking, status: destination.droppableId, workShopId };
        updateBooking(updatedBooking);
      }

      setItemsByStatus((current) => produce(current, (draft) => {
        if (!destination) {
          return;
        }
        const [removed] = draft[
          source.droppableId as TaskBoardItemStatus
        ].splice(source.index, 1);
        draft[destination.droppableId as TaskBoardItemStatus].splice(
          destination.index,
          0,
          removed,
        );
      }));
    }
    return true;
  };

  const getToDate = (e: any) => {
    const date = e ? moment(e).add(+1, 'days').format('YYYY-MM-DD') : generateToDate();
    setToDate(date);
  };

  const getFromDate = (e: any) => {
    const date = e ? moment(e).format('YYYY-MM-DD') : generateFromDate();
    setFromDate(date);
  };

  const syncData = (dataList: any[]) => {
    const data: any = {};
    data[TaskBoardItemStatus.TO_DO] = dataList.filter((item: any) => item.status === TaskBoardItemStatus.TO_DO);
    data[TaskBoardItemStatus.CHECKIN_TO_DO] = dataList.filter((item: any) => item.status === TaskBoardItemStatus.CHECKIN_TO_DO);
    data[TaskBoardItemStatus.CHECKIN_IN_PROGRESS] = dataList.filter((item: any) => item.status === TaskBoardItemStatus.CHECKIN_IN_PROGRESS);
    setItemsByStatus(data);
  };

  const getAllCheckin = () => {
    if (fromDate && toDate) {
      if (fromDate < toDate) {
        getCheckIn(workShopId, userId, fromDate, toDate).then((res: any) => {
          const checkin = [];
          res?.data?.getAllCheckIn.map((item: any) => {
            const temp = { ...item, id: item.sortKey };
            // @ts-ignore
            checkin.push(temp);
            return null;
          }, 1200);
          setSearchData(checkin);
          syncData(checkin);
          setLoading(true);
          setDataAvailable(!!res?.data?.getAllCheckIn?.length);
        });
      } else {
        message.error('Please select a valid date range');
      }
    }
  };

  const onSearch = (e) => {
    const keyword = e.target.value;
    let res = searchData;
    if (keyword !== '') {
      res = searchData.filter((item: any) => {
        let flag = false;
        if (item.plateNumber && item.plateNumber.toUpperCase().includes(keyword.toUpperCase())) {
          flag = true;
        }
        if (item.ownerName && item.ownerName.toUpperCase().includes(keyword.toUpperCase())) {
          flag = true;
        }
        return flag;
      });
    }
    const data = {};
    data[TaskBoardItemStatus.TO_DO] = res.filter((item: any) => item.status === TaskBoardItemStatus.TO_DO);
    data[TaskBoardItemStatus.CHECKIN_TO_DO] = res.filter((item: any) => item.status === TaskBoardItemStatus.CHECKIN_TO_DO);
    data[TaskBoardItemStatus.CHECKIN_IN_PROGRESS] = res.filter((item: any) => item.status === TaskBoardItemStatus.CHECKIN_IN_PROGRESS);
    // @ts-ignore
    setItemsByStatus(data);
  };

  const taskBoardContent = () => {
    if (!loading) {
      return (<Loading />);
    }
    if (!isDataAvailable) {
      return (<NoData />);
    }
    return Object.values(TaskBoardItemStatus).map((status) => (
      <TaskBoardCol
        key={status}
        status={status}
        items={itemsByStatus[status]}
        getAllCheckin={getAllCheckin}
      />
    ));
  };

  const updateTaskBoard = () => {
    const isAvailable = searchData.find((item: any) => item.sortKey === newBooking.sortKey);
    if (isAvailable) {
      const newList: any = searchData.filter((item: any) => item.sortKey !== newBooking.sortKey);
      newList.push({ ...newBooking, id: newBooking.sortKey });
      setSearchData(newList);
      syncData(newList);
    }
    setNewBooking(undefined);
  };

  useEffect(() => {
    if (newBooking) {
      updateTaskBoard();
    }
  }, [newBooking]);

  useEffect(() => {
    subscribeToLiveBooking(setNewBooking);
  }, []);

  useEffect(() => {
    if (workShopId) {
      getAllCheckin();
    }
  }, [workShopId, fromDate, toDate]);

  return (
    <>
      <Row className="top-row">
        <Col xs={3} sm={3} md={3} lg={10} xl={10} style={{ marginLeft: '2vw' }}>
          <label style={{ float: 'right', paddingRight: '4px' }}><h3 style={{ color: '#8c8c8c' }}>{`${t('From')}:`}</h3></label>
        </Col>
        <Col xs={6} sm={6} md={6} lg={3} xl={3}>
          <DatePicker
            onChange={getFromDate}
            className="ks_drp_vcl-ty selection_syl"
            placeholder={moment().add(-7, 'days').format('YYYY-MM-DD')}
          />
        </Col>
        <Col xs={2} sm={2} md={2} lg={1} xl={1}><label style={{ float: 'right', paddingRight: '4px' }}><h3 style={{ color: '#8c8c8c' }}>{`${t('To')}:`}</h3></label></Col>
        <Col xs={6} sm={6} md={6} lg={3} xl={3}>
          <DatePicker
            onChange={getToDate}
            className="ks_drp_vcl-ty selection_syl"
            placeholder={moment().format('YYYY-MM-DD')}
          />
        </Col>
        <Col xs={5} sm={5} md={5} lg={5} xl={5} offset={1}>
          <Input
            style={{ width: '18rem', marginLeft: '-18px' }}
            onChange={onSearch}
            addonBefore={<SearchOutlined />}
            placeholder={t('Search')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <div className="task-board-root">
              <div className="task-board-content">
                {taskBoardContent()}
              </div>
            </div>
          </DragDropContext>
        </Col>
      </Row>
    </>
  );
}
function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    userRoles: state.userData.userRoles,
  };
}

export default connect(mapStateToProps, {})(Taskboard);
