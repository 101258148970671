import { Button, Popover } from 'antd';
import React from 'react';
import { ACTION_TYPE } from '../../../../enum/ConfigurationData';
import { USER_ROLES } from '../../../../enum/User';
import { hasRole } from '../../../../utils/commonUtil';

const invoiceColumns = (t: Function, triggerModal: Function, userRoles: string[]) => [
  {
    title: `${t('Item ID')}`,
    dataIndex: 'grnInvoiceId',
    width: '50px',
  },
  {
    title: `${t('Invoice number')}`,
    dataIndex: 'invoiceNumber',
    width: '60px',
  },
  {
    title: `${t('Description')}`,
    dataIndex: 'description',
    width: '100px',
  },
  {
    title: `${t('Qty')}`,
    dataIndex: 'quantity',
    width: '60px',
  },
  {
    title: `${t('Units')}`,
    dataIndex: 'units',
    width: '60px',
  },
  {
    title: `${t('Min Order Quantity')}`,
    dataIndex: 'minOrderQty',
    width: '60px',
  },
  {
    title: `${t('Min Issue Qty')}`,
    dataIndex: 'minIssueQuantity',
    width: '60px',
  },
  {
    title: `${t('Action')}`,
    dataIndex: 'action',
    width: '60px',
    render: (action: any, item: any) => (
      <>
        <Popover placement="topLeft" content="View Item" trigger="hover">
          <Button
            type="text"
            icon={<img src="/assets/view.png" alt="View Item" />}
            onClick={() => triggerModal(ACTION_TYPE.VIEW, item)}
          />
        </Popover>
        {hasRole([USER_ROLES.WORKSHOP_ADMIN, USER_ROLES.ADMIN], userRoles) && (
        <Popover placement="topLeft" content="Edit Item" trigger="hover">
          <Button
            type="text"
            icon={<img src="/assets/edit.png" alt="Edit Item" />}
            onClick={() => triggerModal(ACTION_TYPE.EDIT, item)}
          />
        </Popover>
        )}
      </>
    ),
  },
];

export default invoiceColumns;
