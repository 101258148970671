import React, { useEffect } from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { checkIsLoggedIn } from './views/login/actions';
import MainPage from './views/main/MainPage';
import Loading from './Loading';
import { initApi } from './utils/apiUtils';
import i18n from './translation/i18n';

initApi();

function App() {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.userData.userId);

  useEffect(() => {
    checkIsLoggedIn(dispatch);
    TagManager.initialize({ gtmId: 'GTM-PH6XW42' });
    localStorage.setItem('lang', 'En');
    i18n.changeLanguage('En');
  }, []);

  if (userId === undefined) {
    return <Loading />;
  }

  return (
    <>
      <Router>
        <Switch>
          <Route path="/"><MainPage /></Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
