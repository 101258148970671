import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './Credit.scss';
import {
  Col, Input, Row, Table, Typography, DatePicker, Space, Tabs,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { getCredit } from './actions';
import { BOOKING_TYPES } from '../../../enum/BookingStatus';
import CreditDetails from './component/CreditDetails';
import awaitingApprovalColumn from './columns/awaitingApprovalColumn';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

type CreditType = {
  workShopId: string,
  userRoles: any,
}

const generateFromDate = () => moment().add(-7, 'days');
const generateToDate = () => moment().add(1, 'days');

export const Credit = ({
  workShopId, userRoles,
}: CreditType) => {
  const { Title } = Typography;
  const { t } = useTranslation();

  const [date, setDate] = useState<any[]>([generateFromDate(), generateToDate()]);
  const [creditData, setCreditData] = useState<any[]>([]);
  const [tabStatus, setTabStatus] = useState<BOOKING_TYPES>(BOOKING_TYPES.AWAITING_APPROVAL);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<any[]>([]);

  const triggerModal = (item: any = undefined) => {
    setSelectedItem(item);
    setModalVisible(true);
  };

  const tableColumn = awaitingApprovalColumn(t, triggerModal, userRoles, tabStatus);

  const getAllCredits = () => {
    const from: String = moment(date[0]).format('YYYY-MM-DD');
    const to: String = moment(date[1]).format('YYYY-MM-DD');
    getCredit(workShopId, tabStatus, from, to)
      .then((res: any) => {
        setCreditData(res?.data?.getAllCredits || []);
        setSearchData(res?.data?.getAllCredits || []);
      });
  };
  const onSearch = (e: any) => {
    const keyword: string = e.target.value;
    let res: any[] = searchData;
    if (keyword !== '') {
      res = searchData.filter((item: any) => {
        let flag = false;
        if (item.plateNumber
            && item.plateNumber.toUpperCase().includes(keyword.toUpperCase())) {
          flag = true;
        }
        if (item.ownerName
            && item.ownerName.toUpperCase().includes(keyword.toUpperCase())) {
          flag = true;
        }
        return flag;
      });
    }
    setCreditData(res);
  };

  const onClose = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    if (workShopId && date && tabStatus) {
      getAllCredits();
    }
  }, [workShopId, date, tabStatus]);

  return (
    <>
      <Row>
        <Col>
          <Title level={3} style={{ color: '#8C8C8C' }}>{t('Credit')}</Title>
        </Col>
      </Row>
      <Row className="row-one">
        <Col md={12}>
          <Tabs
            defaultActiveKey={tabStatus}
            animated
            onChange={(status: any) => setTabStatus(status)}
          >
            <TabPane
              tab={(
                <span>
                  <Title level={5} className="drawr-title">{t('Awaiting Approval')}</Title>
                </span>
            )}
              key={BOOKING_TYPES.AWAITING_APPROVAL}
            />
            <TabPane
              tab={(
                <span>
                  <Title level={5} className="drawr-title">{t('Checkout')}</Title>
                </span>
            )}
              key={BOOKING_TYPES.CHECKOUT}
            />
          </Tabs>
        </Col>
        <Col md={6}>
          <Space direction="vertical" size={10}>
            <RangePicker
              onChange={setDate}
              defaultValue={[generateFromDate(), generateToDate()]}
            />
          </Space>
        </Col>
        <Col md={6}>
          <Input
            onChange={onSearch}
            addonBefore={<SearchOutlined />}
            placeholder={t('Search')}
          />
        </Col>
      </Row>
      <div>
        <Table
          className="jobtable"
          columns={tabStatus ? tableColumn : []}
          dataSource={creditData || []}
        />
      </div>
      {selectedItem && (
      <CreditDetails
        item={selectedItem}
        isModalVisible={isModalVisible}
        onClose={onClose}
        getAllCredits={getAllCredits}
        tabStatus={tabStatus}
      />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    workShopId: state.userData.workshopId,
    configData: state.userData.configData,
    userRoles: state.userData.userRoles,
  };
}

export default connect(mapStateToProps, {})(Credit);
