/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Card } from 'antd';
import TaskBoardItemCard from './TaskBoardItemCard';
import './jobTaskBoard.scss';
import { BOOKING_TYPES } from '../../../enum/BookingStatus';

function TaskBoardCol({
  items,
  status,
  generatedKey,
  getAllBookings,
}: any) {
  const [jobTitle, setJobTitle] = useState<string>('');
  const { t } = useTranslation();

  const selectTitle = () => {
    let title = '';
    if (status === BOOKING_TYPES.TO_DO) {
      title = t('JOBS IN QUEUE');
    } else if (status === BOOKING_TYPES.IN_PROGRESS) {
      title = t('IN PROGRESS');
    } else if (status === BOOKING_TYPES.QC) {
      title = t('QUALITY CONTROL');
    } else if (status === BOOKING_TYPES.PENDING_PAYMENT) {
      title = t('PAYMENTS');
    }
    setJobTitle(title);
  };

  useEffect(() => {
    if (status) {
      selectTitle();
    }
  }, [status, items]);
  return (
    <Card
      className="task-board-col-root"
      title={`${t(jobTitle)} (${items.length})`}
      key={generatedKey}
    >
      <Droppable droppableId={status}>
        {(provided: any) => (
          <div
            className="droppable-root"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {items.map((item: any, index: number) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(childProvided, snapshot) => (
                  <div
                    key={item.id}
                    ref={childProvided.innerRef}
                    {...childProvided.draggableProps}
                    {...childProvided.dragHandleProps}
                  >
                    <TaskBoardItemCard
                      item={item}
                      key={item}
                      generatedKey={`${item}-${index}`}
                      status={status}
                      isDragging={snapshot.isDragging}
                      getAllBookings={getAllBookings}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Card>
  );
}

export default TaskBoardCol;
