/* eslint-disable consistent-return */
import { message } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { APIS, postApi, getApi } from '../../../utils/apiUtils';
import {
  getEmployees,
  searchEmployee,
  addEmployee, updateEmployeeMutation,
} from '../../../graphql-queries/employee';

export const saveEmployee = async (generalformData, qulificationsformData, salaryformData) => {
  try {
    let responce:any = null;
    const {
      email,
      userName,
      password,
      userRole,
      workShopId,
    } = generalformData;
    message.loading('Saving. Please wait', 0);
    await postApi(APIS.USER_API, 'signup', {
      userName,
      email,
      password,
      userRole,
      workShopId,
    })
      .then(async (result) => {
        const res = await API.graphql(graphqlOperation(addEmployee, {
          contactNumber: generalformData.contactNumber,
          dob: generalformData.dob,
          name: generalformData.name,
          address: generalformData.address,
          nic: generalformData.nic,
          role: generalformData.userRole,
          status: generalformData.status,
          userName: generalformData.userName,
          workShopId: generalformData.workShopId,
          employeeId: result.User.Attributes.filter((i) => i.Name === 'sub')[0].Value,
          accountHolderName: salaryformData.accountHolderName,
          bank: salaryformData.bank,
          branch: salaryformData.branch,
          accountNumber: salaryformData.accountNumber,
          basicSalary: salaryformData.basicSalary,
          epfEmployeeContribution: salaryformData.epfEmployeeContribution,
          epfCompanyContribution: salaryformData.epfCompanyContribution,
          etf: salaryformData.etf,
        }));
        responce = res;
      })
      .catch((err) => {
        if (err.message === 'Request failed with status code 500') {
          message.destroy();
          message.warning('User already exists. Please enter a different user name');
          responce = false;
        }
      });
    return responce;
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getAllEmployee = async (workShopId, limit, nextToken) => {
  try {
    return await API.graphql(graphqlOperation(getEmployees, { workShopId: `${workShopId}`, limit: `${limit}`, nextToken }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const updateEmployee = async (employee) => {
  try {
    return await API.graphql(graphqlOperation(updateEmployeeMutation, { newEmployee: employee }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const searchEmployeeList = async (workShopId, keyword) => {
  try {
    return await API.graphql(graphqlOperation(searchEmployee, { workShopId: `${workShopId}`, searching: `${keyword}` }));
  } catch (err) {
    message.destroy();
    message.error(err.message);
    return null;
  }
};

export const getSignedUrl = async (key, contentType) => {
  try {
    return await getApi(APIS.EMPLOYEE_API, `employee/signedurl?key=${key}&contentType=${contentType}`);
  } catch (err) {
    message.destroy();
    message.error(err.message);
  }
};

export const getImageUrl = async (key, contentType, method) => {
  try {
    return await getApi(APIS.EMPLOYEE_API, `employee/get-image-url?key=${key}&contentType=${contentType}&method=${method}`);
  } catch (err) {
    message.destroy();
    message.error(err.message);
  }
};
